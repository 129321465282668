/* eslint-disable no-nested-ternary */
import {
  createMaterialTopTabNavigator,
  MaterialTopTabBarProps,
} from '@react-navigation/material-top-tabs';
import { useNavigation } from '@react-navigation/native';
import React, { ReactNode, useCallback, useEffect, useState } from 'react';
import { FlatList, Platform } from 'react-native';
import { InferType } from 'yup';

import Avatar from '@components/Avatar/Avatar';
import CustomTabBar from '@components/CustomTabBar/CustomTabBar';
import EmptyContactListPlaceHolder from '@components/EmptyState/EmptyContactListPlaceHolder';
import EmptyStateNoTeams from '@components/EmptyState/EmptyStateNoTeams';
import { ContactType } from '@components/Invite/Contact';
import ContactList from '@components/Invite/ContactList';
import ContactListMobile from '@components/Invite/ContactListMobile';
import { Box, Text } from '@components/Restyle';
import ActivityIndicatorLoading from '@components/shared/ActivityIndicatorLoading';
import SearchInput from '@components/shared/SearchInput/SearchInput';
import { TabNavigatorSwipeSpacer } from '@components/shared/TabNavigatorSwipeSpacer';
import { TeamListItem } from '@components/Team/TeamListItem';
import { useListUsersLazyQuery, User } from '@graphql/generated';
import { useListTeamsFromQuery } from '@hooks/useListTeamsFromQuery';
import useMe from '@hooks/useMe';
import { useProjectFromQuery } from '@hooks/useProjectFromQuery';
import { useTeamFromQuery } from '@hooks/useTeamFromQuery';
import { ThemedNavigationContainer } from '@navigation/ThemedNavigationContainer';
import { userSchema } from '@schemas/userSchema';
import theme from '@themes/theme';
import { convertUsersToContacts } from '@utils/convertUsersToContacts';

const TabNavigationContainer = Platform.select({
  default: ({ children }: { children: ReactNode }) => <>{children}</>,
  web: ({ children }: { children: ReactNode }) => (
    <ThemedNavigationContainer independent>
      {children}
    </ThemedNavigationContainer>
  ),
});

export type AddMemberType = InferType<typeof userSchema>;
interface File {
  __typename: string;
  id: string;
  url: string;
  cdnBaseUrl: string;
  path: string;
}

interface User {
  __typename: string;
  id: string;
  name: string;
  avatar: File | null;
}

interface Team {
  __typename: string;
  id: string;
  avatar: string;
  name: string;
  personal: boolean;
}

interface Group {
  __typename: string;
  id: string;
  name: string;
  currentUserTeamRole: string | null;
  team: Team;
  users: User[];
}

export type ListGroupData = Group[];
export interface SelectMembersListProps {
  isCreateProject?: boolean;
  isCreateTask?: boolean;
  projectId?: string;
  teamId?: string;
  contacts: User[];
  hideTeamTab?: boolean;
  hideSelectedUserList?: boolean;
  showTaskTagUsers?: boolean;
  selectedUserIds?: string[];
  disabledUsersId?: string[];
  users?: AddMemberType[];
  updateUsers: (newUsers: AddMemberType[]) => void;
  isInviting?: boolean;
  loading?: boolean;
  alphabetize?: boolean;
  listGroup?: ListGroupData[];
  setSelectTab?: (val: string) => void;
  isFromEditProject?: boolean;
  showTaskTagSupportUser?: boolean;
}

export const SelectMembersList: React.FC<SelectMembersListProps> = ({
  isCreateProject = false,
  showTaskTagSupportUser = true,
  isCreateTask = false,
  projectId = '',
  disabledUsersId = [],
  teamId = '',
  contacts,
  hideTeamTab,
  hideSelectedUserList,
  showTaskTagUsers,
  selectedUserIds,
  users = [],
  updateUsers,
  isInviting,
  loading,
  alphabetize = true,
  listGroup,
  setSelectTab,
  isFromEditProject = false,
}) => {
  const TASKTAG_SUPPORT_EMAIL = 'emmanueln@lovettcommercial.com';
  const Tab = createMaterialTopTabNavigator();
  const navigation = useNavigation();
  const { teamsWithoutPersonal: teams, loading: loadingTeams } =
    useListTeamsFromQuery();
  const [search, setSearch] = useState<string>('');
  const [selectedTab, setSelectedTab] = useState<string>('suggested');
  const [hideSuggested, setHideSuggested] = useState<boolean>();
  const [showLoading, setShowLoading] = useState<boolean>(true);
  const [listUsers, { data: taskTagUsers }] = useListUsersLazyQuery();
  const { me } = useMe();

  const roleLabel = 'projects.roles.member';

  const { project, loading: loadingProject } = projectId
    ? useProjectFromQuery({
        projectId,
      })
    : { project: null, loading: false };

  const { members: projectMembers } = project || { users: [] };

  const projectUsers = () => {
    const users: User[] = [];
    projectMembers?.map((u) => {
      if (u.user.id !== me?.id) users.push(u.user);
    });
    return users;
  };

  const listProjectUsersToContactType = convertUsersToContacts(
    projectUsers()
  ).map((c) => {
    if (selectedUserIds?.includes(c.id)) {
      return {
        ...c,
        roleLabel,
      };
    } else {
      return c;
    }
  });

  const { team, loading: loadingTeamMembers } = teamId
    ? useTeamFromQuery({
        teamId,
      })
    : { team: null, loading: false };

  const { users: teamUsers } = team || { users: [] };

  const listTeamContactsToContactType = convertUsersToContacts(
    teamUsers.filter((u) => u.id !== me?.id)
  ).map((c) => {
    if (selectedUserIds?.includes(c.id)) {
      return {
        ...c,
        roleLabel,
      };
    } else {
      return c;
    }
  });

  const myTeamsUsers = () => {
    const userIds = new Set();
    const users: User[] = [];

    teams?.forEach((team) => {
      team.users.forEach((user) => {
        if (user.id !== me?.id && !userIds.has(user.id)) {
          users.push(user);
          userIds.add(user.id);
        }
      });
    });

    return users;
  };

  const myTeamsUsersToContactType = convertUsersToContacts(myTeamsUsers()).map(
    (c) => {
      if (selectedUserIds?.includes(c.id)) {
        return {
          ...c,
          roleLabel,
        };
      } else {
        return c;
      }
    }
  );

  const listContactsToContactType = convertUsersToContacts(contacts).map(
    (c) => {
      if (selectedUserIds?.includes(c.id)) {
        return {
          ...c,
          roleLabel,
        };
      } else {
        return c;
      }
    }
  );

  // additionally filters out listContacts => rm filter when backend does this
  const listUsersToContactType = convertUsersToContacts(
    (taskTagUsers?.listUsers as User[]) || []
  ).filter((u) => !listContactsToContactType.some((c) => c.id === u.id));

  useEffect(() => {
    search && showTaskTagUsers && listUsers({ variables: { term: search } });
  }, [search]);

  const renderTabBar = (props: MaterialTopTabBarProps) => {
    return (
      <Box
        flexDirection='row'
        alignItems='center'
        paddingHorizontal='m'
        paddingBottom='xs'
        maxHeight={50}>
        <CustomTabBar {...props} spacing={theme.spacing.l} />
      </Box>
    );
  };

  const onPress = useCallback(
    (user: ContactType) => {
      const newSelection: AddMemberType = {
        id: user.id,
        firstName: user.firstName,
        lastName: user.lastName,
        name: user.name,
        avatar: user.avatar,
        phoneNumber: user.phoneNumber,
        type: user?.type,
        memberCount: user?.memberCount,
        users: user?.users,
      };
      if (users?.find((u) => u.id === user.id)) {
        const selectedUsers = users.filter((u) => u.id !== user.id);
        updateUsers(selectedUsers);
      } else {
        updateUsers([...users, newSelection]);
      }
    },
    [users]
  );

  useEffect(() => {
    const shouldHideSuggested =
      (teamId && listTeamContactsToContactType.length === 0) ||
      (projectId && listProjectUsersToContactType.length === 0) ||
      myTeamsUsersToContactType.length === 0;

    if (shouldHideSuggested !== hideSuggested) {
      setHideSuggested(shouldHideSuggested);
    }
  }, [
    listTeamContactsToContactType.length,
    listProjectUsersToContactType.length,
    myTeamsUsersToContactType.length,
  ]);

  useEffect(() => {
    setTimeout(() => setShowLoading(false), 2000);
  }, []);

  const isLoading =
    selectedTab === 'all-contacts' || search || hideTeamTab
      ? loading
      : teamId
      ? loadingTeamMembers
      : projectId
      ? loadingProject
      : loadingTeams;

  if (isLoading || showLoading) {
    return <ActivityIndicatorLoading />;
  }

  let contactsType: ContactType[];

  if (selectedTab === 'all-contacts' || search || hideTeamTab) {
    contactsType = listContactsToContactType;
  } else if (teamId) {
    contactsType =
      listTeamContactsToContactType?.length > 0
        ? listTeamContactsToContactType
        : listContactsToContactType;
  } else if (projectId) {
    contactsType =
      listProjectUsersToContactType?.length > 0
        ? listProjectUsersToContactType
        : listContactsToContactType;
  } else if (myTeamsUsersToContactType?.length > 0) {
    contactsType = myTeamsUsersToContactType;
  } else {
    contactsType = listContactsToContactType;
  }

  const renderContactList = () => {
    return (
      <ContactList
        shouldDisplayPhoneContactLabel={false}
        isInviting={isInviting ?? false}
        alphabetize={alphabetize}
        disabledRoles={
          isCreateProject || isCreateTask || !isFromEditProject
            ? undefined
            : [roleLabel]
        }
        disabledUsersId={disabledUsersId}
        contacts={
          showTaskTagSupportUser
            ? contactsType
            : contactsType.filter((c) => c.email !== TASKTAG_SUPPORT_EMAIL)
        }
        filterVal={search}
        onPress={onPress}
        isEditing
        loading={
          selectedTab === 'all-contacts' || search || hideTeamTab
            ? loading
            : teamId
            ? loadingTeamMembers
            : projectId
            ? loadingProject
            : loadingTeams
        }
        selected={users.map((u) => u.id)}
        taskTagUsers={listUsersToContactType}
        showEmptyStateNoSearch={!!search}
        showEmptyStateNoContacts={
          !loading && listContactsToContactType.length === 0
        }
      />
    );
  };

  const renderContactGroupsList = () => {
    return isFromEditProject ? (
      <EmptyContactListPlaceHolder showSmall />
    ) : (
      <ContactListMobile
        shouldDisplayPhoneContactLabel={false}
        isInviting={(isInviting && isFromEditProject) || false}
        alphabetize={alphabetize}
        disabledRoles={
          isCreateProject || isCreateTask ? undefined : [roleLabel]
        }
        contacts={listGroup ?? []}
        filterVal={search}
        onPress={onPress}
        isEditing
        loading={
          selectedTab === 'all-contacts' || search || hideTeamTab
            ? loading
            : teamId
            ? loadingTeamMembers
            : projectId
            ? loadingProject
            : loadingTeams
        }
        selected={users.map((u) => u.id)}
        taskTagUsers={listUsersToContactType}
        showEmptyStateNoSearch={!!search}
        showEmptyStateNoContacts={
          !loading && listContactsToContactType.length === 0
        }
      />
    );
  };

  const getTabs = (showSuggested: boolean) => {
    return (
      <>
        <TabNavigationContainer>
          <Tab.Navigator
            screenOptions={{ swipeEnabled: Platform.OS !== 'web' }}
            sceneContainerStyle={{
              backgroundColor: 'transparent',
            }}
            tabBar={renderTabBar}
            initialRouteName={showSuggested ? 'suggested' : 'all-contacts'}
            backBehavior='none'>
            {showSuggested && (
              <Tab.Screen
                name='suggested'
                options={{
                  tabBarLabel: 'Suggested',
                  tabBarAccessibilityLabel: 'Suggested',
                }}
                listeners={{
                  tabPress: () => {
                    setSelectTab && setSelectTab('suggested');
                    setSelectedTab('suggested');
                  },
                }}
                children={() => (
                  <Box flex={1} accessibilityLabel='Suggested'>
                    {renderContactList()}
                  </Box>
                )}
              />
            )}
            <Tab.Screen
              name='all-contacts'
              options={{
                tabBarLabel: 'All Contacts',
                tabBarAccessibilityLabel: 'All Contacts',
              }}
              listeners={{
                tabPress: () => {
                  setSelectTab && setSelectTab('all-contacts');
                  setSelectedTab('all-contacts');
                },
              }}
              children={() => (
                <Box flex={1} accessibilityLabel='All Contacts'>
                  {renderContactList()}
                </Box>
              )}
            />
            {(isFromEditProject || Platform.OS === 'web') && (
              <Tab.Screen
                name='teams'
                listeners={{
                  tabPress: () => {
                    setSelectTab && setSelectTab('teams');
                  },
                }}
                options={{
                  tabBarLabel: 'Teams',
                  tabBarAccessibilityLabel: 'Teams',
                }}
                children={() => (
                  <Box accessibilityLabel='Teams' flex={1}>
                    <FlatList
                      data={teams}
                      ListEmptyComponent={
                        <Box pt='xxl'>
                          <EmptyStateNoTeams />
                        </Box>
                      }
                      keyExtractor={(item) => item.id}
                      renderItem={({ item, item: { id } }) => (
                        <Box
                          accessibilityLabel='Team Item'
                          marginHorizontal='m'>
                          <TeamListItem
                            team={item}
                            onSelect={() => {
                              navigation.navigate('select-members-from-team', {
                                teamId: id,
                              });
                            }}
                          />
                        </Box>
                      )}
                      ListFooterComponent={() => (
                        <Box marginBottom='listFooter' />
                      )}
                      ListHeaderComponent={() => <Box marginTop='m' />}
                      ItemSeparatorComponent={() => <Box marginBottom='l' />}
                    />
                  </Box>
                )}
              />
            )}

            <Tab.Screen
              name='contact-groups'
              options={{
                tabBarLabel: 'Contact Groups',
                tabBarAccessibilityLabel: 'Contact Groups',
              }}
              listeners={{
                tabPress: () => {
                  setSelectTab && setSelectTab('contact-groups');
                  setSelectedTab('contact-groups');
                },
              }}
              children={() => (
                <Box flex={1} accessibilityLabel='contact-groups'>
                  {renderContactGroupsList()}
                </Box>
              )}
            />
          </Tab.Navigator>
        </TabNavigationContainer>
        <TabNavigatorSwipeSpacer />
      </>
    );
  };

  return (
    <Box flex={1}>
      <Box
        marginHorizontal='m'
        marginTop='m'
        flexDirection='row'
        alignItems='center'>
        <SearchInput
          onTextChanged={(t: string) => setSearch(t)}
          value={search}
        />
      </Box>
      <Box>
        {users.length && !hideSelectedUserList ? (
          <FlatList
            style={{
              marginTop: theme.spacing.m,
              paddingBottom: theme.spacing.m,
            }}
            horizontal
            data={users?.filter((item) => item.role !== 'OWNER')}
            ItemSeparatorComponent={() => <Box marginRight='m' />}
            ListHeaderComponent={() => <Box marginRight='m' />}
            ListFooterComponent={() => <Box marginRight='m' />}
            renderItem={({ item, index }) => {
              const { id, avatar, memberCount, name, firstName } = item;
              return (
                <Box key={index} alignItems='center' alignSelf='flex-start'>
                  <Avatar
                    id={item.id}
                    avatar={avatar}
                    id={item.id}
                    label={name}
                    isMultiple={item?.type === 'GROUP'}
                    onDismiss={() => {
                      const newUsers = users.filter((i) => i.id !== id);
                      updateUsers(newUsers);
                    }}
                  />
                  {firstName && (
                    <Text
                      variant='labelSmall'
                      marginTop='xs'
                      style={{ maxWidth: 64 }}
                      numberOfLines={1}>
                      {firstName}
                    </Text>
                  )}
                  {!firstName && name && (
                    <Text
                      variant='labelSmall'
                      marginTop='xs'
                      style={{ maxWidth: 64 }}
                      numberOfLines={1}>
                      {name}
                    </Text>
                  )}
                  {!firstName && memberCount && (
                    <Text
                      variant='labelSmall'
                      marginTop='xs'
                      style={{ maxWidth: 100 }}
                      numberOfLines={1}>
                      {memberCount + ' members'}
                    </Text>
                  )}
                </Box>
              );
            }}
          />
        ) : (
          <Box marginTop='m' />
        )}
      </Box>
      <Box flex={1} style={{ height: `100%` }}>
        {!hideTeamTab && !search ? (
          hideSuggested ? (
            getTabs(false)
          ) : (
            getTabs(true)
          )
        ) : (
          <>{renderContactList()}</>
        )}
      </Box>
    </Box>
  );
};
