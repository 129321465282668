import { AsyncStorage } from '@utils/storage';

export const useFileValidation = () => {
  const isValidExtension = async (contentType: string) => {
    const validExtensionTypes = await AsyncStorage.getItem(
      'allowedContentTypes'
    );
    return validExtensionTypes?.includes(contentType);
  };

  return { isValidExtension };
};
