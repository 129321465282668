import dayjs from 'dayjs';
import { useFormikContext } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { SelectModal } from '@components/FormModals/SelectModal';
import { Team } from '@graphql/generated';
import { useListTeamsFromQuery } from '@hooks/useListTeamsFromQuery';
import useSelectedMembers from '@hooks/useSelectedMembers';
import { useAppNavigation } from '@navigation/useAppNavigation';

interface TeamSelectProps {
  isBottomSheet?: boolean;
  onSelectFinish?: () => void;
  teams?: Team[];
}
export const TeamSelect = ({
  isBottomSheet,
  onSelectFinish,
  teams: teamsProp,
}: TeamSelectProps) => {
  const { t } = useTranslation('models');
  const { teams: teamsFromQuery } = useListTeamsFromQuery({});
  const { setFieldValue, getFieldProps, touched, setTouched } =
    useFormikContext();
  const { name: fieldName, value } = getFieldProps('teamId');
  const navigation = useAppNavigation();
  const { setShowTeamSelect } = useSelectedMembers();

  const getTeams = () => {
    if (teamsProp) return teamsProp;

    return teamsFromQuery.filter((team) => {
      return -dayjs().diff(team?.plan?.expiration, 'day') > 0 || team?.personal;
    });
  };

  const teams = getTeams();

  return (
    <SelectModal
      title='Teams'
      list={teams.map(({ id, name }) => ({ id, name }))}
      onClose={() => {
        setShowTeamSelect(false);
        navigation.goBack();
      }}
      setSelected={(id) => {
        setFieldValue(fieldName, id);
        setTouched({ ...touched, [fieldName]: true });
        setShowTeamSelect(false);
        onSelectFinish ? onSelectFinish() : navigation.goBack();
      }}
      value={value}
      singleSelect
      isBottomSheet={isBottomSheet}
      emptyContentMessage={t('resourceNotFound.message', {
        resource: 'team',
        resourcePlural: 'teams',
      })}
    />
  );
};
