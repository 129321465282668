import React from 'react';
import { Platform } from 'react-native';
import Highlighter from 'react-native-highlight-words';

import Avatar from '@components/Avatar/Avatar';
import { ContactType } from '@components/Invite/Contact';
import { Box, Text } from '@components/Restyle';
import theme from '@themes/theme';

type ContactGroupMemberProps = {
  isFromMemberRole?: boolean;
  isVisible: boolean;
  contact: ContactType | null;
  filterVal?: string;
  isEditProject?: boolean;
};

const ContactGroupMember: React.FC<ContactGroupMemberProps> = ({
  isFromMemberRole,
  isVisible,
  contact,
  filterVal,
  isEditProject = false,
}) => {
  return (
    <Box marginLeft={isFromMemberRole ? 'xxl' : 'listFooter'}>
      {isVisible &&
        contact?.users?.map((user) => (
          <Box
            marginTop='xxs'
            flexDirection='row'
            alignItems='center'
            paddingVertical='xs'
            key={user?.id}>
            <Avatar
              id={user?.id}
              label={user?.name}
              avatar={user?.avatar}
              size='medium'
              disableAvatarPress={false}
            />
            <Text
              style={{ marginLeft: theme.spacing.xs }}
              accessibilityLabel='Member'
              variant={
                isEditProject && Platform.OS === 'web'
                  ? 'webBodySecondary'
                  : 'labelEmphasized'
              }
              color='textPrimary'>
              {user.name ? (
                <Highlighter
                  highlightStyle={
                    Platform.OS == 'web'
                      ? {
                          backgroundColor: theme.colors.yellowBright,
                        }
                      : {
                          color: theme.colors.greenSecondary,
                          backgroundColor: theme.colors.background,
                        }
                  }
                  autoEscape
                  searchWords={filterVal?.split(' ') || ''}
                  textToHighlight={user?.name}
                />
              ) : (
                'No name'
              )}
            </Text>
          </Box>
        ))}
    </Box>
  );
};

export default ContactGroupMember;
