import {
  BottomSheetModal,
  BottomSheetBackdropProps,
  BottomSheetModalProvider,
} from '@gorhom/bottom-sheet';
import { useNavigation } from '@react-navigation/native';
import dayjs from 'dayjs';
import { Field, useFormikContext } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Platform,
  ScrollView,
  StyleSheet,
  TouchableOpacity,
  View,
  Modal,
} from 'react-native';

import { Alert } from '@components/Alert';
import { TeamSelect } from '@components/FormModals/TeamSelect';
import { FloatingHeader } from '@components/Headers/FloatingHeader';
import { BottomSheetBackdrop } from '@components/Modals/BottomSheetBackdrop';
import {
  getShowProjectLimitSnackBarNextTime,
  removeProjectLimitSnackBarNextTime,
  saveShowProjectLimitSnackBarNextTime,
} from '@components/Projects/ProjectForm.web';
import { FormValues } from '@components/Projects/ProjectFormStack';
import { Box, Text } from '@components/Restyle';
import Button from '@components/shared/Button/Button';
import Icon from '@components/shared/Icon/Icon';
import TextField from '@components/shared/TextField/TextField';
import { useListTeamsFromQuery } from '@hooks/useListTeamsFromQuery';
import useMe from '@hooks/useMe';
import useSelectedMembers from '@hooks/useSelectedMembers';
import theme from '@themes/theme';

export const ProjectCreateBottomSheet: React.FC = () => {
  const { me } = useMe();
  const navigation = useNavigation();
  const { teams } = useListTeamsFromQuery();
  const primaryTeam = teams[0];
  const { t } = useTranslation();

  const handleSheetChanges = (index: number) => {
    if (index < 0) {
      navigation.goBack();
    }
  };
  const bottomSheetRef = useRef<BottomSheetModal>(null);
  const { setSelectedMembers, selectedMembers, setShowTeamSelect } =
    useSelectedMembers();

  const {
    submitForm,
    setFieldValue,
    isSubmitting,
    values,
    errors,
    touched,
    resetForm,
  } = useFormikContext<FormValues>();

  const { teamId = '', name, description, currentTeam, users } = values;

  const selectedTeam = teams.find((item) => item.id === teamId);
  const { name: selectedTeamName } = selectedTeam || {};

  const [_touchedTeam, setTouchedTeam] = useState(false);
  const [memberCount, setMemberCount] = useState(selectedMembers.length || 0);
  const moreButtonRef = useRef<View>(null);
  const [blankProjectForm, setblankProjectForm] = useState(true);
  const [showProjectLimitSnackBar, setShowProjectLimitSnackBar] =
    useState(false);
  const [hideProjectLimitSnackBar, setHideProjectLimitSnackBar] =
    useState(false);

  const subSnapPoints =
    showProjectLimitSnackBar && !hideProjectLimitSnackBar
      ? ['50%', '55%']
      : ['50%', '75%'];
  const snapPoints = [...subSnapPoints, '90%'];

  useEffect(() => {
    setMemberCount(selectedMembers.length || 0);
  }, [selectedMembers]);

  const _dismissProjectFormModal = () => {
    if (bottomSheetRef.current) {
      bottomSheetRef.current.dismiss();
    }
  };

  useEffect(() => {
    if (bottomSheetRef.current) {
      bottomSheetRef.current.present();
    }
  }, [bottomSheetRef]);

  useEffect(() => {
    setFieldValue('users', selectedMembers);
  }, [selectedMembers]);
  useEffect(() => {
    if (!values.teamId && primaryTeam?.id) {
      setFieldValue('teamId', primaryTeam.id);
    }
  }, [primaryTeam, values.teamId, setFieldValue]);

  useEffect(() => {
    setSelectedMembers([]);
    return () => setSelectedMembers([]);
  }, []);

  useEffect(() => {
    if (name.trim() || teamId || users.length > 0 || description?.trim()) {
      setblankProjectForm(false);
    } else {
      setblankProjectForm(true);
    }
  }, [values]);

  const discardAlert = (ref: React.RefObject<BottomSheetModal>) => {
    Alert.alert(
      t('models:discardProject.title'),
      t('models:discardProject.message'),
      [
        {
          text: t('shared:cancel'),
        },
        {
          text: t('shared:discard'),
          style: 'destructive',
          onPress: () => {
            ref.current?.dismiss();
          },
        },
      ]
    );
  };

  const renderBackdrop = (
    props: BottomSheetBackdropProps,
    ref: React.RefObject<BottomSheetModal>
  ) => (
    <BottomSheetBackdrop
      {...props}
      closeSheet={() =>
        blankProjectForm ? ref.current?.dismiss() : discardAlert(ref)
      }
    />
  );

  const bottomSheetTeamModalRef = useRef<BottomSheetModal>(null);
  const openTeamSelectModal = () => {
    if (bottomSheetTeamModalRef.current) {
      bottomSheetTeamModalRef.current.present();
    }
  };
  const dismissTeamSelectModal = () => {
    if (bottomSheetTeamModalRef.current) {
      bottomSheetTeamModalRef.current.dismiss();
    }
  };
  const isTeamErrors = errors.teamId && touched.teamId;

  const isActiveOrTrialingTeam =
    selectedTeam?.plan?.name === 'Team' &&
    (selectedTeam?.plan?.status === 'trialing' ||
      selectedTeam?.plan?.status === 'active');

  const projectLeft =
    (selectedTeam?.maxNumberOfProjects ?? 0) -
    (selectedTeam?.numberOfProjectsUsed ?? 0);

  const disabled =
    selectedTeam?.maxNumberOfProjects === 0
      ? isSubmitting || !name.trim() || !teamId
      : projectLeft <= 0 || isSubmitting || !name.trim() || !teamId;

  useEffect(() => {
    if (!isActiveOrTrialingTeam && selectedTeam?.maxNumberOfProjects) {
      if (projectLeft < 1 && selectedTeam.maxNumberOfProjects > 0) {
        setShowProjectLimitSnackBar(true);
      } else if (
        projectLeft === 1 ||
        (projectLeft === 2 && selectedTeam.maxNumberOfProjects > 0)
      ) {
        needShowProjectLimitSnackBar();
      } else {
        setShowProjectLimitSnackBar(false);
      }
    }
  }, [selectedTeam]);

  const needShowProjectLimitSnackBar = async () => {
    const savedTimes = await getShowProjectLimitSnackBarNextTime();
    const savedTime = savedTimes?.find(
      (item) => item.userId === me?.id && item.teamId === selectedTeam?.id
    );
    if (savedTime) {
      if (dayjs(Date()).diff(new Date(savedTime.nextTime)) < 0) {
        return;
      }
    }
  };

  const onCloseProjectUpgradeModal = () => {
    setShowProjectLimitSnackBar(false);
    setHideProjectLimitSnackBar(true);
    saveShowProjectLimitSnackBarNextTime(me?.id, selectedTeam?.id);
  };

  return (
    <>
      <BottomSheetModalProvider>
        <BottomSheetModal
          ref={bottomSheetRef}
          index={1}
          enablePanDownToClose
          snapPoints={snapPoints}
          onChange={handleSheetChanges}
          backdropComponent={(props) => renderBackdrop(props, bottomSheetRef)}
          handleComponent={() => <Box style={styles.closeLine} />}>
          <Box flex={1}>
            <ScrollView style={{ flex: 1 }}>
              <Box flex={1} marginHorizontal='m' style={{ paddingBottom: 120 }}>
                <Field
                  component={TextField}
                  name='name'
                  isRequired
                  autoFocus
                  borderless
                  isLargeText
                  showCharsRemaining={name?.length > 0}
                  textInputProps={{ maxLength: 25 }}
                  marginBottom='xxxs'
                  placeholder={t('models:projects.placeholders.name')}
                  accessibilityLabel={t(
                    'models:projects.placeholders.projectName'
                  )}
                />
                <Field
                  component={TextField}
                  label='Description'
                  name='description'
                  borderless
                  showCharsRemaining
                  textInputProps={{ maxLength: 500, multiline: true }}
                  placeholder={t('models:projects.placeholders.description')}
                  accessibilityLabel={t(
                    'models:projects.placeholders.description'
                  )}
                />

                <Box style={styles.teamContainer}>
                  {selectedTeam && teamId ? (
                    <Button
                      marginRight='s'
                      backgroundColor='black'
                      accessibilityLabel={t(
                        'models:projects.create.teams.addTeam'
                      )}
                      variant='editWhite'
                      prefix={<Icon name='TT20' color='textPrimary' />}
                      onPress={() => {
                        setTouchedTeam(true);
                        setShowTeamSelect(true);
                        openTeamSelectModal();
                      }}
                      isSmall>
                      {(selectedTeamName || currentTeam?.name) ??
                        t('models:projects.create.teams.addTeam')}
                    </Button>
                  ) : (
                    <>
                      <Button
                        marginRight='s'
                        backgroundColor='white'
                        accessibilityLabel={t(
                          'models:projects.create.teams.addTeam'
                        )}
                        variant={isTeamErrors ? 'editError' : 'edit'}
                        prefix={
                          <Icon
                            name='Folder'
                            color={isTeamErrors ? 'alertRed' : 'textPrimary'}
                          />
                        }
                        onPress={() => {
                          setTouchedTeam(true);
                          setShowTeamSelect(true);
                          openTeamSelectModal();
                        }}
                        isSmall>
                        {t('models:projects.create.teams.team')}
                      </Button>
                    </>
                  )}

                  <View ref={moreButtonRef}>
                    <Button
                      accessibilityLabel={t('models:projects.create.copyCta')}
                      variant='edit'
                      prefixMarginRight='xxxs'
                      backgroundColor='white'
                      prefix={
                        <Icon
                          color={
                            showProjectLimitSnackBar &&
                            !hideProjectLimitSnackBar
                              ? 'grey04'
                              : 'black'
                          }
                          name='Copy'
                        />
                      }
                      onPress={() => {
                        navigation.navigate(
                          Platform.OS === 'web'
                            ? 'project-copy-search'
                            : 'project-search'
                        );
                      }}
                      isSmall
                      children=''></Button>
                  </View>
                </Box>

                <Box style={styles.memberContainer}>
                  <Box>
                    <Box flex={1}>
                      <Button
                        accessibilityLabel={t(
                          'models:tasks.create.members.title'
                        )}
                        variant={
                          showProjectLimitSnackBar && !hideProjectLimitSnackBar
                            ? 'editSelectedDisabled'
                            : 'editSelected'
                        }
                        backgroundColor='white'
                        onPress={() => {
                          navigation.navigate('project-add-members-stack', {
                            selectedTeamId: teamId,
                            isCreateProjectForMobile: true,
                            memberCount: memberCount,
                          });
                        }}
                        prefixMarginRight='xs'
                        prefix={
                          <Icon
                            name='Users'
                            color={
                              showProjectLimitSnackBar &&
                              !hideProjectLimitSnackBar
                                ? 'grey04'
                                : 'textPrimary'
                            }
                          />
                        }
                        isSmall>
                        {memberCount > 0
                          ? memberCount +
                            1 + //increment 1 is for the owner object that is pushed to render only
                            ' ' +
                            t('models:tasks.create.members.title')
                          : t('models:tasks.create.members.title')}
                      </Button>
                    </Box>
                  </Box>
                  <Box style={styles.save}>
                    <TouchableOpacity
                      onPress={() => {
                        if (!teamId) {
                          setFieldValue('teamId', primaryTeam?.id);
                        }
                        submitForm();
                        removeProjectLimitSnackBarNextTime(me?.id, '0');
                        removeProjectLimitSnackBarNextTime(
                          me?.id,
                          selectedTeam?.id
                        );
                        setTimeout(() => {
                          const selectedTeamId =
                            values.teamId || primaryTeam?.id;
                          setMemberCount(0);
                          setSelectedMembers([]);
                          resetForm({
                            values: {
                              id: '',
                              name: '',
                              description: '',
                              users: [],
                              teamId: selectedTeamId,
                              location: '',
                              color: '',
                              icon: '',
                              currentTeam: null,
                            },
                          });
                        }, 5);
                      }}
                      disabled={disabled}
                      accessibilityLabel={t('shared:save')}>
                      <Box
                        alignItems='center'
                        justifyContent='center'
                        borderRadius='xxl'
                        bg={disabled ? 'grey04' : 'greenSecondary'}
                        width={40}
                        height={40}>
                        <Icon variant='xl' name='ArrowUp' color='white' />
                      </Box>
                    </TouchableOpacity>
                  </Box>
                </Box>
              </Box>
            </ScrollView>
            {!isActiveOrTrialingTeam &&
              showProjectLimitSnackBar &&
              !hideProjectLimitSnackBar && (
                <Box mx='m' backgroundColor='white'>
                  <Modal
                    transparent={true}
                    animationType='fade'
                    visible={
                      !isActiveOrTrialingTeam &&
                      showProjectLimitSnackBar &&
                      !hideProjectLimitSnackBar
                    }
                    onRequestClose={() => {
                      setShowProjectLimitSnackBar(false);
                      setHideProjectLimitSnackBar(true);
                      saveShowProjectLimitSnackBarNextTime(
                        me?.id,
                        selectedTeam?.id
                      );
                    }}>
                    <Box style={styles.snackBarOuterContainer}>
                      <Box style={styles.snackBarContainer}>
                        <Box flexDirection='row' justifyContent='space-between'>
                          <Text variant='labelEmphasized' color='white'>
                            {t('models:projects.snackBar.freeProjectLimit')}
                          </Text>
                          <Icon
                            variant='m'
                            color='grey04'
                            name='X'
                            onPress={() => {
                              onCloseProjectUpgradeModal();
                            }}
                          />
                        </Box>

                        <Text variant='typography' color='grey03'>
                          {t('models:projects.snackBar.createMoreProjects')}
                        </Text>
                        <Button
                          variant='edit'
                          marginVertical='s'
                          backgroundColor='white'
                          accessibilityLabel='Upgrade Now'
                          onPress={() => {
                            onCloseProjectUpgradeModal();
                            navigation.navigate('payments-stack', {
                              screen: 'upgrade-plus',
                            });
                          }}
                          disabled={false}>
                          {t('models:projects.snackBar.upgradeNow')}
                        </Button>
                      </Box>
                    </Box>
                  </Modal>
                </Box>
              )}
          </Box>
        </BottomSheetModal>
        <BottomSheetModal
          name='Team Select Modal'
          index={0}
          stackBehavior='push'
          ref={bottomSheetTeamModalRef}
          enablePanDownToClose
          snapPoints={snapPoints}
          backdropComponent={BottomSheetBackdrop}
          handleComponent={() => <Box style={styles.closeLine} />}>
          <>
            <FloatingHeader
              title={t('models:projects.create.teams.title')}
              simple
              hideBorder
              onCancel={dismissTeamSelectModal}
              onSave={dismissTeamSelectModal}
              saveButtonTitle='Done'
              disabled
            />
            <TeamSelect isBottomSheet onSelectFinish={dismissTeamSelectModal} />
          </>
        </BottomSheetModal>
      </BottomSheetModalProvider>
    </>
  );
};
const styles = StyleSheet.create({
  closeLine: {
    alignSelf: 'center',
    width: 56,
    height: 4,
    borderRadius: 2.5,
    backgroundColor: theme.colors.grey03,
    margin: theme.spacing.l,
  },
  teamContainer: {
    display: 'flex',
    flexDirection: 'row',
    paddingVertical: theme.spacing.s,
    alignItems: 'center',
  },
  memberContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingVertical: theme.spacing.s,
  },
  save: {
    zIndex: 99,
    marginTop: theme.spacing.s,
  },
  snackBarContainer: {
    padding: theme.spacing.m,
    backgroundColor: 'black',
    borderTopEndRadius: theme.borderRadii.s,
    borderTopStartRadius: theme.borderRadii.s,
    flexDirection: 'column',
  },
  snackBarOuterContainer: {
    flex: 1,
    position: 'absolute',
    bottom: 0,
    width: '100%',
  },
});
