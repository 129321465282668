import {
  BottomSheetModal,
  BottomSheetModalProvider,
} from '@gorhom/bottom-sheet';
import { useRoute } from '@react-navigation/native';
import {
  createStackNavigator,
  StackScreenProps,
} from '@react-navigation/stack';
import { Formik } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Alert, Platform } from 'react-native';
import { useToast } from 'react-native-toast-notifications';
import { InferType } from 'yup';

import { TaskCreatedToast } from '@components/CustomToast/TaskCreatedToast';
import { DateSelect } from '@components/FormModals/DateSelect';
import { DependencySelect } from '@components/FormModals/DependencySelect';
import ProjectSelect from '@components/FormModals/ProjectSelect';
import { SkillSelect } from '@components/FormModals/SkillSelect';
import SecondaryHeader from '@components/Headers/SecondaryHeader';
import { SimpleHeader } from '@components/Headers/SimpleHeader';
import { Box } from '@components/Restyle';
import { EditTaskDates } from '@components/Tasks/EditTaskDates.web';
import { EditTaskDatesBottomSheet } from '@components/Tasks/EditTaskDatesBottomSheet';
import { TaskCreateBottomSheet } from '@components/Tasks/TaskCreateBottomSheet';
import { TaskForm } from '@components/Tasks/TaskForm';
import { useWebDrawer } from '@components/Web/Drawer/WebDrawerContext';
import {
  GetProjectDocument,
  ListMessagesDocument,
  ListProjectsDocument,
  ListTasksDocument,
  TaskChecklistAttributes,
  TaskMemberAttributes,
  useAssignTaskUsersMutation,
  useCreateTaskMutation,
  useDuplicateTaskFilesMutation,
  useUpdateTaskMutation,
} from '@graphql/generated';
import useMe from '@hooks/useMe';
import useSelectedMembers from '@hooks/useSelectedMembers';
import useTagInput from '@hooks/useTagInput';
import useTask from '@hooks/useTask';
import { ChatsStackScreenProps } from '@navigation/chats/chats-stack';
import { TasksStackScreenProps } from '@navigation/tasks/tasks-stack';
import { useAppNavigation } from '@navigation/useAppNavigation';
import createTaskFormSchema from '@schemas/createTaskFormSchema';
import { AddEditTaskMembersFromModal } from '@screens/Members/AddEditTaskMembersFromModal';
import { TaskCopySearch } from '@screens/Tasks/TaskCopySearch';
import { TaskCopySelect } from '@screens/Tasks/TaskCopySelect.web';
import { TaskDetailEditPriority } from '@screens/Tasks/TaskDetailEditPriority';
import { TaskDetailEditStatus } from '@screens/Tasks/TaskDetailEditStatus';
import { beginningOfDayUTC } from '@utils/formatters/date';
import { defaultTaskRole, defaultAssignMemberTaskRole } from '@utils/tasks';

export type FormValues = InferType<typeof createTaskFormSchema>;

export type TaskFormStackParamList = {
  'task-form': undefined;
  'member-select': undefined;
  'project-select': undefined;
  'skill-select': undefined;
  'phase-select': undefined;
  'dependency-select': undefined;
  'task-copy-search': undefined;
  'task-copy-select': undefined;
  'add-edit-task-modal-members': undefined;
  'date-select': { field: FormValues['startDate' | 'dueDate'] };
  'edit-date-select': {
    submitOnSelected: false;
    fieldName: FormValues['startDate' | 'dueDate'];
  };
  'date-select-modal': undefined;
};

export type TaskFormStackScreenProps<T extends keyof TaskFormStackParamList> =
  StackScreenProps<TaskFormStackParamList, T>;

const Stack = createStackNavigator<TaskFormStackParamList>();

const TaskFormStack: React.FC<{
  taskId?: string | undefined;
  chatId?: string | undefined;
  initialValues: FormValues;
  isShortModal?: boolean;
  editField?: string;
  isAssignTask?: boolean;
  isCopyTask?: boolean;
  screenName?: string;
  openModal?: boolean;
  selectedList?: [];
  checkList?: TaskChecklistAttributes[];
}> = ({
  taskId,
  chatId,
  initialValues,
  isShortModal,
  editField,
  isAssignTask,
  isCopyTask,
  screenName,
  openModal = false,
  selectedList = [],
  checkList = [],
}) => {
  const { t } = useTranslation();
  const navigation = useAppNavigation();
  const { navigateToTask } = navigation;
  const { setCurrentTask, copyItems } = useTask();
  const { setSelectedMembers, selectedMembers } = useSelectedMembers();
  const { params } = useRoute<
    ChatsStackScreenProps<'task-form-modal'>['route'] &
      TasksStackScreenProps<'task-create'>['route']
  >();
  const [copyTask, setCopyTask] = useState();
  const { tags, setTags } = useTagInput();
  const tagsCollection = tags(chatId);
  const { me } = useMe();

  const includeFiles = openModal ? selectedList[2]?.list[0].isSelected : false;
  const getEditPage = () => {
    switch (editField) {
      case 'status':
        return <TaskDetailEditStatus />;
      case 'priority':
        return <TaskDetailEditPriority />;
    }
  };

  const { setIdTaskDetailOpen } = useWebDrawer();

  const bottomSheetTaskFormRef = useRef<BottomSheetModal>(null);

  const { projectId, goBackOnComplete, taskname } = params || {
    projectId: '',
    goBackOnComplete: false,
  };

  useEffect(() => {
    if (Platform.OS === 'web') {
      const unsubscribe = navigation
        .getParent()
        ?.addListener('drawerItemPress', (e) => {
          navigation.reset({ routes: [{ name: 'tasks' }], index: 0 });
          e.preventDefault();
        });
      return unsubscribe;
    }
  }, [navigation]);

  const [duplicateTaskFiles] = useDuplicateTaskFilesMutation({
    onCompleted: () => {
      // NOTE: If errors are returned, inform the user
      navigation.goBack();
      if (isCopyTask && copyTask) {
        setTimeout(() => {
          setCurrentTask(undefined);
          setSelectedMembers([]);
        }, 1000);
      }
    },
    refetchQueries: [
      { query: ListTasksDocument },
      { query: ListProjectsDocument },
      'listTasks',
    ],
  });

  const [assignTaskUsers] = useAssignTaskUsersMutation({
    onCompleted: () => {
      setTimeout(() => {
        setSelectedMembers([]);
      }, 1000);
      navigation.navigate('chat-detail-z', {
        id: chatId,
        showKeyboardFocus: true,
      });
    },
    onError: (error) => {
      Alert.alert(t('shared:error'), error.message);
    },
    refetchQueries: [{ query: ListMessagesDocument, variables: { chatId } }],
  });

  const toast = useToast();

  const [createTask] = useCreateTaskMutation({
    onCompleted: (data) => {
      // NOTE: If errors are returned, inform the user
      const { createTask } = data || { createTask: {} };

      Platform.OS !== 'web' &&
        toast.show(
          <TaskCreatedToast
            taskName={createTask.name}
            message={t('models:customToast.taskCreated')}
            duration={3000}
            onPress={() => {
              navigateToTask(createTask);
            }}
          />,
          {
            type: 'custom',
            duration: 3000,
            placement: 'top',
            animationType: 'slide-in',
            style: {
              backgroundColor: 'transparent',
              shadowColor: 'transparent',
            },
          }
        );

      if (isCopyTask && taskId) {
        if (
          (copyItems && !copyItems.includeFiles) ||
          (openModal && !includeFiles)
        ) {
          setTimeout(() => {
            setCurrentTask(undefined);
            setSelectedMembers([]);
          }, 1000);
          return;
        }

        // copy task files
        setCopyTask(createTask);
        createTask &&
          duplicateTaskFiles({
            variables: {
              sourceId: taskId,
              destinationId: createTask.id,
            },
          });
        return;
      }

      if (isAssignTask) {
        if (Platform.OS !== 'web') {
          const selectedUserIds = selectedMembers.map((u) => {
            return { userId: u.id, role: defaultAssignMemberTaskRole };
          });
          createTask.id &&
            chatId &&
            assignTaskUsers({
              variables: {
                id: data.createTask.id,
                members: selectedUserIds,
                chatId: chatId,
              },
            });
        }

        return;
      }

      if (goBackOnComplete) {
        if (Platform.OS !== 'web') {
          const collectionToUpdate = tagsCollection?.findIndex(
            (t) => t.project.id === data.createTask.projectId
          );
          if (collectionToUpdate !== undefined && collectionToUpdate !== -1) {
            // put task into correct coll based on project
            if (
              !tagsCollection?.[collectionToUpdate].tasks.find(
                (t) => t.id === data.createTask.id
              )
            ) {
              const newTagsColl = [...(tagsCollection ?? [])];
              newTagsColl[collectionToUpdate] = {
                ...newTagsColl[collectionToUpdate],
                tasks: [
                  ...newTagsColl[collectionToUpdate].tasks,
                  data.createTask,
                ],
              };

              setTags(chatId, newTagsColl);
            }
          } else if (me) {
            // new tags coll
            const newTagsColl = [
              ...(tagsCollection ?? []),
              {
                tasks: [data.createTask],
                project: data.createTask.project,
                author: me,
              },
            ];

            setTags(chatId, newTagsColl);
            navigation.navigate('chat-detail-z', {
              id: chatId,
              showKeyboardFocus: true,
            });
          }
        } else {
          navigation.goBack();
        }
        return;
      } else if (Platform.OS === 'web') {
        setIdTaskDetailOpen(createTask.id);
      }
    },
    refetchQueries: [
      { query: ListTasksDocument },
      { query: ListProjectsDocument },
      { query: GetProjectDocument, variables: { id: projectId } },
      'listTasks',
    ],
  });

  const [updateTask] = useUpdateTaskMutation({
    onCompleted: () => navigation.goBack(),
    refetchQueries: [
      { query: ListTasksDocument },
      { query: ListProjectsDocument },
      'listTasks',
    ],
  });

  const submit = (values: FormValues) => {
    const {
      name,
      description,
      fixedOrder,
      frequency,
      recurring,
      dependencyIds,
      projectId,
      startDate,
      dueDate,
      priority,
      status,
      users,
      phaseId,
      skillIds,
      completedAt,
    } = values;

    const members: TaskMemberAttributes[] = (users || []).map((u) => {
      return {
        userId: u.id,
        role: u.role || defaultTaskRole,
      };
    });

    const attributes = {
      name,
      description,
      fixedOrder,
      frequency,
      dependencyIds,
      recurring,
      projectId,
      phaseId,
      priority,
      status,
      members: isAssignTask ? [] : members,
      skillIds,
      ...{
        startDate: startDate
          ? beginningOfDayUTC(startDate).toISOString()
          : null,
      },
      ...{ dueDate: dueDate ? beginningOfDayUTC(dueDate).toISOString() : null },
      ...(isCopyTask &&
        copyItems?.includeCheckLists && {
          checklists: values.checklists?.map(
            (item: TaskChecklistAttributes) => {
              return { name: item.name, completed: false };
            }
          ),
        }),
      ...(openModal && {
        checklists: checkList?.map((item: TaskChecklistAttributes) => {
          return { name: item.name, completed: false };
        }),
      }),
      ...{
        completedAt: !isCopyTask && !openModal && taskId ? completedAt : null,
      },
    };

    if (taskId && !isCopyTask && !openModal) {
      updateTask({ variables: { id: taskId, attributes } });
    } else {
      createTask({ variables: { attributes } });
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values) => submit(values)}
      validationSchema={createTaskFormSchema}>
      {({ values }) => {
        if (isShortModal) {
          if (taskId && !isCopyTask && !openModal) {
            if (editField === 'dates') {
              if (Platform.OS === 'web') {
                return <EditTaskDates />;
              } else {
                return (
                  <BottomSheetModalProvider>
                    <EditTaskDatesBottomSheet ref={bottomSheetTaskFormRef} />
                  </BottomSheetModalProvider>
                );
              }
            } else {
              return getEditPage();
            }
          } else {
            return (
              <BottomSheetModalProvider>
                <TaskCreateBottomSheet
                  parentProject={!!projectId}
                  ref={bottomSheetTaskFormRef}
                  taskname={taskname}
                  selectedList={selectedList}
                  isCopyTask={isCopyTask}
                  values={values}
                  goBackOnComplete={goBackOnComplete || false}
                  chatId={chatId}
                  isAssignTask={isAssignTask}
                />
              </BottomSheetModalProvider>
            );
          }
        }

        return (
          <Stack.Navigator
            initialRouteName='task-form'
            screenOptions={{
              headerShown: Platform.OS !== 'web',
            }}>
            <Stack.Screen
              name='task-form'
              options={() => ({
                detachPreviousScreen: false,
                header: () => (
                  <Box paddingTop='xs'>
                    <SecondaryHeader
                      title={t('models:tasks.edit.title')}
                      searchable={false}
                    />
                  </Box>
                ),
              })}>
              {() => (
                <TaskForm parentProject={!!projectId} screenName={screenName} />
              )}
            </Stack.Screen>
            <Stack.Group
              screenOptions={{
                headerShown: false,
                presentation: 'transparentModal',

                cardOverlayEnabled: Platform.OS !== 'web',
                cardStyleInterpolator: ({ current: { progress } }) => ({
                  containerStyle: {
                    opacity: progress.interpolate({
                      inputRange: [0, 1],
                      outputRange: [0, 1],
                      extrapolate: 'clamp',
                    }),
                  },
                  overlayStyle: {
                    opacity: progress.interpolate({
                      inputRange: [0, 1],
                      outputRange: [0, 0.6],
                      extrapolate: 'clamp',
                    }),
                  },
                }),
              }}>
              <Stack.Screen
                name='add-edit-task-modal-members'
                component={AddEditTaskMembersFromModal}
                options={{
                  header: () => <SimpleHeader title='Add Members' />,
                }}
              />
              <Stack.Screen
                name='project-select'
                component={ProjectSelect}
                options={{ title: t('shared:projects') }}
              />
              <Stack.Screen
                name='skill-select'
                component={SkillSelect}
                options={{ title: t('shared:skills') }}
              />
              <Stack.Screen
                name='dependency-select'
                component={DependencySelect}
                options={{ title: t('shared:dependencies') }}
              />
              <Stack.Screen name='date-select' component={DateSelect} />
              <Stack.Screen name='edit-date-select'>
                {() => (
                  <BottomSheetModalProvider>
                    <EditTaskDatesBottomSheet ref={bottomSheetTaskFormRef} />
                  </BottomSheetModalProvider>
                )}
              </Stack.Screen>
            </Stack.Group>

            <Stack.Group
              screenOptions={{
                headerShown: false,
                presentation: 'transparentModal',
              }}>
              <Stack.Screen
                name='task-copy-search'
                component={TaskCopySearch}
                options={{ title: t('shared:copy') }}
              />
              <Stack.Screen
                name='task-copy-select'
                component={TaskCopySelect}
                options={{ title: t('shared:select') }}
              />
            </Stack.Group>
          </Stack.Navigator>
        );
      }}
    </Formik>
  );
};

export default TaskFormStack;
