import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Modal,
  Platform,
  StyleSheet,
  TouchableOpacity,
  TouchableWithoutFeedback,
} from 'react-native';
import Highlighter from 'react-native-highlight-words';

import Avatar from '@components/Avatar/Avatar';
import { ModalHeader } from '@components/Headers/ModalHeader';
import ContactGroupMember from '@components/Invite/ContactGroupMember';
import { AddMemberType } from '@components/Invite/SelectMembersList';
import { ConfirmModal } from '@components/Modals/ConfirmModal';
import { Box, ShadowBox, Text } from '@components/Restyle';
import Icon from '@components/shared/Icon/Icon';
import { ProjectMemberRole, User } from '@graphql/generated';
import theme from '@themes/theme';
export type MemberType<Roles> = {
  user: User;
  role: Roles;
};
interface MemberRoleProps<Roles> {
  roles?: string[];
  member: MemberType<Roles> | AddMemberType;
  updateRole?: (role: Roles) => void;
  removeMember?: (member: MemberType<Roles> | AddMemberType) => void;
  filterVal?: string;
  onPress?: () => void;
  isCreateTask?: boolean;
  isCreateProject?: boolean;
  isEditProject?: boolean;
  avaterSize?: 'memberList' | 'xs' | 'small' | 'medium' | 'default' | 'large';
  showUserInfo?: boolean;
  onPressMember?: (item: User) => void;
}
export const MemberRole = <Roles,>({
  member,
  updateRole,
  removeMember,
  filterVal = '',
  onPress,
  roles,
  isCreateTask,
  isCreateProject,
  isEditProject,
  avaterSize = 'medium',
  showUserInfo = false,
  onPressMember,
}: MemberRoleProps<Roles>) => {
  const { user, role } = member;
  const {
    name = '',
    skills,
    email,
    phoneNumber,
    id,
    avatar,
  } = isCreateTask || isCreateProject || isEditProject ? member : user;
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const isContactGroup = !!(member?.user?.type || member?.type);

  const [isDeleteModalVisible, setDeleteModalVisible] =
    useState<boolean>(false);
  const memberFullName =
    isCreateTask || isCreateProject || isEditProject
      ? member.firstName + ' ' + member.lastName
      : member.user.firstName + ' ' + member.user.lastName;
  const { t } = useTranslation('models');
  const { t: f } = useTranslation('format');
  const [skillWidth, setSkillWidth] = useState(0);
  const [skillWidth1, setSkillWidth1] = useState(0);
  const [skillWidth2, setSkillWidth2] = useState(0);
  const [skillWidth3, setSkillWidth3] = useState(0);
  const [skillWidth4, setSkillWidth4] = useState(0);
  const [skillWidth5, setSkillWidth5] = useState(0);
  const [skillWidth6, setSkillWidth6] = useState(0);
  const [skillWidth7, setSkillWidth7] = useState(0);
  const [skillWidth8, setSkillWidth8] = useState(0);
  const [skillWidth9, setSkillWidth9] = useState(0);
  const [skillWidth10, setSkillWidth10] = useState(0);
  const [skillWidth100, setSkillWidth100] = useState(0);

  const getUserName = () => {
    if (name.length <= 20) return name;
    return name.substring(0, 20) + '...';
  };
  const [isVisible, setIsVisible] = useState(false);

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };

  const renderSkills = () => {
    if (skills.length === 0) return null;
    const skill100Box = (
      <Box
        key={skills.length + 1}
        marginTop='xxs'
        marginRight='xxs'
        padding='xxs'
        borderRadius='xxs'
        backgroundColor='grey01'
        onLayout={(e) => setSkillWidth100(e.nativeEvent.layout.width)}>
        <Text variant='bodyTaskName' color='textPrimary' numberOfLines={1}>
          ...
        </Text>
      </Box>
    );

    const getSkillsWidth = (index: number) => {
      switch (index) {
        case 0:
          return theme.spacing.xxs;
        case 1:
          return skillWidth1 + theme.spacing.xxs;
        case 2:
          return skillWidth1 + skillWidth2 + theme.spacing.xxs * index;
        case 3:
          return (
            skillWidth1 + skillWidth2 + skillWidth3 + theme.spacing.xxs * index
          );
        case 4:
          return (
            skillWidth1 +
            skillWidth2 +
            skillWidth3 +
            skillWidth4 +
            theme.spacing.xxs * index
          );
        case 5:
          return (
            skillWidth1 +
            skillWidth2 +
            skillWidth3 +
            skillWidth4 +
            skillWidth5 +
            theme.spacing.xxs * index
          );
        case 6:
          return (
            skillWidth1 +
            skillWidth2 +
            skillWidth3 +
            skillWidth4 +
            skillWidth5 +
            skillWidth6 +
            theme.spacing.xxs * index
          );
        case 7:
          return (
            skillWidth1 +
            skillWidth2 +
            skillWidth3 +
            skillWidth4 +
            skillWidth5 +
            skillWidth6 +
            skillWidth7 +
            theme.spacing.xxs * index
          );
        case 8:
          return (
            skillWidth1 +
            skillWidth2 +
            skillWidth3 +
            skillWidth4 +
            skillWidth5 +
            skillWidth6 +
            skillWidth7 +
            skillWidth8 +
            theme.spacing.xxs * index
          );
        case 9:
          return (
            skillWidth1 +
            skillWidth2 +
            skillWidth3 +
            skillWidth4 +
            skillWidth5 +
            skillWidth6 +
            skillWidth7 +
            skillWidth8 +
            skillWidth9 +
            theme.spacing.xxs * index
          );
        case 10:
          return (
            skillWidth1 +
            skillWidth2 +
            skillWidth3 +
            skillWidth4 +
            skillWidth5 +
            skillWidth6 +
            skillWidth7 +
            skillWidth8 +
            skillWidth9 +
            skillWidth10 +
            theme.spacing.xxs * index
          );
        default:
          return 0;
      }
    };

    const getSkillMaxWidth = (index: number) => {
      return skills.length === index + 1
        ? getSkillMaxWidthNotHaveEllipsis(index)
        : getSkillMaxWidthHaveEllipsis(index);
    };

    const getSkillMaxWidthNotHaveEllipsis = (index: number) => {
      return (
        skillWidth - getSkillsWidth(index) + (index > 0 ? theme.spacing.xxs : 0)
      );
    };

    const getSkillMaxWidthHaveEllipsis = (index: number) => {
      return (
        skillWidth - getSkillsWidth(index) - skillWidth100 - theme.spacing.xs
      );
    };

    const getSkillBox = (index: number, needSetWidth: boolean) => {
      let setSkillWidth: (value: React.SetStateAction<number>) => void;
      switch (index) {
        case 0:
          setSkillWidth = setSkillWidth1;
          break;
        case 1:
          setSkillWidth = setSkillWidth2;
          break;
        case 2:
          setSkillWidth = setSkillWidth3;
          break;
        case 3:
          setSkillWidth = setSkillWidth4;
          break;
        case 4:
          setSkillWidth = setSkillWidth5;
          break;
        case 5:
          setSkillWidth = setSkillWidth6;
          break;
        case 6:
          setSkillWidth = setSkillWidth7;
          break;
        case 7:
          setSkillWidth = setSkillWidth8;
          break;
        case 8:
          setSkillWidth = setSkillWidth9;
          break;
        case 9:
          setSkillWidth = setSkillWidth10;
          break;
      }
      return (
        <Box
          key={index}
          marginTop='xxs'
          marginRight='xxs'
          padding='xxs'
          borderRadius='xxs'
          backgroundColor='grey01'
          maxWidth={getSkillMaxWidth(index)}
          onLayout={(e) =>
            needSetWidth &&
            setSkillWidth &&
            setSkillWidth(e.nativeEvent.layout.width)
          }>
          <Text variant='bodyTaskName' color='textPrimary' numberOfLines={1}>
            {skills[index].name}
          </Text>
        </Box>
      );
    };

    const getSkillsBox = (
      skillsNum: number,
      haveEllipsis: boolean,
      needSetWidth: boolean
    ) => {
      const newSkills = [];
      for (let i = 0; i < skillsNum; i++) {
        newSkills.push(getSkillBox(i, needSetWidth));
      }
      return [newSkills, haveEllipsis ? skill100Box : undefined];
    };

    for (let i = 1; i <= skills.length; i++) {
      if (getSkillMaxWidthNotHaveEllipsis(i) > skillWidth100 * 2) {
        if (skills.length > i) {
          if (getSkillMaxWidthHaveEllipsis(i) <= theme.spacing.xxs) {
            return getSkillsBox(i, true, false);
          } else {
            continue;
          }
        }
        return getSkillsBox(i, false, true);
      }
      return getSkillsBox(i - 1 > 0 ? i - 1 : 1, skills.length > 1, true);
    }
  };

  const memberRoleOwner = () => {
    return (
      <Box flexDirection='row' alignItems='center'>
        <Box flex={1} flexDirection='row' alignItems='center'>
          <Avatar
            id={id}
            avatar={avatar}
            label={name}
            isMultiple={isContactGroup}
            size={avaterSize}
            onPress={() =>
              onPressMember &&
              onPressMember(
                isCreateTask || isCreateProject || isEditProject ? member : user
              )
            }
          />
          <Text
            accessibilityLabel='Task member'
            variant='bodyTaskName'
            marginLeft='xs'
            style={{ flex: 1 }}
            numberOfLines={isCreateProject || isCreateTask ? 1 : 0}>
            <Highlighter
              autoEscape
              highlightStyle={{
                color: theme.colors.greenSecondary,
                backgroundColor: theme.colors.background,
              }}
              searchWords={filterVal?.split(' ') || ''}
              textToHighlight={
                Platform.OS === 'web' ? name : getUserName() || ''
              }
            />
          </Text>
        </Box>
        {showUserInfo && (
          <>
            <Box
              flex={1}
              flexDirection='row'
              justifyContent='flex-start'
              flexWrap='wrap'
              onLayout={(e) => setSkillWidth(e.nativeEvent.layout.width)}>
              {renderSkills()}
            </Box>
            <Box flex={1} flexDirection='row' justifyContent='flex-start'>
              <Text variant='metadata1' color='textPrimary' numberOfLines={1}>
                {email}
              </Text>
            </Box>
            <Box flex={0.5} flexDirection='row' justifyContent='flex-end'>
              <Text variant='metadata1' color='textPrimary' numberOfLines={1}>
                {f('phoneNumber', {
                  val: phoneNumber,
                })}
              </Text>
            </Box>
          </>
        )}
        <Box flex={0.5} flexDirection='row' justifyContent='flex-end'>
          {role && (
            <Text
              accessibilityLabel='Member role'
              variant={isEditProject ? 'webBodySecondary' : 'buttonLabel'}
              marginLeft='s'
              color='textPrimary'
              style={{ textTransform: 'capitalize' }}>
              {role.toString().toLowerCase()}
            </Text>
          )}
        </Box>
      </Box>
    );
  };

  return (
    <Box>
      {isDeleteModalVisible && (
        <ConfirmModal
          showModal={isDeleteModalVisible}
          onClose={() => setDeleteModalVisible(false)}
          onPress={() => removeMember && removeMember(member)}
          buttonText={t('teams.removeTeamMember.remove')}
          title={t('teams.removeTeamMember.title')}
          message={t('teams.removeTeamMember.message', {
            member: memberFullName,
          })}
        />
      )}
      <Modal animationType='fade' transparent={true} visible={modalOpen}>
        <Box style={styles.modalContainer}>
          <TouchableWithoutFeedback
            onPress={() => setModalOpen(!modalOpen)}
            style={styles.modal}
            accessibilityLabel='CloseModalButton'>
            <Box style={styles.modal} />
          </TouchableWithoutFeedback>

          {roles && (
            <ShadowBox
              variant='thin'
              style={Platform.OS === 'web' ? styles.popUpWeb : styles.popUp}>
              <Box
                marginVertical='m'
                marginHorizontal='m'
                flex={1}
                justifyContent='space-between'>
                <Box
                  style={styles.modalHeader}
                  accessibilityLabel='RoleSelectModal'>
                  <ModalHeader
                    title={t('projects.roles.selectRole')}
                    submitLabel='Cancel'
                    onCancel={() => setModalOpen(false)}
                    showCancel={false}
                    onSubmit={() => setModalOpen(false)}
                  />
                </Box>
                {roles.map((i, index) => {
                  return (
                    <TouchableOpacity
                      key={index}
                      onPress={() => {
                        updateRole && updateRole(i);
                        setModalOpen(false);
                      }}
                      accessibilityLabel={i}>
                      <Box
                        flexDirection='row'
                        justifyContent='space-between'
                        alignItems='center'
                        marginBottom={
                          index !== roles.length - 1 ? 'l' : undefined
                        }>
                        <Text
                          variant='body'
                          style={{ textTransform: 'capitalize' }}>
                          {i.toLowerCase()}
                        </Text>
                        {i.toLowerCase() === role.toLowerCase() && (
                          <Icon
                            variant='s'
                            name='Check'
                            color='greenSecondary'
                            accessibilityLabel={`SelectedRole-${i}`}
                          />
                        )}
                      </Box>
                    </TouchableOpacity>
                  );
                })}

                {removeMember && (
                  <>
                    <Box
                      backgroundColor='grey02'
                      height={1}
                      marginVertical='m'
                    />
                    <TouchableOpacity
                      onPress={() => {
                        setModalOpen(false);
                        setDeleteModalVisible(true);
                      }}
                      accessibilityLabel='Remove'>
                      <Text variant='body' color='alertRed'>
                        Remove
                      </Text>
                    </TouchableOpacity>
                  </>
                )}
              </Box>
            </ShadowBox>
          )}
        </Box>
      </Modal>
      {role === 'OWNER' && memberRoleOwner()}
      {role !== 'OWNER' && (
        <TouchableOpacity
          onPress={() => (onPress ? onPress() : setModalOpen(true))}
          disabled={
            (!updateRole && !onPress) || (isContactGroup && !isEditProject)
          }
          accessibilityLabel='MemberCard'>
          <Box
            flexDirection='row'
            alignItems='center'
            py={isContactGroup ? 'none' : 'xxs'}>
            <Box flex={1} flexDirection='row' alignItems='center'>
              <Avatar
                id={id}
                avatar={avatar}
                label={name}
                size={avaterSize}
                isMultiple={isContactGroup}
                onPress={() =>
                  onPressMember &&
                  onPressMember(
                    isCreateTask || isCreateProject || isEditProject
                      ? member
                      : user
                  )
                }
              />
              <Box flexDirection='column'>
                <Text
                  accessibilityLabel='Task member'
                  variant={
                    isEditProject ? 'webBodySecondary' : 'labelEmphasized'
                  }
                  marginLeft='xs'
                  style={{ flex: 1 }}
                  numberOfLines={
                    isCreateProject || isCreateTask || isEditProject ? 1 : 0
                  }>
                  <Highlighter
                    autoEscape
                    highlightStyle={{
                      color: theme.colors.greenSecondary,
                      backgroundColor: theme.colors.background,
                    }}
                    searchWords={filterVal?.split(' ') || ''}
                    textToHighlight={
                      Platform.OS === 'web' ? name : getUserName() || ''
                    }
                  />
                </Text>
                <Box marginLeft='xs'>
                  {member?.user?.memberCount && (
                    <TouchableOpacity onPress={toggleVisibility}>
                      <Box flexDirection='row' alignItems='center'>
                        <Text variant='bodySecondary' color='textSecondary'>
                          {'Contact Group (' + member?.user?.memberCount + ')'}
                        </Text>
                        <Icon
                          name='ChevronDown'
                          style={{ width: 24, height: 24, marginTop: 4 }}
                          color='textSecondary'
                        />
                      </Box>
                    </TouchableOpacity>
                  )}

                  {member?.memberCount && (
                    <TouchableOpacity onPress={toggleVisibility}>
                      <Box flexDirection='row' alignItems='center'>
                        <Text variant='bodySecondary' color='textSecondary'>
                          {'Contact Group (' + member?.memberCount + ')'}
                        </Text>
                        <Icon
                          name='ChevronDown'
                          style={{ width: 24, height: 24, marginTop: 4 }}
                          color='textSecondary'
                        />
                      </Box>
                    </TouchableOpacity>
                  )}
                </Box>
              </Box>
            </Box>
            {showUserInfo && (
              <>
                <Box
                  flex={1}
                  flexDirection='row'
                  justifyContent='flex-start'
                  flexWrap='wrap'
                  onLayout={(e) => setSkillWidth(e.nativeEvent.layout.width)}>
                  {renderSkills()}
                </Box>
                <Box flex={1} flexDirection='row' justifyContent='flex-start'>
                  <Text
                    variant='metadata1'
                    color='textPrimary'
                    numberOfLines={1}>
                    {email}
                  </Text>
                </Box>
                <Box flex={0.5} flexDirection='row' justifyContent='flex-end'>
                  <Text
                    variant='metadata1'
                    color='textPrimary'
                    numberOfLines={1}>
                    {f('phoneNumber', {
                      val: phoneNumber,
                    })}
                  </Text>
                </Box>
              </>
            )}
            <Box flex={0.5} flexDirection='row' justifyContent='flex-end'>
              {role && (
                <Text
                  accessibilityLabel='Member role'
                  variant={isEditProject ? 'webBodySecondary' : 'buttonLabel'}
                  marginLeft='s'
                  color='textPrimary'
                  style={{ textTransform: 'capitalize' }}>
                  {isContactGroup && !isEditProject
                    ? ProjectMemberRole.Viewer.toLowerCase()
                    : role?.toLowerCase()}
                </Text>
              )}

              {updateRole && !(isContactGroup && !isEditProject) && (
                <Icon
                  name='ChevronDown'
                  color='textPrimary'
                  marginLeft='s'
                  onPress={() => setModalOpen(true)}
                  accessibilityLabel='RoleDropDown'
                />
              )}
            </Box>
          </Box>
        </TouchableOpacity>
      )}

      <ContactGroupMember
        isVisible={isVisible}
        contact={member.user ?? member}
        filterVal={filterVal}
        isFromMemberRole
        isEditProject={isEditProject}
      />
    </Box>
  );
};

const styles = StyleSheet.create({
  modal: {
    ...StyleSheet.absoluteFillObject,
    backgroundColor: 'black',
    opacity: 0.5,
  },
  modalContainer: {
    ...StyleSheet.absoluteFillObject,
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalHeader: {
    paddingBottom: theme.spacing.xl,
    paddingTop: theme.spacing.l,
  },
  popUp: {
    position: 'absolute',
    backgroundColor: 'white',
    width: '100%',
    bottom: 0,
    paddingBottom: theme.spacing.xxl,
    borderTopLeftRadius: theme.borderRadii.l,
    borderTopRightRadius: theme.borderRadii.l,
  },
  popUpWeb: {
    position: 'absolute',
    backgroundColor: 'white',
    width: 300,
    paddingBottom: theme.spacing.l,
    borderRadius: theme.borderRadii.s,
  },
});
