import React from 'react';
import { FlatList, ListRenderItemInfo } from 'react-native';

import theme from '@themes/theme';

import PopupItem from './PopupItem';
import { ShadowBox, Text } from '../../Restyle';

import type { MenuItem } from './PopupItem';

export type PopupProps = {
  title?: string;
  menuList: MenuItem[];
  width?: number;
  closePopFn?: () => void;
  marginIconToText?: keyof typeof theme.spacing;
};

const Popup: React.FC<PopupProps> = ({
  menuList,
  width,
  closePopFn,
  title,
  marginIconToText = 'm',
}) => {
  const renderItem = ({ item }: ListRenderItemInfo<MenuItem>) => {
    return (
      <PopupItem
        menuItem={item}
        closePopFn={closePopFn}
        marginIconToText={marginIconToText}
      />
    );
  };

  return (
    <ShadowBox
      accessibilityLabel='Options List'
      flex={1}
      width={width}
      alignItems='flex-start'
      justifyContent='center'
      borderRadius='xs'
      paddingHorizontal='m'
      paddingVertical='m'
      variant='base'
      backgroundColor='white'>
      <>
        {title && (
          <Text
            accessibilityLabel={title}
            variant='labelSmall'
            color='grey04'
            marginBottom='m'>
            {title}
          </Text>
        )}
        <FlatList
          style={{ width: `100%` }}
          data={menuList}
          renderItem={renderItem}
          keyExtractor={(item) => item.name}
        />
      </>
    </ShadowBox>
  );
};

export default Popup;
export { MenuItem };
