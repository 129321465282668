import React from 'react';
import { useTranslation } from 'react-i18next';
import { TouchableOpacity, StyleSheet, Platform } from 'react-native';

import { Box, Text } from '@components/Restyle';
import Icon from '@components/shared/Icon/Icon';
import Images from '@themes/images';
import theme from '@themes/theme';
interface SnackbarProps {
  projectLeft: number;
  title: string;
  description: string;
  iconName: keyof typeof Images;
  onPress?: () => void;
  onClose: () => void;
}

export const SnackbarForProject: React.FC<SnackbarProps> = ({
  projectLeft = 0,
  title,
  description,
  iconName,
  onPress,
  onClose,
}) => {
  const { t } = useTranslation('models');
  return (
    <TouchableOpacity onPress={onPress}>
      <Box
        flexDirection='row'
        padding='m'
        borderRadius='xs'
        backgroundColor={projectLeft > 0 ? 'black' : 'alertRedMild'}>
        <Box marginRight='m' style={{ paddingHorizontal: 10 }}>
          <Icon width={50} height={52} color='textPrimary' name={iconName} />
        </Box>
        <Box flex={1}>
          <Text
            variant='labelLarge'
            color={projectLeft > 0 ? 'white' : 'black'}
            numberOfLines={2}>
            {title}
          </Text>
          <Text
            variant='body'
            color={projectLeft > 0 ? 'white' : 'black'}
            numberOfLines={2}>
            {description}
          </Text>
        </Box>
        <Box marginLeft='m' alignItems='flex-end' justifyContent='flex-end'>
          <Icon
            variant='s'
            color={projectLeft > 0 ? 'white' : 'black'}
            name='X'
            onPress={onClose}
          />
          <Box
            flex={Platform.OS === 'web' ? undefined : 1}
            alignItems='center'
            justifyContent='center'>
            <TouchableOpacity onPress={onPress}>
              <Box
                style={[
                  styles.containerLink,
                  {
                    backgroundColor:
                      projectLeft > 0
                        ? theme.colors.greenSecondaryMild
                        : 'black',
                  },
                ]}>
                <Text
                  variant='webSmall'
                  color={projectLeft > 0 ? 'greenSecondary' : 'white'}>
                  {t('models:projects.snackBar.upgrade')}
                </Text>
              </Box>
            </TouchableOpacity>
          </Box>
        </Box>
      </Box>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  containerLink: {
    borderRadius: 8,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 125,
    height: 32,
    paddingHorizontal: 16,
    paddingVertical: 8,
  },
});
