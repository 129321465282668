import { createStackNavigator } from '@react-navigation/stack';
import { Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Platform } from 'react-native';
import { Toast, useToast } from 'react-native-toast-notifications';
import { InferType } from 'yup';

import Box from '@components/Box/Box';
import { CustomToast } from '@components/CustomToast/CustomToast';
import { TaskCreatedToast } from '@components/CustomToast/TaskCreatedToast';
import ColorPicker from '@components/FormModals/ColorPicker';
import MobileIconPicker from '@components/FormModals/IconPicker';
import IconPicker from '@components/FormModals/IconPicker.web';
import { MemberSelect } from '@components/FormModals/MemberSelect';
import { TeamSelect } from '@components/FormModals/TeamSelect';
import SecondaryHeader from '@components/Headers/SecondaryHeader';
import { WebModal } from '@components/Modals/WebModal.web';
import { ProjectCreateBottomSheet } from '@components/Projects/ProjectCreateBottomSheet';
import ProjectForm from '@components/Projects/ProjectForm';
import Text from '@components/Text/Text';
import { useWebDrawer } from '@components/Web/Drawer/WebDrawerContext';
import {
  ListProjectsDocument,
  Project,
  ProjectMemberAttributes,
  ProjectMemberRole,
  Team,
  useCreateProjectMutation,
  useListProductsQuery,
  useUpdateProjectMutation,
} from '@graphql/generated';
import useMe from '@hooks/useMe';
import { useTeamFromQuery } from '@hooks/useTeamFromQuery';
import { stackScreenOptions } from '@navigation/payments/payments-stack';
import { useAppNavigation } from '@navigation/useAppNavigation';
import { projectFormSchema } from '@schemas/projectFormSchema';
import { ProjectCopySearch } from '@screens/Projects/ProjectCopySearch';
import { ProjectCopySelect } from '@screens/Projects/ProjectCopySelect.web';
import { ProjectLocationSearch } from '@screens/Projects/ProjectLocationSearch';
import { PROJECTS_AMOUNT } from '@src/constants/constants';
import { defaultProjectRole } from '@utils/projects';

export type FormValues = InferType<typeof projectFormSchema>;

export type ProjectFormStackParamList = {
  'create-project-form': { projectId?: Project['id'] };
  'project-location-search': undefined;
  'member-select': undefined;
  'team-select': undefined;
  'color-select': undefined;
  'icon-select': undefined;
  'project-copy-select': undefined;
  'project-copy-search': undefined;
  'create-project-modal': undefined;
};

const Stack = createStackNavigator<ProjectFormStackParamList>();

const ProjectFormStack: React.FC<{
  projectId?: string | undefined;
  initialValues: FormValues;
  isShortModal?: boolean;
  externalNavigate?: any;
}> = ({ projectId, initialValues, isShortModal, externalNavigate }) => {
  const navigation = externalNavigate ? externalNavigate : useAppNavigation();
  const [teamIdentifier, setTeamId] = useState('');
  const { t } = useTranslation();
  const {
    setIsCreateProjectOpen,
    setEditProjectId,
    setIsTaskWebPanelOpen,
    setIdTaskDetailOpen,
    setIdProjectOfTaskDetailOpen,
    setIsTaskWebPanelMaximize,
    setUpgradeTeamPlanOpen,
    setIdTeamOpen,
    setIsTeamPersonal,
  } = useWebDrawer();
  const { data: listProductsData } = useListProductsQuery();
  const toast = useToast();
  const { refetch } = useTeamFromQuery({
    teamId: teamIdentifier,
  });
  useEffect(() => {
    if (Platform.OS === 'web') {
      const unsubscribe = navigation
        .getParent()
        ?.addListener('drawerItemPress', (e) => {
          navigation.reset({ routes: [{ name: 'tabs' }], index: 0 });
          e.preventDefault();
        });
      return unsubscribe;
    }
  }, [navigation]);

  const [createProject] = useCreateProjectMutation({
    onCompleted: ({ createProject }) => {
      if (!createProject) return;

      if (Platform.OS !== 'web') {
        toast.show(
          <TaskCreatedToast
            taskName={createProject.name}
            isFromProject
            message={t('models:customToast.projectCreated')}
            duration={3000}
            onPress={() => {
              navigation.navigateToProject(createProject);
            }}
          />,
          {
            type: 'custom',
            duration: 3000,
            placement: 'top',
            animationType: 'slide-in',
            style: {
              backgroundColor: 'transparent',
              shadowColor: 'transparent',
            },
          }
        );
      } else {
        setIsCreateProjectOpen(false);
        setEditProjectId('');
        navigation.navigateToProject(createProject);
      }
    },
    onError: (e) => console.log('err creating project', e),
    refetchQueries: [
      { query: ListProjectsDocument },
      'listProjects',
      'listProjectsRecent',
      'listTeams',
    ],
  });

  const [updateProject] = useUpdateProjectMutation({
    onCompleted: () => {
      // navigation.goBack();
      if (Platform.OS === 'web') {
        setIsCreateProjectOpen(false);
        setEditProjectId('');
      }
    },
    onError: (e) => console.log('err updating project', e),
    refetchQueries: [
      { query: ListProjectsDocument },
      'listProjects',
      'listProjectsRecent',
    ],
  });

  const getProductPlus = listProductsData?.listProducts?.find((product) =>
    product?.name?.toUpperCase()?.includes('PLUS')
  );
  const getProductTeam = listProductsData?.listProducts?.find((product) =>
    product?.name?.toUpperCase()?.includes('TEAM')
  );

  const planPlus = Number(PROJECTS_AMOUNT?.PLUS);
  const planBasic = Number(PROJECTS_AMOUNT?.BASIC);

  const canCreateProject = async (teamId: string) => {
    const res = await refetch({
      id: teamId || '',
    });
    const teamUpdated = res?.data.getTeam;

    const teamHasPlan = teamUpdated?.plan?.id;
    const teamPersonal = teamUpdated?.personal;
    const ruleTeamPersonalWithPlan =
      teamUpdated?.personal &&
      teamHasPlan &&
      teamUpdated?.numberOfProjectsUsed >= planPlus;
    const ruleTeamPersonalWithoutPlan =
      teamUpdated?.personal &&
      !teamHasPlan &&
      teamUpdated?.numberOfProjectsUsed >= planBasic;

    const ruleByTeamWithoutPlan = !teamUpdated?.personal && !teamHasPlan;

    const displayedLockCreateTeam =
      Boolean(ruleTeamPersonalWithPlan || ruleTeamPersonalWithoutPlan) ||
      ruleByTeamWithoutPlan;
    const projectLeft =
      Number(
        teamUpdated?.plan?.id ? PROJECTS_AMOUNT?.PLUS : PROJECTS_AMOUNT?.BASIC
      ) - (teamUpdated as Team)?.numberOfProjectsUsed;

    if (displayedLockCreateTeam) {
      Toast.show(
        <CustomToast
          onPress={() => {
            setIsCreateProjectOpen(false);
            setEditProjectId('');
            setIsTaskWebPanelOpen(false);
            setIsTaskWebPanelMaximize(false);
            setIdTaskDetailOpen('');
            setIdProjectOfTaskDetailOpen('');
            setUpgradeTeamPlanOpen(true);
            setIdTeamOpen(teamUpdated?.id);
            setIsTeamPersonal(teamPersonal);
          }}
          redirectTo='my-account'
          params={{
            screen: 'create-subscription',
            params: {
              teamId: teamUpdated?.id,
              isPersonal: teamPersonal,
              isPlanPlus: !!teamPersonal,
              planId: teamPersonal ? getProductPlus?.id : getProductTeam?.id,
            },
          }}
          isTryFree
        />,
        {
          type: 'custom',
          placement: 'bottom',
          duration: 10000,
          animationType: 'zoom-in',
        }
      );
      setIsCreateProjectOpen(false);
      setEditProjectId('');
      return false;
    }

    if (teamPersonal) {
      toast.show(
        <CustomToast
          onPress={() => {
            window.sessionStorage.setItem('goto_welcome', '-1');
            navigation.navigate('subscription-plans');
            setIsCreateProjectOpen(false);
            setEditProjectId('');
            setIsTaskWebPanelOpen(false);
            setIsTaskWebPanelMaximize(false);
            setIdTaskDetailOpen('');
            setIdProjectOfTaskDetailOpen('');
          }}
          redirectTo='my-account'
          params={{
            screen: 'create-subscription',
            params: {
              teamId: teamUpdated?.id,
              isPersonal: teamUpdated?.personal,
              isPlanPlus: teamUpdated?.personal,
              planId: getProductPlus?.id,
            },
          }}
          isTryFree>
          <Box>
            <Text
              fontSize={Platform.OS == 'web' ? 16 : 14}
              style={{ color: '#fff' }}>
              {projectLeft <= 0 ? 0 : projectLeft - 1}{' '}
              {t('models:customToast.projectLeft')}
            </Text>
            <Text
              fontSize={Platform.OS == 'web' ? 16 : 14}
              style={{ color: '#fff' }}>
              {t('models:customToast.upgradeToCreate')}
            </Text>
          </Box>
        </CustomToast>,
        {
          type: 'custom',
          placement: 'bottom',
          duration: 10000,
          animationType: 'zoom-in',
        }
      );
      setIsCreateProjectOpen(false);
      setEditProjectId('');
      return true;
    }
    return true;
  };

  const { me } = useMe();

  const submit = async (values: FormValues) => {
    const { teamId, name, location, description, color, icon, users } = values;
    const { address, latitude, longitude } = location || {
      address: undefined,
      latitude: undefined,
      longitude: undefined,
    };
    setTeamId(teamId);

    const members: ProjectMemberAttributes[] = (users || []).map((u) => {
      const type = u?.type === 'GROUP';
      const contactGroupRole = projectId
        ? (u.role as ProjectMemberRole)
        : ProjectMemberRole.Viewer;

      const role: ProjectMemberRole = type
        ? contactGroupRole

        : (u.role as ProjectMemberRole);
      const id = type ? me?.id : u.id;
      const groupId = type ? u.id : '';
      return {
        userId: id,
        groupId: groupId,
        role: role,
      };
    });

    const projectName = name.trim();
    const attributes = {
      teamId,
      name: projectName,
      address,
      latitude,
      longitude,
      description,
      color,
      icon,
      members,
    };

    if (projectId) {
      updateProject({ variables: { id: projectId, attributes } });
    } else {
      const res = await canCreateProject(teamId);
      if (res) {
        createProject({ variables: { attributes } });
      }
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values) => submit(values)}
      validationSchema={projectFormSchema}>
      {({ values }) => {
        const ownerId = values.users.find(
          (u) => u.role === ProjectMemberRole.Owner
        )?.id;
        if (isShortModal) return <ProjectCreateBottomSheet />;

        return (
          <Stack.Navigator
            initialRouteName='create-project-form'
            screenOptions={{
              headerShown: Platform.OS !== 'web',
            }}>
            <Stack.Screen
              name='create-project-form'
              options={() => ({
                header: () => (
                  <SecondaryHeader
                    title={
                      projectId
                        ? t('models:projects.edit.title')
                        : t('models:projects.create.title')
                    }
                    searchable={false}
                  />
                ),
              })}>
              {() => <ProjectForm screenName={projectId ? 'Edit' : 'Create'} />}
            </Stack.Screen>
            <Stack.Screen
              name='project-location-search'
              component={ProjectLocationSearch}
              options={{
                headerShown: false,
              }}
            />
            <Stack.Group screenOptions={stackScreenOptions}>
              <Stack.Screen name='member-select' options={{ title: 'Members' }}>
                {() => (
                  <MemberSelect
                    defaultRole={defaultProjectRole}
                    ownerId={ownerId}
                  />
                )}
              </Stack.Screen>
              <Stack.Screen
                name='team-select'
                component={TeamSelect}
                options={{ title: 'Team' }}
              />
              <Stack.Screen
                name='color-select'
                options={{
                  headerShown: false,
                }}>
                {(props) =>
                  Platform.OS !== 'web' ? (
                    <ColorPicker />
                  ) : (
                    <WebModal visible onClose={props.navigation.goBack}>
                      <ColorPicker />
                    </WebModal>
                  )
                }
              </Stack.Screen>
              <Stack.Screen
                name='icon-select'
                options={{
                  headerShown: false,
                }}>
                {(props) =>
                  Platform.OS !== 'web' ? (
                    <MobileIconPicker />
                  ) : (
                    <WebModal visible onClose={props.navigation.goBack}>
                      <IconPicker />
                    </WebModal>
                  )
                }
              </Stack.Screen>
            </Stack.Group>

            <Stack.Group
              screenOptions={{
                headerShown: false,
                presentation: 'transparentModal',
              }}>
              <Stack.Screen
                name='project-copy-search'
                component={ProjectCopySearch}
                options={{ title: 'Copy' }}
              />
              <Stack.Screen
                name='project-copy-select'
                component={ProjectCopySelect}
                options={{ title: 'Select' }}
              />
            </Stack.Group>
          </Stack.Navigator>
        );
      }}
    </Formik>
  );
};

export default ProjectFormStack;
