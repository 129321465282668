import { object, string, SchemaOf } from 'yup';

import { User, File } from '@graphql/generated';

const fileSchema: SchemaOf<Pick<File, 'id' | 'url' | 'cdnBaseUrl' | 'path'>> =
  object({
    cdnBaseUrl: string().defined(),
    id: string().defined(),
    path: string().defined(),
    url: string().defined(),
  });

export const userSchema: SchemaOf<
  Pick<
    User,
    'id' | 'avatar' | 'name' | 'firstName' | 'lastName' | 'phoneNumber'
  > & {
    role: string;
  }
> = object({
  id: string().defined(),
  avatar: fileSchema.notRequired().default(undefined).nullable(),
  name: string().defined(),
  firstName: string().nullable().notRequired(),
  lastName: string().nullable().notRequired(),
  phoneNumber: string().nullable(),
  role: string().nullable(),
  type: string().nullable().notRequired(),
  memberCount: string().nullable().notRequired(),
  users: object().nullable().notRequired(),
});

// TODO: research yup for passing in a generic role for each schema use case
// possibly create a new schema for each role enum
