import { useNavigation, useRoute } from '@react-navigation/native';
import debounce from 'lodash.debounce';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Alert,
  FlatList,
  ListRenderItemInfo,
  Platform,
  StyleSheet,
  TouchableOpacity,
} from 'react-native';

import { ConfirmModal } from '@components/Modals/ConfirmModal';
import RadioButton from '@components/RadioButton/RadioButton';
import { Box, ShadowBox, Text } from '@components/Restyle';
import ActivityIndicatorLoading from '@components/shared/ActivityIndicatorLoading';
import Button from '@components/shared/Button/Button';
import {
  ListProjectsDocument,
  useDuplicateProjectMutation,
  useGetProjectQuery,
} from '@graphql/generated';
import { ProjectsStackScreenProps } from '@navigation/projects/projects-stack';
import { useAppNavigation } from '@navigation/useAppNavigation';
import theme from '@themes/theme';

type TaskItem = { id: number; name: string; isSelected: boolean };

export type DataType = {
  id: number;
  value?: string;
  name: string;
  isSelected: boolean;
  list?: TaskItem[];
};

const list = [
  {
    id: 1,
    value: '',
    name: 'Project Details',
    isSelected: false,
    list: [{ id: 1, value: '', name: 'Tasks', isSelected: false }],
  },
  {
    id: 2,
    value: '',
    name: 'All Members',
    isSelected: false,
    list: [
      { id: 1, value: '', name: 'Admins', isSelected: false },
      { id: 2, value: '', name: 'Editors', isSelected: false },
      { id: 3, value: '', name: 'Viewers', isSelected: false },
    ],
  },
  {
    id: 3,
    value: '',
    name: 'All Files',
    isSelected: false,
    list: [{ id: 1, value: '', name: 'With Tags', isSelected: false }],
  },
];
const DEBOUNCE = 5000;

export const CopyProjectDetails: React.FC = () => {
  const { navigateToProject, goBack } = useAppNavigation();
  const {
    params: { projectId },
  } = useRoute<ProjectsStackScreenProps<'project-copy-modal'>['route']>();

  const { data: project } = useGetProjectQuery({
    variables: {
      id: projectId,
    },
    skip: !projectId,
  });

  const [disableCopyProject, setDisableCopyProject] = useState(
    project?.getProject.team?.hasReachedMaxProjects
  );

  const [showDisableModal, setShowDisableModal] = useState(false);

  const [selectedList, setIsSelected] = useState(list);
  const [isNoneSelected, setIsNoneSelected] = useState(true);
  const [loading, setloading] = useState(false);
  const [showAlertModal, setShowAlertModal] = useState(false);
  const [isAlertShow, setIsAlertShow] = useState(false);
  const navigation = useNavigation();
  const [duplicateProject] = useDuplicateProjectMutation({
    refetchQueries: [
      { query: ListProjectsDocument },
      'listProjects',
      'listProjectsRecent',
      'listTeams',
    ],
  });
  const { t } = useTranslation();

  const showAlert = () => {
    setIsAlertShow(true);
    if (Platform.OS === 'web') {
      setShowAlertModal(true);
    } else {
      goBack();
      Alert.alert(
        t('models:projects.duplicate.title'),
        t('models:projects.duplicate.message')
      );
    }
  };

  const debouncedAlert = useCallback(debounce(showAlert, DEBOUNCE), []);

  const handleUpgrade = () => {
    setShowDisableModal(true);
    Platform.OS !== 'web' &&
      Alert.alert(
        t('models:projects.snackBar.freeProjectLimit'),
        t('models:projects.snackBar.createMoreProjects'),
        [
          { text: 'Cancel', onPress: () => goBack() },
          {
            text: 'Upgrade',
            onPress: () => {
              navigation.navigate('payments-stack', {
                screen: 'upgrade-plus',
              });
            },
          },
        ]
      );
  };

  const createProject = () => {
    if (disableCopyProject) {
      handleUpgrade();
      return;
    }
    if (projectId != null) {
      setloading(true);
      duplicateProject({
        variables: {
          id: projectId,
          includeTasks: selectedList[0].list[0].isSelected,
          includePhases: false,
          includeMembers: selectedList[1].isSelected,
          includeAdmins: selectedList[1].list[0].isSelected,
          includeEditors: selectedList[1].list[1].isSelected,
          includeViewers: selectedList[1].list[2].isSelected,
          includeFiles: selectedList[2].list[0].isSelected,
        },
        onCompleted: (data) => {
          setloading(false);
          debouncedAlert.cancel();
          if (!isAlertShow) {
            goBack();
            const { duplicateProject } = data || { duplicateProject: {} };
            navigateToProject(duplicateProject);
          }
        },
        onError: (_err) => {
          setloading(false);
          handleUpgrade();
        },
      });
      debouncedAlert();
    }
  };

  useEffect(() => {
    const resetList = list.map((i) => {
      const newList = i.list.map((l) => {
        return {
          ...l,
          isSelected: false,
        };
      });
      // reset selection
      return {
        ...i,
        isSelected: false,
        list: newList,
      };
    });

    setIsSelected(resetList);
  }, []);

  const onRadioButtonClick = (index: number, _index?: number) => {
    const newList = [...selectedList];
    let _isNoneSelected = true;
    newList.map((item, i: number) => {
      if (_index === -1 && index === i) {
        newList[index].isSelected = !newList[index].isSelected;
      }
      let isAllSelected = true;
      item.list?.map((_item: DataType, _i: number) => {
        if (_index === -1 && index === i) {
          _item.isSelected = newList[index].isSelected;
        } else if (index === i && _index === _i) {
          _item.isSelected = !_item.isSelected;
        }
        if (!_item.isSelected && isAllSelected) {
          isAllSelected = false;
        }
        if (_item.isSelected && _isNoneSelected) {
          _isNoneSelected = false;
        }
      });
      item.isSelected = isAllSelected;
    });
    setIsNoneSelected(_isNoneSelected);
    setIsSelected([...newList]);
  };

  const itemRenderItem = (item: DataType, index: number) => {
    return item.list?.map((_item: DataType, _index) => (
      <Box
        key={item.id + _item.id}
        paddingTop='m'
        paddingBottom='xs'
        borderBottomColor='grey02'
        style={[
          styles._item,
          index + 1 === selectedList.length &&
            _index + 1 === item.list?.length &&
            styles._borderBottom,
        ]}>
        <RadioButton
          onPress={() => onRadioButtonClick(index, _index)}
          isSelected={_item.isSelected}
          key={_item.id}
          iconVariant='m'
          text={_item.name}
          textVariant='labelEmphasized'
          textColor='textSecondary'
        />
      </Box>
    ));
  };

  const renderItem = ({ item, index }: ListRenderItemInfo<DataType>) => {
    return (
      <Box paddingBottom='m' paddingTop='xs'>
        <RadioButton
          onPress={() => onRadioButtonClick(index, -1)}
          isSelected={item.isSelected}
          key={item.id}
          iconVariant='m'
          text={item.name}
          textVariant='labelEmphasized'
        />
        {itemRenderItem(item, index)}
      </Box>
    );
  };

  const renderCopyProjectDetailsList = () => (
    <Box borderRadius='xs' flexDirection='column'>
      <FlatList
        data={selectedList}
        renderItem={renderItem}
        keyExtractor={(item) => item.id.toString()}
        style={styles.list}
      />
      {Platform.OS === 'web' ? (
        <Box padding='m' flexDirection='row' justifyContent='flex-end'>
          {disableCopyProject && showDisableModal && (
            <ConfirmModal
              showModal={disableCopyProject || false}
              onClose={() => setDisableCopyProject(false)}
              onPress={() => {
                const redirectUrl = `${process.env.STRIPE_REDIRECT_BASE_URL}/my-account/subscription-plans`;
                window.location.href = redirectUrl;
              }}
              buttonText='Upgrade'
              title={t('models:projects.snackBar.freeProjectLimit')}
              message={t('models:projects.snackBar.createMoreProjects')}
            />
          )}

          <Button
            accessibilityLabel='Confirm'
            variant='primary'
            onPress={createProject}
            disabled={isNoneSelected || loading}>
            {loading ? (
              <Box padding='m'>
                <ActivityIndicatorLoading />
              </Box>
            ) : (
              t('forms:confirm')
            )}
          </Button>
        </Box>
      ) : (
        <Box padding='m'>
          <Button
            variant='primary'
            onPress={createProject}
            disabled={isNoneSelected || loading}>
            {loading ? (
              <ActivityIndicatorLoading />
            ) : (
              t('models:projects.create.title')
            )}
          </Button>
        </Box>
      )}
    </Box>
  );

  return Platform.OS === 'web' ? (
    <Box marginTop='m'>
      {renderCopyProjectDetailsList()}
      <ConfirmModal
        showModal={showAlertModal}
        onClose={() => {
          setShowAlertModal(false);
          goBack();
        }}
        onPress={() => {
          setShowAlertModal(false);
          goBack();
        }}
        buttonText={t('models:projects.duplicate.button')}
        title={t('models:projects.duplicate.title')}
        message={t('models:projects.duplicate.message')}
      />
    </Box>
  ) : (
    <Box flex={1} justifyContent='center'>
      <TouchableOpacity
        style={{
          position: 'absolute',
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
        }}
        onPress={goBack}
        activeOpacity={1}
      />
      <ShadowBox variant='base' borderRadius='xs' marginHorizontal='m'>
        <Box
          padding='m'
          marginBottom='xs'
          flexDirection='row'
          justifyContent='space-between'
          borderBottomWidth={StyleSheet.hairlineWidth}
          borderBottomColor='lightPurple'>
          <Text variant='labelLarge'>Select</Text>
          <TouchableOpacity onPress={goBack}>
            <Text variant='buttonLabel' color='greenSecondary'>
              Cancel
            </Text>
          </TouchableOpacity>
        </Box>
        {renderCopyProjectDetailsList()}
      </ShadowBox>
    </Box>
  );
};

const styles = StyleSheet.create({
  list: {
    paddingHorizontal: theme.spacing.m,
  },
  _item: {
    marginLeft: 32,
    borderBottomWidth: 1,
  },
  _borderBottom: {
    borderBottomWidth: 0,
    borderBottomColor: theme.colors.white,
  },
});
