import { StackActions, useNavigation } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import { Elements } from '@stripe/react-stripe-js';
import { StripeElementsOptions, loadStripe } from '@stripe/stripe-js';
import { Formik } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Platform } from 'react-native';
import { InferType } from 'yup';

import { MemberRoles } from '@components/FormModals/MemberRoles';
import { MemberSelect } from '@components/FormModals/MemberSelect';
import { SkillSelect } from '@components/FormModals/SkillSelect';
import { TeamsModal } from '@components/FormModals/TeamsModal';
import {
  GetTeamDocument,
  LocalFile,
  TeamRole,
  User,
  useRemoveTeamMemberMutation,
} from '@graphql/generated';
import { useListTeamsFromQuery } from '@hooks/useListTeamsFromQuery';
import useMe from '@hooks/useMe';
import { useTeamFromQuery } from '@hooks/useTeamFromQuery';
import { createSubscriptionSchema } from '@schemas/createSubscriptionSchema';
import { CreateSubscription } from '@screens/Payments/CreateSubscription';
import { EditSubscription } from '@screens/Payments/EditSubscription';

const stripePromise = loadStripe(
  process.env.AASA_MODE === 'production'
    ? process.env.PROD_STRIPE_PUBLIC_KEY || 'not set'
    : process.env.STRIPE_PUBLIC_KEY ||
        'pk_test_51NuWiYKiMQhaWaB8lZReWb2agfurIZEqvDIqy8SXewKfgNc5l649X30gHZH3oylt0u4paLws8PWfv0ShI80Lvukw00eykmS5a8'
);

export type CreateSubscriptionStackParamsList = {
  home: undefined;
  'create-subscriptionRightPanel': undefined;
  'add-members': undefined;
  'edit-roles': undefined;
  'create-team': undefined;
  'choose-team': undefined;
  'skill-select': undefined;
};

const Stack = createStackNavigator<CreateSubscriptionStackParamsList>();

export type InferredFormValues = InferType<typeof createSubscriptionSchema>;
type UserWithRole = User & { role: TeamRole };
export type FormValues = Omit<
  InferredFormValues,
  'logo' | 'skillIds' | 'users'
> & { logo: LocalFile | null; skillIds: string[]; users: UserWithRole[] };
const CreateSubscriptionStack: React.FC<{
  externalNavigate?: any;
  params: any;
}> = ({ externalNavigate, params }) => {
  // TODO complete modal for confirmation
  // TODO add paywall prompts in all appropriate screens
  const { t } = useTranslation();
  const { me } = useMe();
  const { teamId, isPersonal, isPlanPlus, screenName, teamValues } = params;

  const { team } = useTeamFromQuery({
    teamId,
  });

  const [removeTeamMember] = useRemoveTeamMemberMutation();

  const checkAndRemoveTeamMember = (userId: string) => {
    if (team?.users.some((t) => t.id === userId)) {
      removeTeamMember({
        variables: {
          id: teamId,
          userId: userId,
        },
        refetchQueries: [{ query: GetTeamDocument, variables: { id: teamId } }],
      });
    }
  };

  const users = () => {
    if (!me) {
      return [];
    }
    const owner = {
      id: me.id,
      avatar: me.avatar,
      name: me.name,
      firstName: me.firstName,
      lastName: me.lastName,
      role: TeamRole.Owner,
    };
    return [owner];
  };

  const teamUsers = team
    ? team.users.map((u) => {
        const a = {
          ...u,
          role:
            (u.teamRoles?.length ?? 0) > 0 ? u.teamRoles![0] : TeamRole.Member,
        };
        return a;
      })
    : users();
  const initialValues: FormValues = {
    users:
      teamValues?.users && teamValues?.users.length > 0
        ? [
            ...teamUsers,
            ...teamValues.users.filter(
              (u: User) => !teamUsers.some((u1) => u1.id === u.id)
            ),
          ]
        : teamUsers,
    teamId: Platform.OS == 'web' ? teamId || null : null,
    plan: team ? team.plan?.id : null,
    planName: team ? team.plan?.name : null,
    billingCycle: team ? team.plan?.interval ?? 'year' : 'year',
    paymentComplete: false,
    priceId: null,
    price: 0,
    location: teamValues?.location ?? '',
    name: team ? team.name : teamValues?.name ?? '',
    skillIds: team ? team.skills.map((s) => s.id) : teamValues?.skillIds ?? [],
    logo: teamValues?.logo ?? null,
  };
  const { teams } = useListTeamsFromQuery({});
  const navigator = useNavigation();

  const filteredTeams = teams.filter((team) => team.personal);
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={createSubscriptionSchema}
      validateOnMount
      onSubmit={() => {}}>
      {({ values }) => {
        const elementOptions: StripeElementsOptions = {
          paymentMethodCreation: 'manual',
          mode: 'subscription',
          amount: values.price || 0,
          currency: 'usd',
          appearance: {
            /*...*/
          },
        };

        return (
          <Elements stripe={stripePromise} options={elementOptions}>
            <Stack.Navigator
              screenOptions={{ headerShown: true }}
              initialRouteName={
                screenName === 'create-subscriptionPanel'
                  ? 'create-subscriptionRightPanel'
                  : 'home'
              }>
              <Stack.Screen
                name='home'
                component={CreateSubscription}
                options={{
                  headerShown: false,
                }}
                initialParams={{ isPersonal, teamId, isPlanPlus }}
              />
              <Stack.Screen
                name='create-subscriptionRightPanel'
                children={() => {
                  return team?.plan && !team?.plan.isCanceled ? (
                    <EditSubscription />
                  ) : (
                    <CreateSubscription externalNavigate={externalNavigate} />
                  );
                }}
                options={{
                  headerShown: false,
                }}
                initialParams={{ isPersonal, teamId, isPlanPlus }}
              />
              <Stack.Group
                screenOptions={{
                  headerShown: false,
                  presentation: 'transparentModal',
                  cardShadowEnabled: Platform.OS === 'web',
                  cardStyleInterpolator: ({ current: { progress } }) => ({
                    containerStyle: {
                      opacity: progress.interpolate({
                        inputRange: [0, 1],
                        outputRange: [0, 1],
                        extrapolate: 'clamp',
                      }),
                    },
                    overlayStyle: {
                      opacity: progress.interpolate({
                        inputRange: [0, 1],
                        outputRange: [0, 0.6],
                        extrapolate: 'clamp',
                      }),
                    },
                  }),
                }}>
                <Stack.Screen
                  name='add-members'
                  options={{ title: 'Add Paid Members' }}>
                  {() => {
                    const formUsers = values.users;
                    const onlyMe =
                      formUsers.length == 1 && formUsers[0].id == users()[0].id;
                    return (
                      <MemberSelect
                        title='Add Paid Members'
                        defaultRole={TeamRole.Member}
                        ownerId={me?.id}
                        showAvatarInSelected={true}
                        buttonLabel={
                          onlyMe ? t('shared:done') : t('shared:next')
                        }
                        showTasktagSupport={false}
                        users={formUsers}
                        ignoreEmptyList={true}
                        doRemoveTeamMember={checkAndRemoveTeamMember}
                        onNext={() => {
                          if (onlyMe) {
                            navigator.dispatch(StackActions.pop());
                          } else {
                            navigator.dispatch(
                              StackActions.replace('edit-roles')
                            );
                          }
                        }}
                      />
                    );
                  }}
                </Stack.Screen>
                <Stack.Screen
                  name='edit-roles'
                  options={{ title: 'Edit Roles' }}>
                  {() => (
                    <MemberRoles
                      title='Edit Roles'
                      buttonLabel={t('shared:next')}
                    />
                  )}
                </Stack.Screen>
                <Stack.Screen name='choose-team' options={{ title: 'Teams' }}>
                  {() => (
                    // TODO need to pass something as a way to filter teams out that already have entitlements
                    <TeamsModal
                      title='Team Setup'
                      teams={filteredTeams}
                      buttonLabel={t('shared:next')}
                    />
                  )}
                </Stack.Screen>
                <Stack.Screen name='skill-select' options={{ title: 'Skills' }}>
                  {() => (
                    <SkillSelect
                      onClose={() => {
                        navigator.dispatch(StackActions.replace('choose-team'));
                      }}
                      onNext={() => {
                        navigator.dispatch(StackActions.replace('choose-team'));
                      }}
                    />
                  )}
                </Stack.Screen>
              </Stack.Group>
            </Stack.Navigator>
          </Elements>
        );
      }}
    </Formik>
  );
};

export default CreateSubscriptionStack;
