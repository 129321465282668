export const SUBSCRIPTION_STATUS = {
  UNSUBSCRIBED: 'unsubscribed',
  SUBSCRIBED: 'subscribed',
  LAPSED: 'lapsed',
} as const;

export const WEB_PLAN_NAME: string = 'team' as const;
export const MOBILE_PLAN_NAME: string = 'plus' as const;

export const PROJECTS_AMOUNT = {
  BASIC: '3',
  PLUS: '50',
  TEAM: 'unlimited',
} as const;
