import {
  createStackNavigator,
  StackScreenProps,
} from '@react-navigation/stack';
import { Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useToast } from 'react-native-toast-notifications';
import { InferType } from 'yup';

import { SimpleHeader } from '@components/Headers/SimpleHeader.web';
import { AddMemberType } from '@components/Invite/SelectMembersList';
import { User, useUpdateGroupMutation } from '@graphql/generated';
import useSelectedMembers from '@hooks/useSelectedMembers';
import addMembersSchema from '@schemas/addMembersSchema';
import { AddMembers } from '@screens/Members/AddMembers';

export type FormValues = InferType<typeof addMembersSchema> & {
  id: string;
  name: string;
  teamId: string;
  users: User[];
};

export type AddMembersStackParamsList = {
  'add-members': undefined;
};

export type AddMembersStackScreenProps<
  T extends keyof AddMembersStackParamsList
> = StackScreenProps<AddMembersStackParamsList, T>;

const Stack = createStackNavigator<AddMembersStackParamsList>();

interface AddContactGroupMembersStackProps {
  data?: any;
  isCreateContactGroup?: boolean;
  members?: AddMemberType[];
  teamId?: string;
  onClose?: () => void;
  initialRouteName?: keyof AddMembersStackParamsList;
}

export const AddContactGroupMembersStack: React.FC<
  AddContactGroupMembersStackProps
> = ({
  data,
  isCreateContactGroup,
  members,
  teamId,
  onClose,
  initialRouteName,
}) => {
  const [selected, setSelected] = useState<AddMemberType[]>();
  const { setSelectedMembers } = useSelectedMembers();

  const initialValues: FormValues = {
    id: data?.id,
    name: data?.name,
    teamId: data?.team?.id || '',
    users: members || [],
    selectedUserIds: [],
  };

  const toast = useToast();
  const [updateGroupMutation] = useUpdateGroupMutation({
    refetchQueries: ['listGroups'],
    onCompleted: () => {
      toast.show('Contact Group Updated Successfully', { duration: 3000 });
    },
  });

  useEffect(() => {
    setSelected(members);
  }, []);

  const submitForm = (values: FormValues) => {
    updateGroupMutation({
      variables: {
        id: values?.id,
        attributes: {
          name: values.name,
          accountId: values.teamId,
          userIds: selected?.map((user) => user.id),
        },
      },
    });
  };

  const setMembersWithRoles = () => {
    setSelectedMembers(selected || []);
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values) => submitForm(values)}
      validationSchema={addMembersSchema}
      validateOnMount>
      {({ setFieldValue, values }) => {
        const { selectedUserIds: selectedUserIdsRaw } = values;
        const selectedUserIds = selectedUserIdsRaw?.filter(
          (s) => !!s
        ) as string[];
        return (
          <Stack.Navigator
            initialRouteName={initialRouteName || undefined}
            screenOptions={{
              cardStyle: {
                backgroundColor: 'white',
              },
            }}>
            <Stack.Screen
              name='add-members'
              options={{
                header: () => {
                  return <SimpleHeader title='Add Members' onClose={onClose} />;
                },
              }}>
              {() => (
                <AddMembers
                  disabled={!selected?.length}
                  onNextPress={() => {
                    if (isCreateContactGroup) {
                      setMembersWithRoles();
                    } else {
                      setFieldValue('users', selected);
                      submitForm(values);
                    }
                    setSelected([]);
                    onClose?.();
                  }}
                  selectedUserIds={selectedUserIds}
                  updateUsers={(newUsers) => setSelected(newUsers)}
                  users={selected}
                  teamId={teamId}
                />
              )}
            </Stack.Screen>
          </Stack.Navigator>
        );
      }}
    </Formik>
  );
};
