import {
  createStackNavigator,
  StackScreenProps,
} from '@react-navigation/stack';
import React, { useEffect } from 'react';

import { SubscriptionPlanDetail } from '@components/Profile/SubscriptionPlanDetail.web';
import { Product, Team } from '@graphql/generated';
import { useAppNavigation } from '@navigation/useAppNavigation';
import { MyAccount } from '@screens/Profile/MyAccount.web';

export type ProfileStackParamsList = {
  'my-account-stack': undefined;
  subscriptionPlanDetail: {
    teamId: Team['id'];
  };
  'subscription-created': { teamId: Team['id']; productId: Product['id'] };
  'subscription-edit': { teamId: Team['id']; productId: Product['id'] };
  'subscription-plans': undefined;
};

export type ProfileStackScreenProps<T extends keyof ProfileStackParamsList> =
  StackScreenProps<ProfileStackParamsList, T>;

const Stack = createStackNavigator<ProfileStackParamsList>();

const ProfileStack: React.FC = () => {
  const navigation = useAppNavigation();
  useEffect(() => {
    const unsubscribe = navigation
      .getParent()
      ?.addListener('drawerItemPress', (e) => {
        navigation.reset({ routes: [{ name: 'tabs' }], index: 0 });
        e.preventDefault();
      });
    return unsubscribe;
  }, [navigation]);

  return (
    <Stack.Navigator>
      <Stack.Screen
        name='my-account-stack'
        component={MyAccount}
        options={{
          headerShown: false,
        }}
      />
      <Stack.Screen
        name='subscriptionPlanDetail'
        component={SubscriptionPlanDetail}
        options={{
          headerShown: false,
        }}
      />
      <Stack.Screen
        name='subscription-created'
        component={MyAccount}
        options={{
          headerShown: false,
        }}
      />
      <Stack.Screen
        name='subscription-edit'
        component={MyAccount}
        options={{
          headerShown: false,
        }}
      />
      <Stack.Screen
        name='subscription-plans'
        component={MyAccount}
        options={{
          headerShown: false,
        }}
      />
    </Stack.Navigator>
  );
};

export default ProfileStack;
