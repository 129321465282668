import { useNavigation } from '@react-navigation/native';
import dayjs from 'dayjs';
import { Field, useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ScrollView, TouchableOpacity, View } from 'react-native';
import { useToast } from 'react-native-toast-notifications';

import { Alert } from '@components/Alert';
import Box from '@components/Box/Box';
import { AddMembersSection } from '@components/Common/AddMembersSection.web';
import { CustomToast } from '@components/CustomToast/CustomToast';
import { CancelConfirmTaskAndProjectModal } from '@components/Modals/CancelConfirmTaskAndProjectModal';
import { WebModal } from '@components/Modals/WebModal';
import { FormValues } from '@components/Projects/ProjectFormStack';
import { Text } from '@components/Restyle/index';
import Button from '@components/shared/Button/Button';
import Icon from '@components/shared/Icon/Icon';
import ListNavigationItem from '@components/shared/ListNavigationItem/ListNavigationItem';
import TextField from '@components/shared/TextField/TextField';
import { SnackbarForProject } from '@components/Snackbar/SnackbarForProject';
import { useWebDrawer } from '@components/Web/Drawer/WebDrawerContext';
import {
  ProjectMemberRole,
  Team,
  useListProductsQuery,
} from '@graphql/generated';
import { useListTeamsFromQuery } from '@hooks/useListTeamsFromQuery';
import useMe from '@hooks/useMe';
import useNewColors, { ColorType } from '@hooks/useNewColors';
import useSelectedMembers from '@hooks/useSelectedMembers';
import useTabFocus from '@hooks/useTabFocus';
import { useTeamFromQuery } from '@hooks/useTeamFromQuery';
import { AddProjectMembersStack } from '@navigation/addMembers/add-project-members-stack';
import { PROJECTS_AMOUNT } from '@src/constants/constants';
import Images from '@themes/images';
import theme from '@themes/theme';
import { getProjectRoleEnumValue } from '@utils/projects';
import { AsyncStorage } from '@utils/storage';

type ProjectFormProps = Record<string, unknown>;

export type ProjectLimitNextTime = {
  userId: string;
  teamId: string;
  nextTime: string;
};

export const getShowProjectLimitSnackBarNextTime = async () => {
  const jsonValue = await AsyncStorage.getItem(
    'showProjectLimitSnackBarNextTime'
  );
  return jsonValue !== null
    ? (JSON.parse(jsonValue) as ProjectLimitNextTime[])
    : [];
};

export const removeProjectLimitSnackBarNextTime = async (
  userId: string | undefined,
  teamId: string | undefined
) => {
  if (!userId || teamId === undefined) return;
  const savedTimes = await getShowProjectLimitSnackBarNextTime();
  const saveTimes = savedTimes?.filter((item) => {
    if (
      item.userId === userId &&
      (item.teamId === teamId || item.teamId === '0')
    ) {
      return false;
    } else {
      return true;
    }
  });
  await AsyncStorage.setItem(
    'showProjectLimitSnackBarNextTime',
    JSON.stringify(saveTimes)
  );
};

export const saveShowProjectLimitSnackBarNextTime = async (
  userId: string | undefined,
  teamId: string | undefined
) => {
  if (!userId || teamId === undefined) return;
  const savedTimes = await getShowProjectLimitSnackBarNextTime();
  const saveValue: ProjectLimitNextTime = {
    userId: userId,
    teamId: teamId,
    nextTime: dayjs(Date()).add(14, 'day').toString(),
  };
  let savedValue = false;
  const saveTimes = savedTimes?.map((item) => {
    if (item.userId === userId && item.teamId === teamId) {
      savedValue = true;
      return saveValue;
    } else {
      return item;
    }
  });
  await AsyncStorage.setItem(
    'showProjectLimitSnackBarNextTime',
    JSON.stringify(savedValue ? saveTimes : [...saveTimes, saveValue])
  );
};

const ProjectForm: React.FC<ProjectFormProps> = ({ screenName }) => {
  const { me } = useMe();
  const navigation = useNavigation();
  const { t } = useTranslation();
  const navigator = useNavigation();
  const { teams } = useListTeamsFromQuery();
  const primaryTeam = teams[0];
  const { colors } = useNewColors();
  const [showProjectLimitSnackBar, setShowProjectLimitSnackBar] =
    useState(false);
  const [hideProjectLimitSnackBar, setHideProjectLimitSnackBar] =
    useState(false);
  const [overrideSaveAlert, setOverrideSaveAlert] = useState(false);
  const [isCancelModalVisible, setIsCancelModalVisible] = useState(false);
  const { tabFocus } = useTabFocus();
  const {
    setIsCreateProjectOpen,
    setEditProjectId,
    setIsTaskWebPanelOpen,
    setIdTaskDetailOpen,
    setIdProjectOfTaskDetailOpen,
    setIsTaskWebPanelMaximize,
  } = useWebDrawer();
  const [touchedTeam, setTouchedTeam] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [isCancelHovered, setIsCancelHovered] = useState(false);
  const [showAddMembersModal, setShowAddMembersModal] = useState(false);
  const { selectedMembers, setSelectedMembers } = useSelectedMembers();
  const {
    values,
    touched,
    submitForm,
    setFieldValue,
    setFieldTouched,
    isSubmitting,
  } = useFormikContext<FormValues>();
  const { data: listProductsData } = useListProductsQuery();
  const getProductPlus = listProductsData?.listProducts?.find((product) =>
    product?.name?.toUpperCase()?.includes('PLUS')
  );
  const getProductTeam = listProductsData?.listProducts?.find((product) =>
    product?.name?.toUpperCase()?.includes('TEAM')
  );

  const toast = useToast();
  const {
    teamId = '',
    users,
    color,
    icon,
    id: projectId,
    location,
    currentTeam,
  } = values;

  const sortedUsers = [...users].sort((u) =>
    u.role !== ProjectMemberRole.Owner ? 1 : -1
  );

  const selectedTeam = teams.find((item) => item.id === teamId);
  const { name: selectedTeamName } = selectedTeam || {};

  const selectedColor = colors.find((item: ColorType) => item.id === color);

  const updateMember = (userId: string, role: string) => {
    const members = users?.map((u) => {
      if (u.id !== userId) return u;
      else {
        return { ...u, role: getProjectRoleEnumValue(role) };
      }
    });
    setFieldTouched('users');
    setFieldValue('users', [...members]);
  };
  const { team, loading } = useTeamFromQuery({
    teamId: selectedTeam?.id,
  });

  const saveAlert = () => {
    Alert.alert(
      t('models:saveProject.title'),
      t('models:saveProject.message'),
      [
        {
          text: t('shared:cancel'),
        },
        {
          text: t('shared:leavePage'),

          onPress: () => {
            setOverrideSaveAlert(true);

            // debounce, else alert will show twice
            setTimeout(() => {
              navigation.goBack();
            }, 10);
          },
        },
      ]
    );
  };
  const onPressCancel = () => {
    if (!teamId) {
      setFieldValue('teamId', primaryTeam?.id);
    }
    setIsCancelModalVisible(false);
    setIsCreateProjectOpen(false);
    setEditProjectId('');
    if (projectId && tabFocus === 'Projects') {
      navigation.navigate('projects-stack', {
        screen: 'details',
        params: { projectId },
      });
    } else {
      navigation.navigate('allprojects');
    }
  };

  useEffect(
    () =>
      navigation.addListener('beforeRemove', (e) => {
        if (overrideSaveAlert) {
          return;
        }
        if (projectId && Object.entries(touched).length > 0) {
          e.preventDefault();
          saveAlert();
        } else {
          return;
        }
      }),
    [navigation, touched, overrideSaveAlert]
  );

  useEffect(() => {
    Object.entries(touched).length;
  }, [touched]);

  useEffect(() => {
    if (!values.teamId && primaryTeam?.id) {
      setFieldValue('teamId', primaryTeam.id);
    }
  }, [primaryTeam, values.teamId, setFieldValue]);

  const planPlus = Number(PROJECTS_AMOUNT?.PLUS);
  const planBasic = Number(PROJECTS_AMOUNT?.BASIC);

  const teamHasPlan = team?.plan?.id;
  const teamPersonal = team?.personal;
  const ruleTeamPersonalWithPlan =
    team?.personal && teamHasPlan && team?.numberOfProjectsUsed >= planPlus;
  const ruleTeamPersonalWithoutPlan =
    team?.personal && !teamHasPlan && team?.numberOfProjectsUsed >= planBasic;

  const ruleByTeamWithoutPlan = !team?.personal && !teamHasPlan;

  const displayedLockCreateTeam =
    Boolean(ruleTeamPersonalWithPlan || ruleTeamPersonalWithoutPlan) ||
    ruleByTeamWithoutPlan;

  const isActiveOrTrialingTeam =
    selectedTeam?.plan?.name === 'Team' &&
    (selectedTeam?.plan?.status === 'trialing' ||
      selectedTeam?.plan?.status === 'active');

  const projectLeft = selectedTeam?.maxNumberOfProjects
    ? (selectedTeam?.maxNumberOfProjects ?? 0) -
      (selectedTeam?.numberOfProjectsUsed ?? 0)
    : Number(team?.plan?.id ? PROJECTS_AMOUNT?.PLUS : PROJECTS_AMOUNT?.BASIC) -
      (team as Team)?.numberOfProjectsUsed;

  const shouldProjectCreateDisabled =
    selectedTeam?.maxNumberOfProjects === 0
      ? false
      : (selectedTeam?.maxNumberOfProjects ?? 0) ===
        (selectedTeam?.numberOfProjectsUsed ?? 0);

  const createButton = () => {
    const disabled =
      (!projectId && shouldProjectCreateDisabled) ||
      isSubmitting ||
      Object.entries(touched).length < 1 ||
      !(selectedTeamName || currentTeam?.name) ||
      loading ||
      values.name === undefined ||
      values.name === '' ||
      !team?.id;
    const bgColor = isHovered ? 'grey05' : 'black';

    return (
      <Button
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        isSmall
        disabled={disabled}
        backgroundColor={disabled ? undefined : bgColor}
        borderColor={disabled ? undefined : bgColor}
        variant='webprimary'
        marginTop='l'
        accessibilityLabel={
          projectId
            ? t('models:projects.update.title')
            : t('models:projects.create.title')
        }
        onPress={() => {
          if (!projectId && displayedLockCreateTeam) {
            toast.show(
              <CustomToast
                onPress={() => {
                  window.sessionStorage.setItem('goto_welcome', '-1');
                  navigation.navigate('subscription-plans');
                  setIsCreateProjectOpen(false);
                  setEditProjectId('');
                  setIsTaskWebPanelOpen(false);
                  setIsTaskWebPanelMaximize(false);
                  setIdTaskDetailOpen('');
                  setIdProjectOfTaskDetailOpen('');
                }}
                redirectTo='my-account'
                params={{
                  screen: 'create-subscription',
                  params: {
                    teamId: team?.id,
                    isPersonal: teamPersonal,
                    isPlanPlus: !!teamPersonal,
                    planId: teamPersonal
                      ? getProductPlus?.id
                      : getProductTeam?.id,
                  },
                }}
                isTryFree
              />,
              {
                type: 'custom',
                placement: 'bottom',
                duration: 10000,
                animationType: 'zoom-in',
              }
            );
            setIsCancelModalVisible(false);
            setIsCreateProjectOpen(false);
            setEditProjectId('');
            return;
          }
          if (!projectId && teamPersonal) {
            toast.show(
              <CustomToast
                onPress={() => {
                  window.sessionStorage.setItem('goto_welcome', '-1');
                  navigation.navigate('subscription-plans');
                  setIsCreateProjectOpen(false);
                  setEditProjectId('');
                  setIsTaskWebPanelOpen(false);
                  setIsTaskWebPanelMaximize(false);
                  setIdTaskDetailOpen('');
                  setIdProjectOfTaskDetailOpen('');
                }}
                redirectTo='my-account'
                params={{
                  screen: 'create-subscription',
                  params: {
                    teamId: team?.id,
                    isPersonal: team?.personal,
                    isPlanPlus: team?.personal,
                    planId: getProductPlus?.id,
                  },
                }}
                isTryFree>
                <Box>
                  <Text style={{ color: '#fff' }}>
                    {projectLeft - 1 < 0 ? 0 : projectLeft - 1}{' '}
                    {t('models:customToast.projectLeft')}
                  </Text>
                  <Text style={{ color: '#fff' }}>
                    {t('models:customToast.upgradeToCreate')}
                  </Text>
                </Box>
              </CustomToast>,
              {
                type: 'custom',
                placement: 'bottom',
                duration: 10000,
                animationType: 'zoom-in',
              }
            );
          }
          setOverrideSaveAlert(true);
          submitForm();
          removeProjectLimitSnackBarNextTime(me?.id, '0');
          removeProjectLimitSnackBarNextTime(me?.id, selectedTeam?.id);
        }}>
        {projectId
          ? t('models:projects.update.title')
          : t('models:projects.create.title')}
      </Button>
    );
  };

  const teamIcon =
    selectedTeamName || currentTeam?.name ? (
      <Icon name='Logo' variant='l' color='white' disabled={false} />
    ) : undefined;

  useEffect(() => {
    selectedMembers && setFieldValue('users', selectedMembers);
  }, [selectedMembers]);

  useEffect(() => {
    setSelectedMembers(projectId ? users : []);
    return () => setSelectedMembers([]);
  }, []);

  useEffect(() => {
    if (
      !projectId &&
      !isActiveOrTrialingTeam &&
      selectedTeam?.maxNumberOfProjects
    ) {
      if (projectLeft < 1 && selectedTeam?.maxNumberOfProjects > 0) {
        setShowProjectLimitSnackBar(true);
      } else if (
        projectLeft === 1 ||
        (projectLeft === 2 && selectedTeam?.maxNumberOfProjects > 0)
      ) {
        needShowProjectLimitSnackBar();
      } else {
        setShowProjectLimitSnackBar(false);
      }
    }
  }, [selectedTeam]);

  const needShowProjectLimitSnackBar = async () => {
    const savedTimes = await getShowProjectLimitSnackBarNextTime();
    const savedTime = savedTimes?.find(
      (item) => item.userId === me?.id && item.teamId === selectedTeam?.id
    );
    if (savedTime) {
      if (dayjs(Date()).diff(new Date(savedTime.nextTime)) < 0) {
        return;
      }
    }
    setShowProjectLimitSnackBar(true);
  };

  const goToUpgradeTeam = () => {
    setIsTaskWebPanelOpen(false);
    setIsTaskWebPanelMaximize(false);
    setIdTaskDetailOpen('');
    setIdProjectOfTaskDetailOpen('');
    setIsCreateProjectOpen(false);
    setEditProjectId('');
    const redirectUrl = `${process.env.STRIPE_REDIRECT_BASE_URL}/my-account/subscription-plans`;
    window.location.href = redirectUrl;
  };

  return (
    <>
      {!isActiveOrTrialingTeam &&
        showProjectLimitSnackBar &&
        !hideProjectLimitSnackBar && (
          <Box pb='l' backgroundColor='white'>
            <SnackbarForProject
              projectLeft={projectLeft}
              title={t('models:projects.snackBar.projectsRemainingWithCount', {
                count: projectLeft < 0 ? 0 : projectLeft,
              })}
              description={t(
                'models:projects.snackBar.upgradeToCreateMoreProjects'
              )}
              iconName='Trophy'
              onPress={() => {
                goToUpgradeTeam();
              }}
              onClose={() => {
                setShowProjectLimitSnackBar(false);
                setHideProjectLimitSnackBar(true);
                saveShowProjectLimitSnackBarNextTime(me?.id, selectedTeam?.id);
              }}
            />
          </Box>
        )}
      <Box flex={1} backgroundColor='white' maxWidth={600}>
        <Box flex={1}>
          <ScrollView>
            {!projectId && (
              <Box
                borderBottomColor='grey02'
                borderBottomWidth={1}
                marginBottom='l'>
                <ListNavigationItem
                  title='Copy from existing project'
                  iconName='Copy'
                  // project-copy-search sits in project-form-stack for web. In mobile, it is in project-stack
                  onPress={() => navigation.navigate('project-copy-search')}
                  hideChevron={true}
                />
              </Box>
            )}
            <Field
              component={TextField}
              label='Name'
              name='name'
              accessible={true}
              isRequired
              placeholder={t('models:projects.placeholders.nameForWeb')}
              textInputProps={{ maxLength: 25 }}
              showCharsRemaining
              accessibilityLabel={t('models:projects.placeholders.nameForWeb')}
            />
            <Field
              component={TextField}
              label='Description'
              name='description'
              accessible={true}
              textInputProps={{ maxLength: 500 }}
              placeholder={t('models:projects.placeholders.description')}
              accessibilityLabel={t('models:projects.placeholders.description')}
            />

            <Text color='textPrimary' variant='labelSmall'>
              {t('shared:address')}
            </Text>
            <TouchableOpacity
              onPress={() => navigation.navigate('project-location-search')}>
              <Box
                accessibilityLabel='Address'
                flexDirection='row'
                borderColor='grey02'
                borderWidth={1}
                height={48}
                alignItems='center'
                borderRadius='xs'
                paddingHorizontal='m'
                mt='xs'
                mb='m'>
                <Icon name='MapPin' variant='m' color='grey04' />
                {location && (
                  <Text
                    variant='bodySecondary'
                    color='black'
                    numberOfLines={1}
                    mr='m'
                    ml='xs'>
                    {location.address}
                  </Text>
                )}
              </Box>
            </TouchableOpacity>

            <Box flexDirection='row' mb='m'>
              <Box flex={1}>
                <Box
                  flexDirection='row'
                  alignItems='flex-end'
                  justifyContent='center'>
                  <Text marginBottom='xs' variant='bodyTaskName'>
                    {t('models:projects.create.teams.team')}
                  </Text>
                  <Box flex={1}></Box>
                  <Text marginBottom='xs' variant='labelRequired'>
                    {t('models:projects.create.required')}
                  </Text>
                </Box>

                <Button
                  variant='edit'
                  fullWidth
                  isSmall
                  onPress={() => {
                    navigator.navigate('team-select');
                    setTimeout(() => {
                      setTouchedTeam(true);
                    }, 500);
                  }}
                  prefix={
                    <Box
                      width={24}
                      height={24}
                      alignItems='center'
                      justifyContent='center'
                      backgroundColor={
                        selectedTeam?.personal ? 'greenSecondary' : undefined
                      }
                      borderRadius='xxs'
                      marginRight='m'>
                      {selectedTeam?.personal ? (
                        <Icon
                          name='Office'
                          variant='s'
                          color='white'
                          disabled={false}
                        />
                      ) : (
                        teamIcon
                      )}
                    </Box>
                  }
                  accessibilityLabel='Assign Team'>
                  <Text
                    variant='bodyTaskName'
                    color={
                      selectedTeamName || currentTeam?.name
                        ? 'textPrimary'
                        : 'grey04'
                    }>
                    {selectedTeamName || currentTeam?.name || 'Assign Team'}
                  </Text>
                </Button>

                {touchedTeam && !(selectedTeamName || currentTeam?.name) && (
                  <Box flexDirection='row' alignItems='center' marginBottom='s'>
                    <Icon
                      name='AlertTriangle'
                      color='alertRed'
                      variant='s'
                      marginRight='xxs'
                    />
                    <Text variant='error'>
                      {t('models:projects.create.teams.teamIsRequired')}
                    </Text>
                  </Box>
                )}
              </Box>
              <Box marginLeft='m'>
                <Text marginBottom='xs' variant='bodyTaskName'>
                  {t('models:projects.color')}
                </Text>
                <TouchableOpacity
                  accessibilityLabel='Color'
                  style={{
                    width: 72,
                    height: 48,
                    paddingHorizontal: theme.spacing.l,
                    paddingVertical: theme.spacing.s,
                    borderRadius: theme.spacing.xs,
                    borderWidth: 1,
                    borderColor: theme.colors.grey03,
                    backgroundColor: theme.colors.grey01,
                  }}
                  onPress={() => navigator.navigate('color-select')}>
                  {selectedColor ? (
                    <View
                      style={{
                        backgroundColor: selectedColor.value,
                        borderRadius: 24,
                        width: 24,
                        height: 24,
                        marginRight: theme.spacing.xxs,
                      }}></View>
                  ) : undefined}
                </TouchableOpacity>
              </Box>
              <Box marginLeft='m'>
                <Text marginBottom='xs' variant='bodyTaskName'>
                  {t('models:projects.icon')}
                </Text>
                <TouchableOpacity
                  accessibilityLabel='Icon'
                  style={{
                    width: 72,
                    height: 48,
                    paddingHorizontal: theme.spacing.l,
                    paddingVertical: theme.spacing.s,
                    borderRadius: theme.spacing.xs,
                    borderWidth: 1,
                    borderColor: theme.colors.grey03,
                    backgroundColor: theme.colors.grey01,
                  }}
                  onPress={() => navigator.navigate('icon-select')}>
                  {icon ? (
                    <Icon
                      name={icon as keyof typeof Images}
                      variant='l'
                      color='textPrimary'
                    />
                  ) : undefined}
                </TouchableOpacity>
              </Box>
            </Box>

            <AddMembersSection
              isCreateProject={!projectId}
              isEditProject={!!projectId}
              members={sortedUsers}
              updateMember={(member, role) => updateMember(member.id, role)}
              removeMember={(member) => {
                const members = users?.filter((u) => u.id !== member.id);
                setFieldTouched('users');
                setFieldValue('users', [...members]);
              }}
              addMembers={() => {
                setFieldTouched('users', true);
                setShowAddMembersModal(true);
              }}
            />
          </ScrollView>
        </Box>
        <Box flexDirection='row'>
          <Box flex={1}>
            <Button
              onMouseEnter={() => setIsCancelHovered(true)}
              onMouseLeave={() => setIsCancelHovered(false)}
              isSmall
              marginTop='l'
              marginRight='xs'
              borderWidth={1}
              backgroundColor={isCancelHovered ? 'grey02' : 'white'}
              borderColor={isCancelHovered ? 'grey02' : 'black'}
              borderRadius='xs'
              variant='textweb'
              onPress={() => {
                setIsCancelModalVisible(true);
              }}
              float='bottom'
              accessibilityLabel={t('shared:cancel')}>
              {t('shared:cancel')}
            </Button>
          </Box>
          <Box flex={1}>{createButton()}</Box>
        </Box>

        <CancelConfirmTaskAndProjectModal
          showModal={isCancelModalVisible}
          onClose={() => {
            setIsCancelModalVisible(false);
          }}
          onPress={onPressCancel}
          buttonTextOne={
            screenName == 'Create'
              ? t('models:tasks.cancel.btnCreateText')
              : t('models:tasks.cancel.btnEditText')
          }
          buttonText={
            screenName == 'Create'
              ? t('models:tasks.cancel.removeProjectBtnText')
              : t('models:tasks.cancel.btnCancelText')
          }
          title={
            screenName == 'Create'
              ? t('models:tasks.cancel.projectCancelTitle')
              : t('models:tasks.cancel.cancelEditingTitle')
          }
          message={
            screenName == 'Create'
              ? t('models:tasks.cancel.projectCreateMessage')
              : t('models:tasks.cancel.projectEditMessage')
          }
        />
      </Box>
      <WebModal
        onClose={() => setShowAddMembersModal(false)}
        visible={showAddMembersModal}
        width={504}
        height={512}>
        <AddProjectMembersStack
          isCreateProject
          teamId={values.teamId}
          members={sortedUsers}
          onComplete={() => setShowAddMembersModal(false)}
          onClose={() => setShowAddMembersModal(false)}
        />
      </WebModal>
    </>
  );
};

export default ProjectForm;
