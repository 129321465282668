import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';
import dayjs from 'dayjs';
import { Formik } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  FlatList,
  Modal,
  StyleSheet,
  TouchableOpacity,
  TouchableWithoutFeedback,
} from 'react-native';
import Toast from 'react-native-toast-notifications';

import Avatar from '@components/Avatar/Avatar';
import CustomTabBar from '@components/CustomTabBar/CustomTabBar';
import { ModalHeader } from '@components/Headers/ModalHeader';
import {
  AddMemberType,
  SelectMembersList,
} from '@components/Invite/SelectMembersList';
import { ConfirmModal } from '@components/Modals/ConfirmModal';
import { WebModal } from '@components/Modals/WebModal';
import { Box, ShadowBox, Text } from '@components/Restyle';
import ActivityIndicatorLoading from '@components/shared/ActivityIndicatorLoading';
import Button from '@components/shared/Button/Button';
import Icon from '@components/shared/Icon/Icon';
import ListNavigationItem from '@components/shared/ListNavigationItem/ListNavigationItem';
import { useWebDrawer } from '@components/Web/Drawer/WebDrawerContext';
import { PopupTeamMemberRoleButtonweb } from '@components/Web/PopupTeamMemberRoleButton.web';
import {
  GetTeamDocument,
  Invoice,
  ListTeamsDocument,
  Maybe,
  MemberAttributes,
  Payment,
  Scalars,
  TeamRole,
  User,
  useRemoveTeamMemberMutation,
  useUpdateTeamMembersMutation,
} from '@graphql/generated';
import { useContacts } from '@hooks/useContacts';
import useMe from '@hooks/useMe';
import { useTeamFromQuery } from '@hooks/useTeamFromQuery';
import { useWebDrawerNavigator } from '@hooks/useWebDrawerNavigator';
import { ProfileStackScreenProps } from '@navigation/profile/profile-stack.web';
import { useAppNavigation } from '@navigation/useAppNavigation';
import { projectFormSchema } from '@schemas/projectFormSchema';
import theme from '@themes/theme';
import {
  defaultTeamRole,
  getTeamRoleEnumValue,
  selectableTeamRoles,
} from '@utils/teams';

export const SubscriptionPlanDetail = ({
  route,
}: ProfileStackScreenProps<'subscriptionPlanDetail'>) => {
  const TASKTAG_SUPPORT_EMAIL = 'emmanueln@lovettcommercial.com';
  const {
    params: { teamId },
  } = route;

  const navigation = useAppNavigation();

  const { me } = useMe();

  const { t } = useTranslation();
  const { setSelectedTeam } = useWebDrawerNavigator();

  const Tab = createMaterialTopTabNavigator();

  const [isHovered, setIsHovered] = useState(false);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [isDeleteModalVisible, setDeleteModalVisible] =
    useState<boolean>(false);
  const [selectedMember, setSelectedMember] = useState<User | undefined>(
    undefined
  );

  const {
    setIsTaskWebPanelOpen,
    setIdTaskDetailOpen,
    setIdProjectOfTaskDetailOpen,
    setIsTaskWebPanelMaximize,
    setIsCreateProjectOpen,
    setEditProjectId,
    setUpgradeTeamPlanOpen,
    setIdTeamOpen,
    setIsTeamPersonal,
    setTeamValues,
    teamValues,
  } = useWebDrawer();

  const offsetPopButton: Partial<any> = {
    position: 'left',
    offset: [0, 6],
  };
  const teamRoles: { key: TeamRole | 'all'; str: string }[] = [
    {
      key: 'all',
      str: t('models:profile.subscriptionPlans.allRoles'),
    },
    { key: TeamRole.Admin, str: t('models:profile.subscriptionPlans.admin') },
    {
      key: TeamRole.Member,
      str: t('models:profile.subscriptionPlans.member'),
    },
  ];
  const toastRef = useRef();
  const [updateTeamMembers] = useUpdateTeamMembersMutation({
    onCompleted: () => {
      toastRef?.current?.show(
        t('models:profile.subscriptionPlans.theUserHasBeenAddedToYourTeamPlan'),
        {
          duration: 2000,
        }
      );
    },
    refetchQueries: [{ query: GetTeamDocument, variables: { id: teamId } }],
  });

  const goToUpgradeSubscription = () => {
    setIsTaskWebPanelOpen(false);
    setIsTaskWebPanelMaximize(false);
    setIdTaskDetailOpen('');
    setIdProjectOfTaskDetailOpen('');
    setIsCreateProjectOpen(false);
    setEditProjectId('');
    setUpgradeTeamPlanOpen(true);
    setIdTeamOpen(team?.id ?? '');
    setIsTeamPersonal(team?.personal ?? false);
  };

  const updateTeamMember = async (userId: User['id'], role: TeamRole) => {
    await updateTeamMembers({
      variables: {
        teamId,
        attributes: {
          userId: userId,
          role: getTeamRoleEnumValue(role),
        } as MemberAttributes,
      },
      onCompleted: () => {},
      refetchQueries: [{ query: GetTeamDocument, variables: { id: teamId } }],
    });
  };

  const teamRolePriority = (m: User) => {
    if (m.id === team?.owner.id) return 1;
    if ((m.teamRoles || []).includes(TeamRole.Admin)) return 0;
    return -1;
  };

  const { team } = useTeamFromQuery({
    teamId,
    fetchPolicy: 'network-only',
  });
  const initialValues = {
    users: [],
    selectedUserIds: (team?.users || []).map((u) => u.id),
  };

  const members = [...(team?.users || [])].sort(
    (m1, m2) => teamRolePriority(m2) - teamRolePriority(m1)
  );
  const isOwner = team?.owner.id === me.id;

  const isAdmin = members.some(
    (m) => m.id == me?.id && m.teamRoles?.includes(TeamRole.Admin)
  );

  const isCanceled = team?.plan?.isCanceled ?? false;

  const canRemoveMember =
    team?.plan &&
    !(team?.personal ?? false) &&
    !isCanceled &&
    (isOwner || isAdmin);

  const roleDisplayStr = (role: TeamRole) => {
    switch (role) {
      case TeamRole.Owner:
        return 'Owner';
      case TeamRole.Admin:
        return 'Admin';
      default:
        return 'Member';
    }
  };

  const sortInvoices = (sortUP: boolean) => {
    const number = sortUP ? 1 : -1;
    return [...(team?.invoices || [])].sort(
      (m1, m2) => (dayjs(m2.date).isAfter(dayjs(m1.date)) ? -1 : 1) * number
    );
  };
  const [invoicesSortByDateUp, setInvoicesSortByDateUp] =
    useState<boolean>(false);
  const [displayInvoices, setDisplayInvoices] = useState<Invoice[]>(
    sortInvoices(invoicesSortByDateUp)
  );

  const [hoverInvoiceId, setHoverInvoiceId] = useState<string | undefined>();
  const [displayMembers, setDisplayMembers] = useState(members);

  const [hoverMemberId, setHoverMemberId] = useState<string | undefined>();

  const [selectedRole, setSelectedRole] = useState<string | undefined>(
    teamRoles[0].key
  );
  const [showAddMembersModal, setShowAddMembersModal] = useState(false);

  const [removeTeamMember] = useRemoveTeamMemberMutation();

  useEffect(() => {
    const unsubscribe = navigation
      .getParent()
      ?.addListener('drawerItemPress', (e) => {
        setSelectedTeam(undefined);
        navigation.reset({ routes: [{ name: 'my-profile' }], index: 0 });
        e.preventDefault();
      });

    return unsubscribe;
  }, [navigation]);

  const resetMembers = (role: TeamRole | 'all') => {
    setDisplayMembers(
      (role === 'all'
        ? members
        : members.filter((u) =>
            (u.teamRoles || []).includes(role as TeamRole)
          )) || []
    );
  };

  useEffect(() => {
    resetMembers(selectedRole);
  }, [team?.users]);

  useEffect(() => {
    setDisplayInvoices(sortInvoices(invoicesSortByDateUp));
  }, [team?.invoices?.length]);

  const renderHeader = () => {
    const titleText = (title: string) => (
      <Text variant='metadataSecondary' color='grey04'>
        {title.toUpperCase()}
      </Text>
    );
    return (
      <Box
        flexDirection='row'
        height={40}
        backgroundColor='grey01'
        borderRadius='xs'>
        <Box
          flex={1}
          flexDirection='row'
          style={{ paddingHorizontal: 20 }}
          justifyContent='flex-start'
          alignItems='center'>
          {titleText('Date')}
          <Icon
            name='Sort2'
            color='grey02'
            variant='l'
            marginLeft='xxs'
            marginTop='s'
            onPress={() => {
              setDisplayInvoices(sortInvoices(!invoicesSortByDateUp));
              setInvoicesSortByDateUp(!invoicesSortByDateUp);
            }}
          />
        </Box>
        <Box flex={1} px='m' justifyContent='center'>
          {titleText('Total')}
        </Box>
        <Box flex={1.5} px='m' justifyContent='center'>
          {titleText('Description')}
        </Box>
        <Box flex={1} px='m' justifyContent='center'>
          {titleText('status')}
        </Box>
        <Box width={67}></Box>
      </Box>
    );
  };

  const renderItem = (item: {
    __typename: 'Invoice';
    amount: Scalars['Int'];
    date: Scalars['ISO8601DateTime'];
    description: Scalars['String'];
    id: Scalars['ID'];
    invoiceUrl: Scalars['String'];
    payment?: Maybe<Payment>;
    status: Scalars['String'];
  }) => {
    return (
      <Box
        flexDirection='row'
        height={38}
        style={{
          borderBottomColor: theme.colors.grey02,
          borderBottomWidth: 1,
        }}>
        <Box flex={1} px='m' justifyContent='center'>
          <Text variant='webBodySecondary' color='textPrimary'>
            {dayjs(item.date).format('MMMM DD, YYYY')}
          </Text>
        </Box>
        <Box flex={1} px='m' justifyContent='center'>
          <Text variant='webBodySecondary' color='textPrimary'>
            {`$${(item.amount / 100).toFixed(2)}`}
          </Text>
        </Box>
        <Box flex={1.5} px='m' justifyContent='center'>
          <Text variant='webBodySecondary' color='textPrimary'>
            {item.description}
          </Text>
        </Box>
        <Box flex={1} px='m' justifyContent='center'>
          <Text variant='webBodySecondary' color='textPrimary'>
            {item.status}
          </Text>
        </Box>
        <Box
          px='m'
          justifyContent='center'
          onMouseEnter={() => {
            setHoverInvoiceId(item.id);
          }}
          onMouseLeave={() => {
            setHoverInvoiceId(undefined);
          }}>
          <TouchableOpacity
            onPress={() => {
              item.invoiceUrl && window.open(item.invoiceUrl, '_blank');
            }}>
            <Text
              variant='webBodySecondary'
              color='greenSecondary'
              textDecorationLine={
                hoverInvoiceId === item.id ? 'underline' : undefined
              }>
              {t('shared:view')}
            </Text>
          </TouchableOpacity>
        </Box>
      </Box>
    );
  };

  const renderMembersHeader = () => {
    const titleText = (title: string) => (
      <Text variant='metadataSecondary' color='grey04'>
        {title.toUpperCase()}
      </Text>
    );
    return (
      <Box
        flexDirection='row'
        height={40}
        backgroundColor='grey01'
        borderRadius='xs'>
        <Box
          flex={1.5}
          flexDirection='row'
          style={{ paddingHorizontal: 20 }}
          justifyContent='flex-start'
          alignItems='center'>
          {titleText('Name')}
          {/*<Icon*/}
          {/*  name='Sort2'*/}
          {/*  color='grey02'*/}
          {/*  variant='l'*/}
          {/*  marginLeft='xxs'*/}
          {/*  marginTop='s'*/}
          {/*/>*/}
        </Box>
        {/*<Box flex={1} px='m' justifyContent='center'>*/}
        {/*  {titleText('Last Active')}*/}
        {/*</Box>*/}
        <Box flex={1} px='m' justifyContent='center'>
          {titleText('Role')}
        </Box>
        <Box flex={1.5} px='m' justifyContent='center'>
          {titleText('Email')}
        </Box>
        <Box flex={1} px='m' justifyContent='center'>
          {titleText('Phone')}
        </Box>
        {canRemoveMember && <Box width={100}></Box>}
      </Box>
    );
  };

  const planUpgrade = {
    title: 'Upgrade',
    description: 'Enjoy more features!',
    iconName: 'Zap' as const,
    iconColor: 'greenSecondary',
    iconBackgroundColor: 'greenSecondaryMild',
    onPress: goToUpgradeSubscription,
  };
  const formatPhoneNumber = (xphoneNumber: string) => {
    const phoneNumber = xphoneNumber.replace(/\D/g, '');
    if (phoneNumber.startsWith('1')) {
      return phoneNumber.length == 11
        ? phoneNumber.substring(1, 4) +
            '-' +
            phoneNumber.substring(4, 7) +
            '-' +
            phoneNumber.substring(7)
        : phoneNumber.substring(0, 3) +
            '-' +
            phoneNumber.substring(3, 6) +
            '-' +
            phoneNumber.substring(6);
    } else {
      return phoneNumber;
    }
  };

  const removeMember = (item: User) => {
    removeTeamMember({
      variables: {
        id: teamId,
        userId: item.id,
      },
      onCompleted: () => {
        setDeleteModalVisible(false);
      },
      refetchQueries: [
        { query: GetTeamDocument, variables: { id: teamId } },
        { query: ListTeamsDocument },
      ],
    });
  };

  const renderMemberItem = (item: User, index: number) => {
    return (
      <Box
        flexDirection='row'
        height={48}
        style={{
          borderBottomColor: theme.colors.grey02,
          borderBottomWidth: 1,
        }}>
        <Box
          flex={1.5}
          px='m'
          justifyContent='flex-start'
          alignItems='center'
          flexDirection='row'>
          <Avatar
            id={item.id}
            avatar={item.avatar}
            size='small'
            label={item.name}
          />
          <Text variant='webBodySecondary' color='textPrimary' ml='xs'>
            {item.name}
          </Text>
        </Box>
        {/*<Box flex={1} px='m' justifyContent='center'>*/}
        {/*  <Text variant='webBodySecondary' color='textPrimary'>*/}
        {/*    // here need read from backend*/}
        {/*    Recently*/}
        {/*  </Text>*/}
        {/*</Box>*/}
        <Box
          flex={1}
          px='m'
          justifyContent='flex-start'
          alignItems='center'
          flexDirection='row'>
          <TouchableOpacity
            key={index}
            onPress={() => {
              if (
                (isOwner || isAdmin) &&
                item.id !== me?.id &&
                !item.teamRoles?.includes(TeamRole.Owner)
              ) {
                setSelectedMember(item);
                setModalOpen(true);
              }
            }}
            accessibilityLabel={item.name ?? ''}>
            <Box
              flex={1}
              justifyContent='flex-start'
              alignItems='center'
              flexDirection='row'>
              <Text variant='webBodySecondary' color='textPrimary'>
                {(item.teamRoles || []).length > 0 &&
                  roleDisplayStr(item.teamRoles![0])}
              </Text>
              {(isOwner || isAdmin) &&
                item.id !== me?.id &&
                !item.teamRoles?.includes(TeamRole.Owner) && (
                  <Icon
                    name='ChevronDown'
                    color='textPrimary'
                    variant='s'
                    marginLeft='xs'
                  />
                )}
            </Box>
          </TouchableOpacity>
        </Box>
        <Box flex={1.5} px='m' justifyContent='center'>
          <Text variant='webBodySecondary' color='textPrimary'>
            {item.email}
          </Text>
        </Box>
        <Box flex={1} px='m' justifyContent='center'>
          <Text variant='webBodySecondary' color='textPrimary'>
            {formatPhoneNumber(item.phoneNumber)}
          </Text>
        </Box>
        {!canRemoveMember && <Box width={100} />}
        {canRemoveMember && (
          <Box
            width={100}
            px='m'
            justifyContent='center'
            onMouseEnter={() => {
              setHoverMemberId(item.id);
            }}
            onMouseLeave={() => {
              setHoverMemberId(undefined);
            }}>
            {item.id !== me?.id && (
              <TouchableOpacity
                onPress={() => {
                  setSelectedMember(item);
                  setDeleteModalVisible(true);
                }}>
                <Text
                  variant='webBodySecondary'
                  color='alertRed'
                  textDecorationLine={
                    hoverMemberId === item.id ? 'underline' : undefined
                  }>
                  {t('shared:remove')}
                </Text>
              </TouchableOpacity>
            )}
          </Box>
        )}
      </Box>
    );
  };

  const maskEmail = (email: string) => {
    if (!email.includes('@')) return '';
    const [localPart, domain] = email.split('@');

    if (domain.length < 10) {
      return `${localPart[0]}***@${domain}`;
    } else {
      return `${localPart[0]}***@${domain.substring(
        0,
        3
      )} ... ${domain.substring(domain.length - 5)}`;
    }
  };

  const leftDays = -dayjs().diff(team?.plan?.expiration, 'day');
  let planName = '-';
  let planStatus = '-';
  let planStatusColor = 'textSecondary';
  const expirationDate = new Date(team?.plan?.expiration);
  const currentDate = new Date();
  if (isCanceled) {
    planStatus = 'Canceled';
  } else if (expirationDate < currentDate) {
    planStatus = 'Expired';
  } else if (leftDays) {
    planStatus = 'Active';
  }
  if (isCanceled || expirationDate < currentDate) {
    planStatusColor = 'alertRed';
  } else if (leftDays) {
    planStatusColor = 'greenSecondary';
  }
  if (team?.plan?.name) {
    planName = team?.plan?.name;
  } else if (team?.personal && team?.plan?.name == null) {
    planName = 'Free Plan';
  }

  return (
    <Box width='100%' height='100%'>
      <Box
        width={606}
        borderRadius='xs'
        borderWidth={1}
        borderColor='grey02'
        style={{ marginBottom: 40 }}
        ml='l'
        mt='m'
        px='m'
        py='s'>
        <Box py='xs' flexDirection='row' alignItems='center'>
          <Text variant='labelSmall' color='textPrimary'>
            {t('models:profile.subscriptionPlans.plan')}
          </Text>
          <Box flex={1}></Box>
          <Box flexDirection='row' alignItems='center'>
            <Text variant='webBodySecondary' color='textSecondary' ml='xs'>
              {planName}
            </Text>
          </Box>
        </Box>
        <Box py='xs' flexDirection='row' alignItems='center'>
          <Text variant='labelSmall' color='textPrimary'>
            {t('models:projects.create.teams.team')}
          </Text>
          <Box flex={1}></Box>
          <Box flexDirection='row' alignItems='center'>
            <Text variant='webBodySecondary' color='textSecondary' ml='xs'>
              {team?.personal ? 'Personal Projects' : team?.name ?? ''}
            </Text>
          </Box>
        </Box>

        {/* {leftDays > 0 && (
          <Box py='xs' flexDirection='row' justifyContent='flex-start'>
            <Text variant='labelSmall' color='textPrimary'>
              Trial Period
            </Text>
            <Box flex={1}></Box>

            {leftDays > 0 && team?.plan && (
              <Text variant='webBodySecondary' color='textSecondary'>
                {leftDays.toString() +
                  (leftDays > 1 ? ' days left' : ' day left')}
              </Text>
            )}
          </Box>
        )} */}

        <Box flexDirection='row' alignItems='center' height={32}>
          <Text variant='labelSmall' color='textPrimary'>
            {t('models:profile.subscriptionPlans.nextInvoice')}
          </Text>
          <Box flex={1}></Box>
          <Text variant='webBodySecondary' color='textSecondary'>
            {planStatus === 'Active'
              ? t('format:dateSlash', { val: team?.plan?.expiration })
              : '-'}
          </Text>
        </Box>

        <Box py='xs' flexDirection='row' justifyContent='flex-start'>
          <Text variant='labelSmall' color='textPrimary'>
            {t('models:profile.subscriptionPlans.planStatus')}
          </Text>
          <Box flex={1}></Box>
          <Text variant='webBodySecondary' color={planStatusColor}>
            {planStatus}
          </Text>
        </Box>
        <Box py='xs' flexDirection='row' justifyContent='flex-start'>
          <Text variant='labelSmall' color='textPrimary'>
            {t('models:teams.subscriptions.totalTeamMembers')}
          </Text>
          <Box flex={1}></Box>
          <Box flexDirection='row' alignItems='center'>
            <Text variant='webBodySecondary' color='textSecondary'>
              {team?.usersCount ?? 0}
            </Text>
          </Box>
        </Box>
        <Box py='xs' flexDirection='row' justifyContent='flex-start'>
          <Text variant='labelSmall' color='textPrimary'>
            {t('models:teams.subscriptions.totalAmount')}
          </Text>
          <Box flex={1}></Box>
          <Box flexDirection='row' alignItems='center'>
            <Text variant='webBodySecondary' color='textSecondary'>
              {team?.plan?.amount
                ? `$${(
                    (team?.plan?.amount / 100) *
                    (team?.plan?.quantity || 1)
                  ).toFixed(2)}`
                : '-'}
            </Text>
          </Box>
        </Box>
        <Box
          py='xs'
          flexDirection='row'
          justifyContent='flex-start'
          height={32}>
          <Text variant='labelSmall' color='textPrimary'>
            {t('models:profile.subscriptionPlans.paymentMethod')}
          </Text>
          <Box flex={1}></Box>
          {/*about link payment brand is email address.*/}
          {team?.plan && team?.plan?.payment?.brand.includes('@') && (
            <Box flexDirection='row' alignItems='center'>
              <Icon name='LinkPayment' variant='m' color='white' />
              <Text
                ml='xs'
                variant='webBodySecondary'
                color='textSecondary'
                numberOfLines={1}>
                {maskEmail(team?.plan?.payment?.brand)}
              </Text>
            </Box>
          )}
          {team?.plan && team?.plan?.payment?.last4 ? (
            <Box flexDirection='row' alignItems='center'>
              {team?.plan?.payment?.brand.toLowerCase() === 'visa' && (
                <Icon name='Visa' variant='xl' color='white' />
              )}
              {team?.plan?.payment?.brand.toLowerCase() === 'mastercard' && (
                <Icon name='MasterCard' variant='xl' color='white' />
              )}
              {team?.plan?.payment?.brand.toLowerCase() === 'amex' && (
                <Icon name='AmericanExpress' variant='xl' color='white' />
              )}
              {team?.plan?.payment?.brand.toLowerCase() === 'discover' && (
                <Icon name='Discover' variant='xl' color='white' />
              )}
              <Text variant='webBodySecondary' color='textSecondary' ml='xxs'>
                {'**** **** **** ' + team?.plan?.payment?.last4}
              </Text>
            </Box>
          ) : (
            <Text variant='webBodySecondary' color='textSecondary' ml='xxs'>
              -
            </Text>
          )}
        </Box>

        {(isCanceled || expirationDate < currentDate) && !team?.personal && (
          <Box paddingVertical='s'>
            <ListNavigationItem {...planUpgrade} />
          </Box>
        )}
      </Box>

      <Tab.Navigator
        screenOptions={{
          swipeEnabled: false,
        }}
        sceneContainerStyle={{
          backgroundColor: 'transparent',
        }}
        initialRouteName='TeamInvoice'
        backBehavior='none'
        tabBar={(props) => (
          <Box
            flexDirection='row'
            alignItems='center'
            paddingHorizontal='l'
            paddingBottom='xs'
            maxHeight={50}>
            <CustomTabBar {...props} spacing={theme.spacing.l} />
          </Box>
        )}>
        <Tab.Screen
          name='TeamInvoice'
          options={{
            tabBarLabel: `${t(
              'models:teams.subscriptions.invoices.invoice'
            )} (${team?.invoices?.length ?? 0})`,
          }}
          children={() => (
            <Box mt='xs'>
              <FlatList
                showsHorizontalScrollIndicator={false}
                showsVerticalScrollIndicator={false}
                data={displayInvoices}
                renderItem={({ item }) => <Box mx='l'>{renderItem(item)}</Box>}
                ListHeaderComponent={() => <Box mx='l'>{renderHeader()}</Box>}
              />
            </Box>
          )}
        />
        <Tab.Screen
          name='TeamMembers'
          options={{
            tabBarLabel: `${t('models:teams.subscriptions.members')} (${
              team?.usersCount ?? 0
            })`,
          }}
          children={() => (
            <Box flex={1} mt='xs'>
              <Box flexDirection='row' mb='xs' mx='l'>
                <PopupTeamMemberRoleButtonweb
                  initialRoles={teamRoles}
                  boxProps={offsetPopButton}
                  onPressRole={(role) => {
                    setSelectedRole(role.key);
                    resetMembers(role.key);
                  }}
                />
                <Box flex={1}></Box>
                {canRemoveMember && (
                  <Button
                    accessibilityLabel={t(
                      'models:profile.subscriptionPlans.inviteUser'
                    )}
                    onMouseEnter={() => setIsHovered(true)}
                    onMouseLeave={() => setIsHovered(false)}
                    isSmall
                    height={32}
                    backgroundColor={isHovered ? 'grey05' : 'black'}
                    prefix={<Icon variant='s' name='UserPlus' color='white' />}
                    onPress={() => {
                      setShowAddMembersModal(true);
                    }}
                    borderRadius='xs'
                    borderColor={isHovered ? 'grey05' : 'black'}
                    variant='create'
                    prefixMarginRight='xs'
                    paddingHorizontal='m'
                    paddingVertical='xs'>
                    {t('models:profile.subscriptionPlans.inviteUser')}
                  </Button>
                )}
              </Box>
              <FlatList
                showsHorizontalScrollIndicator={false}
                showsVerticalScrollIndicator={false}
                data={displayMembers}
                renderItem={({ item, index }) => (
                  <Box mx='l'>{renderMemberItem(item, index)}</Box>
                )}
                ListHeaderComponent={() => (
                  <Box mx='l'>{renderMembersHeader()}</Box>
                )}
              />
            </Box>
          )}
        />
      </Tab.Navigator>

      {isDeleteModalVisible && (
        <ConfirmModal
          showModal={isDeleteModalVisible}
          onClose={() => setDeleteModalVisible(false)}
          onPress={() =>
            selectedMember && removeMember && removeMember(selectedMember)
          }
          buttonText={t('models:teams.removeTeamMember.remove')}
          title={t('models:teams.removeTeamMember.title')}
          message={t('models:teams.removeTeamMember.message', {
            member: selectedMember
              ? selectedMember.firstName + ' ' + selectedMember.lastName
              : '',
          })}
        />
      )}
      <Modal animationType='fade' transparent={true} visible={modalOpen}>
        <Box style={styles.modalContainer}>
          <TouchableWithoutFeedback
            onPress={() => setModalOpen(!modalOpen)}
            style={styles.modal}
            accessibilityLabel='CloseModalButton'>
            <Box style={styles.modal} />
          </TouchableWithoutFeedback>

          {selectableTeamRoles && (
            <ShadowBox variant='thin' style={styles.popUpWeb}>
              <Box
                marginVertical='m'
                marginHorizontal='m'
                flex={1}
                justifyContent='space-between'>
                <Box
                  style={styles.modalHeader}
                  accessibilityLabel='RoleSelectModal'>
                  <ModalHeader
                    title={t('models:projects.roles.selectRole')}
                    submitLabel='Cancel'
                    onCancel={() => setModalOpen(false)}
                    showCancel={false}
                    onSubmit={() => setModalOpen(false)}
                  />
                </Box>
                {selectableTeamRoles.map((i, index) => {
                  return (
                    <TouchableOpacity
                      key={index}
                      onPress={() => {
                        selectedMember &&
                          updateTeamMember &&
                          updateTeamMember(selectedMember?.id, i);
                        setModalOpen(false);
                      }}
                      accessibilityLabel={i}>
                      <Box
                        flexDirection='row'
                        justifyContent='space-between'
                        alignItems='center'
                        marginBottom={
                          index !== selectableTeamRoles.length - 1
                            ? 'l'
                            : undefined
                        }>
                        <Text
                          variant='body'
                          style={{ textTransform: 'capitalize' }}>
                          {i.toLowerCase()}
                        </Text>
                        {selectedMember?.teamRoles?.includes(
                          i.toLocaleUpperCase() as TeamRole
                        ) && (
                          <Icon
                            variant='s'
                            name='Check'
                            color='greenSecondary'
                            accessibilityLabel={`SelectedRole-${i}`}
                          />
                        )}
                      </Box>
                    </TouchableOpacity>
                  );
                })}

                {canRemoveMember && (
                  <>
                    <Box
                      backgroundColor='grey02'
                      height={1}
                      marginVertical='m'
                    />
                    <TouchableOpacity
                      onPress={() => {
                        setModalOpen(false);
                        setDeleteModalVisible(true);
                      }}
                      accessibilityLabel='Remove'>
                      <Text variant='body' color='alertRed'>
                        Remove
                      </Text>
                    </TouchableOpacity>
                  </>
                )}
              </Box>
            </ShadowBox>
          )}
        </Box>
      </Modal>

      <WebModal
        onClose={() => setShowAddMembersModal(false)}
        visible={showAddMembersModal}
        width={504}
        height={665}
        accessibilityLabel='add-members'>
        <Formik
          initialValues={initialValues}
          onSubmit={() => {}}
          validationSchema={projectFormSchema}>
          {({ setFieldValue, values, isSubmitting }) => {
            const { selectedUserIds: selectedUserIdsRaw, users } = values;
            const selectedUserIds = selectedUserIdsRaw?.filter(
              (s) => !!s
            ) as string[];

            const { contacts } = useContacts();

            const ButtonAdd = (
              xWidth: number,
              accessibilityLabel: string,
              backgroundColor: keyof typeof theme.colors,
              txtColor: keyof typeof theme.colors,
              isLoading: boolean,
              onPress: () => void
            ) => {
              return (
                <TouchableOpacity
                  onPress={onPress}
                  accessibilityLabel={accessibilityLabel}>
                  <Box
                    style={{ width: xWidth, height: 48 }}
                    alignItems='center'
                    borderWidth={1}
                    borderColor={txtColor}
                    borderRadius='xs'
                    justifyContent='center'
                    backgroundColor={backgroundColor}
                    mr='xs'>
                    {!isLoading && (
                      <Text variant='labelSmall' color={txtColor}>
                        {accessibilityLabel}
                      </Text>
                    )}
                    {isLoading && <ActivityIndicatorLoading />}
                  </Box>
                </TouchableOpacity>
              );
            };
            return (
              <>
                <Box
                  flexDirection='row'
                  justifyContent='space-between'
                  marginHorizontal='m'
                  mt='l'
                  mb='xs'>
                  <Text variant='labelEmphasized'>
                    {t('models:profile.subscriptionPlans.inviteUser')}
                  </Text>
                  <Icon
                    name='X'
                    variant='m'
                    color='black'
                    onPress={() => {
                      setShowAddMembersModal(false);
                    }}
                  />
                </Box>
                <Box
                  flex={1}
                  accessibilityLabel='Add Members'
                  backgroundColor='white'>
                  <SelectMembersList
                    isCreateTask={false}
                    isCreateProject={false}
                    teamId={teamId}
                    projectId=''
                    contacts={contacts.filter(
                      (c) => c.email !== TASKTAG_SUPPORT_EMAIL
                    )}
                    showTaskTagUsers
                    selectedUserIds={selectedUserIds}
                    disabledUsersId={selectedUserIds}
                    users={users}
                    updateUsers={(newUsers: AddMemberType[]) => {
                      setFieldValue(
                        'users',
                        newUsers.map((u) => {
                          return {
                            ...u,
                            role: u.role || defaultTeamRole,
                          };
                        })
                      );
                    }}
                  />
                  <Box style={styles.bottomButtonWeb} flexDirection='row'>
                    {ButtonAdd(
                      77,
                      t('shared:back'),
                      'white',
                      'black',
                      false,
                      () => {
                        setShowAddMembersModal(false);
                      }
                    )}
                    {ButtonAdd(
                      107,
                      t('models:invite.title'),
                      users.length > 0 ? 'black' : 'grey02',
                      'white',
                      isSubmitting,
                      async () => {
                        if (users.length > 0) {
                          goToUpgradeSubscription();
                          setTeamValues({ teamValues, users: users });
                          setShowAddMembersModal(false);
                        }
                      }
                    )}
                  </Box>
                </Box>
              </>
            );
          }}
        </Formik>
      </WebModal>
      <Toast ref={toastRef} />
    </Box>
  );
};

const styles = StyleSheet.create({
  bottomButtonWeb: {
    position: 'absolute',
    bottom: theme.spacing.m,
    right: theme.spacing.xs,
  },
  modal: {
    ...StyleSheet.absoluteFillObject,
    backgroundColor: 'black',
    opacity: 0.5,
  },
  modalContainer: {
    ...StyleSheet.absoluteFillObject,
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalHeader: {
    paddingBottom: theme.spacing.xl,
    paddingTop: theme.spacing.l,
  },
  popUp: {
    position: 'absolute',
    backgroundColor: 'white',
    width: '100%',
    bottom: 0,
    paddingBottom: theme.spacing.xxl,
    borderTopLeftRadius: theme.borderRadii.l,
    borderTopRightRadius: theme.borderRadii.l,
  },
  popUpWeb: {
    position: 'absolute',
    backgroundColor: 'white',
    width: 300,
    paddingBottom: theme.spacing.l,
    borderRadius: theme.borderRadii.s,
  },
});
