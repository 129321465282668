import React from 'react';
import { useWindowDimensions } from 'react-native';

import { Box, Text } from '../Restyle';
import Icon from '../shared/Icon/Icon';

interface EmptySearchNoTaskProps {
  isFromChatTags?: boolean;
}

const EmptySearchNoTask: React.FC<EmptySearchNoTaskProps> = ({
  isFromChatTags = false,
}) => {
  const { height, width } = useWindowDimensions();
  const EMPTY_ICON_WIDTH = width > 375 ? width / 3 : width / 1.8;
  const EMPTY_ICON_HEIGHT = height > 750 ? height / 8 : height / 5;

  return (
    <Box
      flex={1}
      alignItems='center'
      justifyContent='center'
      alignContent='center'>
      <Box
        flex={1}
        justifyContent='center'
        alignItems='center'
        alignContent='center'
        flexDirection='column'
        alignSelf='center'
        paddingBottom='xxl'>
        <Icon
          name='NoSearchTask'
          width={isFromChatTags ? 141 : EMPTY_ICON_WIDTH}
          height={EMPTY_ICON_HEIGHT}></Icon>
        <Text
          variant='heading1'
          textAlign='center'
          paddingBottom='xs'
          color='onSurfaceSecondary'>
          No Results
        </Text>
        {!isFromChatTags && (
          <Text variant='body' textAlign='center' color='onSurfaceSecondary'>
            Tap below to create this task
          </Text>
        )}
      </Box>
    </Box>
  );
};

export default EmptySearchNoTask;
