import { useState } from 'react';

import {
  useListProjectsTasksQuery,
} from '@graphql/generated';
import { ProjectTagType } from '@src/types/project';
/**
 * Retrieves a list of project tags
 */

interface ListProjectsTasksQueryProps {
  archivesOnly?: boolean;
  onCompleted?: () => void;
  onError?: () => void;
  first?: number;
  last?: number;
  after?: string;
  before?: string;
  filterTeamsProjects?: boolean;
  chatId?: string;
  excludeCompleted?: boolean;
  collaboratorsOnly?: boolean;
}

export const useListProjectsTasksFromQuery = (
  props?: ListProjectsTasksQueryProps
) => {
  const {
    onCompleted = undefined,
    onError = undefined,
    first = undefined,
    last = undefined,
    after = undefined,
    before = undefined,
    excludeCompleted = false,
    collaboratorsOnly = false,
    chatId = undefined,
  } = props || {};

  const [refreshing, setRefreshing] = useState(false);

  const { data, loading, error, refetch, fetchMore } =
    useListProjectsTasksQuery({
      variables: {
        first,
        last,
        before,
        after,
        excludeCompleted,
        collaboratorsOnly,
        chatId,
      },
      fetchPolicy: 'cache-and-network',
      onCompleted: () => {
        setRefreshing(false);
        onCompleted && onCompleted();
      },
      onError: () => {
        setRefreshing(false);
        onError && onError();
      },
    });

  const { listProjectsTasks } = data || { listProjectsTasks: undefined };

  const fetchMoreFromCursor = () => {
    if (!projects || !pageInfo?.hasNextPage || loading) return;
    const cursor = pageInfo?.endCursor;
    fetchMore({
      variables: {
        after: cursor,
        first,
        chatId,
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        const newEdges = fetchMoreResult.listProjectsTasks.edges;
        const pageInfo = fetchMoreResult.listProjectsTasks.pageInfo;
        return newEdges?.length
          ? {
              listProjectsTasks: {
                __typename: previousResult.listProjectsTasks.__typename,
                edges: [...previousResult.listProjectsTasks.edges, ...newEdges],
                pageInfo,
              },
            }
          : previousResult;
      },
    });
  };

  const { edges = [], pageInfo } = listProjectsTasks || { edges: [] };
  // TODO: This needs to be more properly typed to the returned graphql value.
  const projects =
    (edges || []).reduce((acc, curr) => {
      if (curr && curr.node && curr.node !== undefined) {
        return [...acc, curr.node as ProjectTagType];
      } else {
        return acc;
      }
    }, [] as ProjectTagType[]) || [];

  return {
    projectsTasks: projects,
    loading,
    error,
    pageInfo,
    refetch,
    fetchMore,
    fetchMoreFromCursor,
    refreshing,
    setRefreshing,
  };
};
