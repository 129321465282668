import React from 'react';
import {
  ActivityIndicator,
  ColorValue,
  Dimensions,
  Platform,
  StyleSheet,
  useWindowDimensions,
} from 'react-native';

import { ShadowBox } from '@components/Restyle';
import theme from '@themes/theme';

interface ActivityIndicatorLoadingProps {
  isChatMessageList?: boolean;
  color?: ColorValue;
  marginTop?: number;
  top?: number;
  imageWidth?: number;
  testID?: string;
}

const ActivityIndicatorLoading: React.FC<ActivityIndicatorLoadingProps> = ({
  isChatMessageList = false,
  color,
  marginTop,
  top,
  imageWidth,
  testID,
}) => {
  const { width = 360 } =
    Platform.select({
      native: useWindowDimensions(),
      web: {
        width: Dimensions.get('window').width - 256,
        fontScale: 1,
        height: 1,
        scale: 1,
      },
    }) ?? {};

  const loadingMarginLeft = (imageWidth ? imageWidth - 40 : width - 50) / 2;

  const getStyle = () => {
    if (Platform.OS === 'android') {
      if (isChatMessageList) {
        return { bottom: theme.spacing.m };
      }
    }
    if (top) {
      return { top: top };
    } else {
      return { top: theme.spacing.m };
    }
  };

  if (Platform.OS === 'ios' || Platform.OS === 'web') {
    return (
      <ActivityIndicator
        color={color ?? theme.colors.textPrimary}
        size={25}
        testID={testID}
        style={[
          {
            position: 'absolute',
            left: 0,
            right: 0,
            top: marginTop ?? 7.5,
          },
        ]}
      />
    );
  }

  return (
    <ShadowBox
      variant='none'
      style={[
        {
          marginLeft: loadingMarginLeft,
        },
        styles.loadingShadow,
        getStyle(),
      ]}>
      <ActivityIndicator
        color={Platform.OS === 'android' ? theme.colors.textPrimary : 'gray'}
        size={25}
        testID={testID}
        style={[
          {
            position: 'absolute',
            left: 0,
            right: 0,
          },
          Platform.OS === 'android' ? { bottom: 7.5 } : { top: 7.5 },
          isChatMessageList && Platform.OS === 'android'
            ? styles.activityIndicator
            : {},
        ]}
      />
    </ShadowBox>
  );
};

const styles = StyleSheet.create({
  loadingShadow: {
    position: 'absolute',
    left: 0,
    right: 0,
    width: 40,
    height: 40,
    borderRadius: 20,
    elevation: 5,
    shadowColor:
      Platform.OS === 'android' ? theme.colors.textPrimary : 'transparent',
  },
  activityIndicator: {
    transform: [{ rotate: '180deg' }],
  },
});

export default ActivityIndicatorLoading;
