import React from 'react';
import { useTranslation } from 'react-i18next';
import { Platform, useWindowDimensions } from 'react-native';

import { Box, Text } from '../Restyle';
import Icon from '../shared/Icon/Icon';

export interface EmptyStateNoSearchProps {
  showSmall?: boolean;
}
const EmptyStateNoSearch: React.FC<EmptyStateNoSearchProps> = ({
  showSmall = false,
}) => {
  const { height, width } = useWindowDimensions();
  const { t } = useTranslation('models');
  const EMPTY_ICON_WIDTH = width > 375 ? width / 2 : width / 1.8;
  const EMPTY_ICON_HEIGHT = height > 750 ? height / 6.2 : height / 5;
  return Platform.OS === 'web' ? (
    <>
      <Box
        flex={1}
        padding={showSmall ? 'none' : 'm'}
        justifyContent='center'
        testID='empty-state-no-search'
        marginTop='listFooter'
        marginHorizontal={showSmall ? 'none' : 'listFooter'}>
        <Box
          flex={1}
          justifyContent='center'
          alignItems='center'
          flexDirection='column'
          marginVertical='m'>
          <Icon name='NoSearchResults' width={200} height={151} />
          <Text
            variant={showSmall ? 'heading2' : 'heading1'}
            textAlign='center'
            paddingBottom='xs'
            color='onSurfaceSecondary'>
            {t('chat.emptyState.noResults')}
          </Text>
          <Text variant='body' textAlign='center' color='onSurfaceSecondary'>
            {t('chat.emptyState.noSearchMessage')}
          </Text>
        </Box>
      </Box>
    </>
  ) : (
    <Box flex={1} padding='xs' justifyContent='center' marginTop='xxl'>
      <Box alignItems='center' marginTop='l'>
        <Icon
          name='NoSearchResults'
          width={EMPTY_ICON_WIDTH}
          height={EMPTY_ICON_HEIGHT}></Icon>
      </Box>

      <Box flexDirection='column'>
        <Text
          variant='heading1'
          textAlign='center'
          paddingBottom='xs'
          color='onSurfaceSecondary'>
          {t('chat.emptyState.noResults')}
        </Text>
        <Text
          variant='body'
          textAlign='center'
          color='onSurfaceSecondary'
          marginHorizontal='m'>
          {t('chat.emptyState.noSearchMessage')}
        </Text>
      </Box>
    </Box>
  );
};

export default EmptyStateNoSearch;
