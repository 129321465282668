import { NavigationHelpers, ParamListBase } from '@react-navigation/native';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TouchableOpacity } from 'react-native';

import { WebModal } from '@components/Modals/WebModal.web';
import { Box, Text } from '@components/Restyle';
import { NavDrawerEventMap } from '@components/Web/Navigator/WebDrawerNavigator';
import {
  GetTeamDocument,
  ListTeamsDocument,
  Team,
  useCancelStripeSubscriptionMutation,
} from '@graphql/generated';
interface CancelSubscriptionModalProps {
  visible: boolean;
  onClose: () => void;
  selectedTeam?: Team; // Adjust the type as necessary
  fromSubscriptionPlanList?: boolean;
  setFromSubscriptionPlanList: (value: boolean) => void;
  navigation: NavigationHelpers<ParamListBase, NavDrawerEventMap>; // Adjust the type as necessary
  toastRef: any; // Adjust the type as necessary
}

const CancelSubscriptionModal: React.FC<CancelSubscriptionModalProps> = ({
  visible,
  onClose,
  selectedTeam,
  fromSubscriptionPlanList,
  setFromSubscriptionPlanList,
  navigation,
  toastRef,
}) => {
  const { t } = useTranslation();
  const [isCancelingStripSubscription, setIsCancelingStripSubscription] =
    useState(false);

  const [cancelStripeSubscription] = useCancelStripeSubscriptionMutation();

  const handleCancelSubscription = async () => {
    if (!isCancelingStripSubscription && selectedTeam) {
      setIsCancelingStripSubscription(true);
      await cancelStripeSubscription({
        variables: { id: selectedTeam.id },
        refetchQueries: [
          { query: ListTeamsDocument },
          { query: GetTeamDocument, variables: { teamId: selectedTeam.id } },
        ],
        onCompleted: () => {
          onClose();
          if (fromSubscriptionPlanList) {
            setFromSubscriptionPlanList(false);
            navigation.navigate('subscription-plans');
          } else {
            navigation.navigate('my-account-stack');
          }
          toastRef?.current?.show(
            t(
              'models:profile.subscriptionPlans.yourSubscriptionHasBeenTerminated'
            ),
            { duration: 5000 }
          );
          setIsCancelingStripSubscription(false);
        },
        onError: () => {
          setIsCancelingStripSubscription(false);
        },
      });
    }
  };

  return (
    <WebModal
      accessibilityLabel='confirmation-modal'
      marginTop='m'
      visible={visible}
      width={483}
      height={184}
      title={t('models:profile.subscriptionPlans.weAreSadToSeeYouGo')}
      titleVariant='labelLarge'
      titleColor='textPrimary'
      marginHorizontal='l'
      onClose={onClose}>
      <Box flex={1} mx='l'>
        <Box alignItems='flex-start' mt='l'>
          <Text variant='webBodySecondary' color='textPrimary'>
            {t('models:profile.subscriptionPlans.beforeYouLeave')}
          </Text>
        </Box>

        <Box
          mt='l'
          flexDirection='row'
          alignItems='flex-end'
          justifyContent='flex-end'>
          <TouchableOpacity
            onPress={() => {
              onClose();
              const url = 'mailto:info@tasktag.com';
              window.open(url, '_blank');
            }}>
            <Box
              borderRadius='xs'
              borderWidth={1}
              borderColor='black'
              width={117}
              height={48}
              mr='xs'
              justifyContent='center'
              alignContent='center'
              alignItems='center'
              accessibilityLabel={t(
                'models:profile.subscriptionPlans.getInTouch'
              )}>
              <Text variant='labelSmall' color='textPrimary'>
                {t('models:profile.subscriptionPlans.getInTouch')}
              </Text>
            </Box>
          </TouchableOpacity>

          <TouchableOpacity onPress={handleCancelSubscription}>
            <Box
              backgroundColor={
                isCancelingStripSubscription ? 'grey02' : 'black'
              }
              borderRadius='xs'
              width={125}
              height={48}
              justifyContent='center'
              alignContent='center'
              alignItems='center'
              accessibilityLabel={t(
                'models:profile.subscriptionPlans.cancelPlan'
              )}>
              <Text variant='labelSmall' color='white'>
                {t('models:profile.subscriptionPlans.cancelPlan')}
              </Text>
            </Box>
          </TouchableOpacity>
        </Box>
      </Box>
    </WebModal>
  );
};

export default CancelSubscriptionModal;
