import { useApolloClient } from '@apollo/client';
import { useFocusEffect } from '@react-navigation/native';
import * as Sentry from '@sentry/react-native';
import dayjs from 'dayjs';
import { useState, useCallback, useMemo } from 'react';

import {
  Chat,
  ListChatsPaginationDocument,
  ListChatsPaginationQuery,
} from '@graphql/generated';
import useChatInput from '@hooks/useChatInput';

export type ChatsProps = {
  listChats: Chat[];
  loading: boolean;
  refresh: () => void;
  refreshing: boolean;
  loadMore: () => void;
  hasNextPage: boolean;
};

type ChatsDataProps = {
  children: (props: ChatsProps) => JSX.Element;
};

export const chatSort = (a: Chat | undefined, b: Chat | undefined) => {
  const { recentActivityAt: recentActivityAtA } = a || {};
  const { recentActivityAt: recentActivityAtB } = b || {};
  const recentActivityAtA1 = dayjs(recentActivityAtA);
  const recentActivityAtB1 = dayjs(recentActivityAtB);

  if (recentActivityAtA1 < recentActivityAtB1) return 1;
  if (recentActivityAtB1 < recentActivityAtA1) return -1;

  return 0;
};

const ChatData = ({ children }: ChatsDataProps) => {
  const [refreshing, setRefreshing] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [listChats, setListChats] = useState<Chat[]>([]);
  const [pageInfo, setPageInfo] = useState<{
    hasNextPage: boolean;
    endCursor: string | null;
  }>({
    hasNextPage: true,
    endCursor: null,
  });
  const client = useApolloClient();
  const { subscribeToChatUpdates } = useChatInput();

  const fetchChats = useCallback(
    async (afterCursor: string | null = null) => {
      try {
        if (afterCursor) {
          setIsLoadingMore(true);
        } else {
          setLoading(true);
        }
        const { data } = await client.query<ListChatsPaginationQuery>({
          query: ListChatsPaginationDocument,
          variables: {
            first: 10,
            after: afterCursor,
          },
          fetchPolicy: 'network-only',
        });

        const newChats = data.listChatsPagination.edges?.map(
          (edge) => edge?.node
        );
        setListChats((prevChats) => {
          if (!newChats) {
            return prevChats || [];
          }

          const validChats = newChats.filter(
            (chat): chat is Chat => chat !== null && chat !== undefined
          );

          const combinedChats = afterCursor
            ? [...(prevChats || []), ...validChats]
            : validChats;

          return Array.from(
            new Map(combinedChats.map((chat) => [chat.id, chat])).values()
          );
        });

        setPageInfo({
          hasNextPage: data.listChatsPagination.pageInfo.hasNextPage,
          endCursor: data.listChatsPagination.pageInfo.endCursor || null,
        });
      } catch (error) {
        console.error('Failed to fetch chats:', error);
      } finally {
        if (afterCursor) {
          setIsLoadingMore(false);
        } else {
          setLoading(false);
        }
      }
    },
    [client]
  );

  const loadMore = useCallback(() => {
    if (isLoadingMore || !pageInfo.hasNextPage) return;
    fetchChats(pageInfo.endCursor);
  }, [fetchChats, isLoadingMore, pageInfo]);

  useFocusEffect(
    useCallback(() => {
      const unsubscribe = subscribeToChatUpdates((updatedChat) => {
        setListChats((prevChats) => {
          const existingIndex = prevChats.findIndex(
            (chat) => chat.id === updatedChat.id
          );

          if (existingIndex !== -1) {
            const updatedChats = [...prevChats];
            updatedChats[existingIndex] = {
              ...updatedChats[existingIndex],
              ...updatedChat,
            };
            return updatedChats.sort(chatSort);
          } else {
            return [updatedChat, ...prevChats].sort(chatSort);
          }
        });
      });

      return () => unsubscribe();
    }, [subscribeToChatUpdates])
  );

  const refresh = useCallback(() => {
    setRefreshing(true);
    fetchChats().finally(() => setRefreshing(false));
  }, [fetchChats]);

  const sortedListChats = useMemo(() => {
    const pinnedChats = listChats.filter((item) => item?.settings?.pin);
    const sortedPinnedChats = pinnedChats.sort(chatSort);
    const nonPinnedChats = listChats.filter((item) => !item?.settings?.pin);
    const sortedNonPinnedChats = nonPinnedChats.sort(chatSort);

    return [...sortedPinnedChats, ...sortedNonPinnedChats];
  }, [listChats]);

  return children({
    listChats: sortedListChats,
    loading,
    refresh,
    refreshing,
    loadMore,
    hasNextPage: pageInfo.hasNextPage,
  });
};

export const ChatsData = Sentry.withProfiler(ChatData);

// ChatsData.whyDidYouRender = true
