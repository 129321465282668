import {
  useFocusEffect,
  useNavigation,
  useRoute,
} from '@react-navigation/native';
import { parseInt } from 'lodash';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ActivityIndicator,
  Platform,
  StyleProp,
  ViewStyle,
} from 'react-native';
import PieChart from 'react-native-pie-chart';
import Purchases from 'react-native-purchases';

import { HeaderGoBack } from '@components/HeaderGoBack/HeaderGoBack';
import PromoCard from '@components/Profile/PromoCard';
import { Box, Text } from '@components/Restyle';
import Icon from '@components/shared/Icon/RestyleIcon';
import { useTeamFromQuery } from '@hooks/useTeamFromQuery';
import { ProfileStackScreenProps } from '@navigation/profile/profile-stack';
import { SubscriptionStatus } from '@root/types';
import {
  MOBILE_PLAN_NAME,
  SUBSCRIPTION_STATUS,
} from '@src/constants/constants';
import theme from '@themes/theme';
import { remoteConfigValues } from '@utils/remoteConfigValues';

const mbToBytes = (mb: number) => mb * 1024 * 1024;

export const Analytics = () => {
  const [subscriptionStatus, setSubscriptionStatus] =
    useState<SubscriptionStatus>(SUBSCRIPTION_STATUS.UNSUBSCRIBED);
  const route = useRoute<ProfileStackScreenProps<'analytics'>['route']>();
  const navigation = useNavigation();
  const { params } = route;
  const { teamId } = params;
  const { team, loading: teamLoading } = useTeamFromQuery({ teamId });
  const { tasksCount, completedTasksCount } = team || {};
  const { t } = useTranslation();
  const widthAndHeight = 146;
  const teamFeatures = team?.product?.features;

  const totalSizeAvaiblePlan = mbToBytes(
    Number(teamFeatures?.storage_gb_amount)
  );

  const storageAvailable = team
    ? Math.max(0, parseInt(team?.storageRemaining))
    : 0;
  const filesAndDocs = useMemo(() => {
    if (!team) return 0;
    return Math.max(parseInt(team.fileStorageUsed));
  }, [team, teamLoading]);

  const media = useMemo(() => {
    if (!team) return 0;
    return Math.max(parseInt(team.mediaStorageUsed));
  }, [team, teamLoading]);

  const series = useMemo(() => {
    return [filesAndDocs, media, storageAvailable];
  }, [filesAndDocs, media, storageAvailable]);
  const sliceColor = [
    theme.colors.blue,
    theme.colors.orange,
    theme.colors.purple,
  ];

  // console.log({ teamId, filesAndDocs, media, storageAvailable, team });

  const storageUsed = (
    color: string,
    label: string,
    size: string,
    containerStyles?: StyleProp<ViewStyle>
  ) => {
    return (
      <Box style={containerStyles}>
        <Box flexDirection='row' alignItems='center'>
          <Box
            width={theme.spacing.l}
            height={theme.spacing.xs}
            backgroundColor={color}
            borderRadius='xxs'
            marginRight='xs'
          />
          <Text variant='metadataSecondary' color='grey04'>
            {label}
          </Text>
        </Box>
        {teamLoading ? (
          <ActivityIndicator />
        ) : (
          <Text
            variant='labelSmall'
            style={{ marginLeft: theme.spacing.l + theme.spacing.xs }}>
            {size}
          </Text>
        )}
      </Box>
    );
  };

  useFocusEffect(
    useCallback(() => {
      if (Platform.OS === 'web') return;

      const getCustomerInfo = async () => {
        const customerInfo = await Purchases.getCustomerInfo();
        const customerIsNew = customerInfo.allPurchasedProductIdentifiers.some(
          (ident) => ident == MOBILE_PLAN_NAME
        );
        console.log({ customerIsNew, ident: customerInfo.allPurchaseDates });
        if (customerInfo.activeSubscriptions.length < 1) {
          setSubscriptionStatus(SUBSCRIPTION_STATUS.UNSUBSCRIBED);
        } else {
          setSubscriptionStatus(SUBSCRIPTION_STATUS.SUBSCRIBED);
        }
      };
      getCustomerInfo();
    }, [])
  );

  const subscriptionsEnabled = __DEV__ || remoteConfigValues().subscriptions;

  return (
    <Box
      flex={1}
      padding='m'
      style={
        Platform.OS === 'web' ? { marginEnd: 300 + theme.spacing.m } : undefined
      }
      marginTop={Platform?.OS !== 'web' ? 'xxl' : 'xxxs'}>
      <HeaderGoBack
        redirectTo='team-details'
        titleHeader='Analytics'
        params={{ teamId: teamId }}
        noSpacing
      />
      <Box flexDirection='row'>
        <Box
          backgroundColor='grey01'
          marginRight='s'
          padding='m'
          borderRadius='xs'
          flex={1}
          maxHeight={176}
          aspectRatio={1}>
          <Box flexDirection='row'>
            <Icon
              name='Folder'
              variant='m'
              padding='s'
              color='background'
              backgroundColor='greenSecondary'
            />
            <Box flex={1} />
          </Box>
          <Box flex={1} />
          <Text variant='labelEmphasized'>Projects</Text>
          <Box flexDirection='row' justifyContent='space-between' marginTop='m'>
            <Box mr='xl'>
              {teamLoading ? (
                <ActivityIndicator />
              ) : (
                <Text variant='labelSmall'>
                  {team?.numberOfProjectsUsed || 0}
                </Text>
              )}
              <Text variant='metadata'>Created</Text>
            </Box>
            <Box>
              {teamLoading ? (
                <ActivityIndicator />
              ) : (
                <Text variant='labelSmall'>
                  {teamFeatures?.projects_amount || 0}
                </Text>
              )}
              <Text variant='metadata'>Allowed</Text>
            </Box>
            {Platform.OS === 'web' && <Box flex={3}></Box>}
          </Box>
        </Box>
        <Box
          backgroundColor='grey01'
          marginLeft='s'
          padding='m'
          borderRadius='xs'
          flex={1}
          maxHeight={176}
          aspectRatio={1}>
          <Box flexDirection='row'>
            <Icon
              name='Hash'
              variant='m'
              padding='s'
              color='background'
              backgroundColor='textPrimary'
            />
            <Box flex={1} />
          </Box>
          <Box flex={1} />
          <Text variant='labelEmphasized'>Tasks</Text>
          <Box flexDirection='row' justifyContent='space-between' marginTop='m'>
            <Box mr='xl'>
              {teamLoading ? (
                <ActivityIndicator />
              ) : (
                <Text variant='labelSmall'>{tasksCount}</Text>
              )}
              <Text variant='metadata'>Created</Text>
            </Box>
            <Box>
              {teamLoading ? (
                <ActivityIndicator />
              ) : (
                <Text variant='labelSmall'>{completedTasksCount}</Text>
              )}
              <Text variant='metadata'>Completed</Text>
            </Box>
            {Platform.OS === 'web' && <Box flex={3}></Box>}
          </Box>
        </Box>
      </Box>
      <Box backgroundColor='grey01' marginTop='m' padding='m' borderRadius='xs'>
        <Text variant='labelEmphasized'>Storage Used</Text>
        <Box flexDirection='row' marginTop='xs' justifyContent='space-between'>
          <Box
            alignItems='center'
            justifyContent='center'
            position='relative'
            flex={1}
            marginRight='l'>
            {teamLoading ? (
              <Box width={widthAndHeight} height={widthAndHeight}>
                <ActivityIndicator size='large' />
              </Box>
            ) : (
              <PieChart
                widthAndHeight={widthAndHeight}
                series={series}
                sliceColor={sliceColor}
                coverFill='#FFF'
                coverRadius={0.69}
                style={{ transform: [{ rotateZ: '15deg' }] }}
              />
            )}
            <Box position='absolute' alignItems='center'>
              {teamLoading ? (
                <ActivityIndicator />
              ) : (
                <Text variant='labelSmall'>
                  {t('format:fileSize', { val: storageAvailable })}
                </Text>
              )}
              <Text variant='metadataSecondary'>Total Storage</Text>
            </Box>
          </Box>
          <Box flex={1} marginLeft='l'>
            {storageUsed(
              'purple',
              'Storage Available',
              t('format:fileSize', { val: totalSizeAvaiblePlan })
            )}
            {storageUsed(
              'blue',
              'Files and Docs',
              t('format:fileSize', { val: filesAndDocs }),
              { marginTop: theme.spacing.m }
            )}
            {storageUsed(
              'orange',
              'Media',
              t('format:fileSize', { val: media }),
              { marginTop: theme.spacing.m }
            )}
          </Box>
          {Platform.OS === 'web' && <Box flex={3}></Box>}
        </Box>
      </Box>
      {subscriptionsEnabled &&
        subscriptionStatus == 'unsubscribed' &&
        Platform.OS != 'web' &&
        team?.personal && (
          <PromoCard
            onPress={() => {
              navigation.navigate('payments-stack', { screen: 'upgrade-plus' });
            }}
          />
        )}
    </Box>
  );
};
