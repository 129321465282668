import * as ExpoConstants from 'expo-constants';
import * as Notifications from 'expo-notifications';
import * as Updates from 'expo-updates';
import { Platform } from 'react-native';

export const getDeviceId = async () => {
  const Constants = ExpoConstants.default;

  const projectId =
    Updates.manifest?.extra?.eas?.projectId ||
    Constants?.expoConfig?.extra?.eas?.projectId ||
    Constants?.easConfig?.projectId ||
    Constants?.manifest2?.extra?.eas?.projectId ||
    Constants?.manifest?.extra?.eas?.projectId ||
    '92b1f575-ddb1-40a4-94fc-42c64d2db95d';
  try {
    if (Platform.OS === 'web') {
      let id = sessionStorage.getItem('uniqueDeviceId');
      if (!id) {
        id = crypto.randomUUID();
        sessionStorage.setItem('uniqueDeviceId', id);
      }
      return id;
    } else if (projectId && typeof projectId === 'string') {
      const { data: notificationToken } =
        await Notifications.getExpoPushTokenAsync({
          projectId: projectId,
        });
      return notificationToken;
    } else {
      console.error('Error: projectId is undefined or not a string');
    }
  } catch (error) {
    console.error('Error fetching device info:', error);
    return null;
  }
};
