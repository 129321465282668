import AsyncStorage from '@react-native-async-storage/async-storage';
import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Modal,
  Dimensions,
  StyleSheet,
  TouchableWithoutFeedback,
  Platform,
} from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import MessagePopup from '@components/Popups/MessagePopup';
import { Box } from '@components/Restyle';
import Icon from '@components/shared/Icon/RestyleIcon';
import {
  GetMeDocument,
  Message,
  TutoralizationSetting,
  useUpdateTutoralizationSettingMutation,
} from '@graphql/generated';
import useChatInput from '@hooks/useChatInput';
import { ChatsStackParamList } from '@navigation/chats/chats-stack';
import theme from '@themes/theme';

interface TutorialModalProps {
  messages: Message[];
}

const TUTORIAL_HASHTAG_KEY = '@tutoralizationHashtag';
const TUTORIAL_TAG_MESSAGE_KEY = '@tutoralizationTagMessage';
const TUTORIAL_ASSIGN_TASK_KEY = '@tutoralizationAssignTask';

export const TutorialModal: React.FC<TutorialModalProps> = ({ messages }) => {
  const navigation =
    useNavigation<
      StackNavigationProp<ChatsStackParamList, 'chat-attachments'>
    >();
  const [shouldShowTutorial, setShouldShowTutorial] = useState<boolean>(true);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [stepModalOpen, setStepModalOpen] = useState<boolean>(false);
  const [stepModalOpen1, setStepModalOpen1] = useState<boolean>(false);
  const [stepModalOpen2, setStepModalOpen2] = useState<boolean>(false);
  const [shouldShowTutorialAssignTask, setShouldShowTutorialAssignTask] =
    useState<boolean>(false);
  const [opacity, setOpacity] = useState<number>(1);
  const { t } = useTranslation('models');
  const insets = useSafeAreaInsets();
  const [
    updateTutorialSettingsForHashtag,
    setUpdateTutorialSettingsForHashtag,
  ] = useState<boolean>(true);

  const {
    messageModalHeight,
    modalHeight,
    setModalHeight,
    shouldShowTutorialHashTag,
    setShouldShowTutorialHashTag,
    showChatOptionsBar,
  } = useChatInput();

  useEffect(() => {
    if (shouldShowTutorialHashTag && !modalOpen && messages.length === 0)
      showTutorialHashTag();
  }, [shouldShowTutorialHashTag]);

  useEffect(() => {
    if (messages.length > 0) {
      shouldShowTutorialHashTag && setShouldShowTutorialHashTag(false);
      if (updateTutorialSettingsForHashtag) doUpdateTutorialSettings();
    }
    if (shouldShowTutorial && !modalOpen && messages.length > 0) showTutorial();
  }, [messages]);

  const doUpdateTutorialSettings = async () => {
    setUpdateTutorialSettingsForHashtag(false);
    const tutorial = await AsyncStorage.getItem(TUTORIAL_HASHTAG_KEY);
    if (tutorial !== '1') {
      AsyncStorage.setItem(TUTORIAL_HASHTAG_KEY, '1');
      updateTutorialSettings({
        variables: {
          attributes: {
            settingType: TutoralizationSetting.Hashtag,
            close: true,
          },
        },
      });
    }
  };

  // Tutorial Start
  const showTutorialHashTag = async () => {
    setShouldShowTutorialHashTag(false);
    const tutorial = await AsyncStorage.getItem(TUTORIAL_HASHTAG_KEY);
    if (tutorial !== '1') {
      setModalOpen(true);
      return true;
    }
    return false;
  };

  const showTutorial = async () => {
    if (messages.length > 0) {
      const tags = messages?.filter((m) => {
        const items = m?.attachments?.filter(
          (item) =>
            item.__typename === 'MessageTask' ||
            item.__typename === 'MessageProject'
        );
        if (items && items.length > 0) return true;
      });
      if (tags && tags.length > 0) {
        const tutorial = await AsyncStorage.getItem(TUTORIAL_ASSIGN_TASK_KEY);
        if (tutorial !== '1') {
          setShouldShowTutorial(false);
          setShouldShowTutorialAssignTask(true);
          setModalOpen(true);
          return;
        }
      }
      const tutorial = await AsyncStorage.getItem(TUTORIAL_TAG_MESSAGE_KEY);
      if (tutorial !== '1') {
        setShouldShowTutorial(false);
        setShouldShowTutorialAssignTask(false);
        setModalOpen(true);
      }
    }
  };

  const getHashTagBox = () => {
    let left = 0;
    if (shouldShowTutorialAssignTask) {
      if (showChatOptionsBar) {
        left = 16;
      } else {
        left = 6;
      }
    } else {
      left = 61;
    }
    let size = 0;
    if (shouldShowTutorialAssignTask) {
      if (showChatOptionsBar) {
        size = 40;
      } else {
        size = 30;
      }
    } else {
      size = 39;
    }
    const top =
      Dimensions.get('window').height -
      size -
      (insets.bottom ? insets.bottom : 16);
    return (
      <Box
        alignItems='center'
        justifyContent='center'
        style={{
          position: 'absolute',
          left: left,
          top: top,
          opacity: 1,
          zIndex: 99,
          width: size,
          height: size,
          borderRadius: 6,
          backgroundColor: 'white',
        }}>
        <Icon
          name={shouldShowTutorialAssignTask ? 'Plus' : 'Hash'}
          variant='l'
          color='textPrimary'
        />
      </Box>
    );
  };

  const getMessage = () => {
    if (messages.length === 0) {
      return t('chat.tutorial.hashtagMessage.message');
    }

    const messageKey = shouldShowTutorialAssignTask
      ? 'assignTaskMessage'
      : 'tagMessage';

    return t(`chat.tutorial.${messageKey}.message`);
  };

  const getHighlight = () => {
    if (!messages.length) {
      return '#tag';
    }
    return shouldShowTutorialAssignTask ? '' : 'tag';
  };

  const showTutorialModal = () => {
    const continueFn = () => {
      setModalOpen(false);
      setOpacity(0);
      if (messages.length > 0) {
        setModalHeight(0);
        if (shouldShowTutorialAssignTask) {
          navigation.navigate('chat-attachments', {
            showTutorial: true,
          });
        } else {
          navigation.navigate('edit-message-highlight-modal', {
            showTutorial: true,
          });
        }
        setTimeout(() => {
          setStepModalOpen(true);
          setStepModalOpen1(true);
        }, 10);
      } else {
        setStepModalOpen(true);
        setStepModalOpen1(true);
      }
      setTimeout(() => {
        setOpacity(1);
      }, 2000);
    };

    return (
      <>
        <TouchableWithoutFeedback onPress={closeFn}>
          <Box>
            <Box style={styles.modal}></Box>
            {messages.length === 0 && getHashTagBox()}
            {shouldShowTutorialAssignTask && getHashTagBox()}
          </Box>
        </TouchableWithoutFeedback>
        <Box style={styles.popUp}>
          <MessagePopup
            showIcon={true}
            title={t(
              `chat.tutorial.${
                shouldShowTutorialAssignTask
                  ? 'assignTaskMessage'
                  : 'tagMessage'
              }.title`
            )}
            message={getMessage()}
            highlight={[getHighlight()]}
            dismissBtn={true}
            continueBtn={true}
            width={268}
            closePopFn={closeFn}
            continueFn={continueFn}
          />
        </Box>
      </>
    );
  };

  const [updateTutorialSettings] = useUpdateTutoralizationSettingMutation({
    refetchQueries: [{ query: GetMeDocument }, 'getMe'],
  });

  const closeFn = () => {
    let name = '';
    let settingType = TutoralizationSetting.Hashtag;
    if (messages.length > 0) {
      if (shouldShowTutorialAssignTask) {
        name = TUTORIAL_ASSIGN_TASK_KEY;
        settingType = TutoralizationSetting.AssignTask;
      } else {
        name = TUTORIAL_TAG_MESSAGE_KEY;
        settingType = TutoralizationSetting.TagMessage;
      }
    } else {
      name = TUTORIAL_HASHTAG_KEY;
      settingType = TutoralizationSetting.Hashtag;
    }
    AsyncStorage.setItem(name, '1');

    updateTutorialSettings({
      variables: {
        attributes: {
          settingType,
          close: true,
        },
      },
    });

    setModalOpen(false);
    setShouldShowTutorial(true);
    if (shouldShowTutorialAssignTask) {
      setShouldShowTutorialAssignTask(false);
    }
    if (stepModalOpen) {
      setStepModalOpen(false);
      if (messages.length > 0) {
        setTimeout(() => {
          navigation.goBack();
        }, 10);
      }
    }
  };

  const showTutorialStepModal = () => {
    let style = styles.popUp2;
    if (messages.length === 0) {
      style = styles.popUp;
    } else if (shouldShowTutorialAssignTask) {
      style = styles.popUp2;
    } else if (stepModalOpen1) {
      style = styles.popUp;
    }
    return (
      <>
        {messages.length > 0 ? (
          getTutorialBackground()
        ) : (
          <TouchableWithoutFeedback onPress={closeFn}>
            <Box>
              <Box style={styles.modal}></Box>
              {messages.length === 0 && getHashTagBox()}
            </Box>
          </TouchableWithoutFeedback>
        )}
        <Box
          style={[
            style,
            { top: (Dimensions.get('window').height - messageModalHeight) / 2 },
          ]}>
          {getMessagePopup()}
        </Box>
      </>
    );
  };

  const getMessagePopup = () => {
    if (shouldShowTutorialAssignTask) {
      let stepNumber;

      if (stepModalOpen1) {
        stepNumber = 1;
      } else if (stepModalOpen2) {
        stepNumber = 2;
      } else {
        stepNumber = 3;
      }

      const imageName = `AssignTaskMsg${stepNumber}`;
      const step = t(`chat.tutorial.assignTaskMessageStep${stepNumber}.step`);
      const title = t(`chat.tutorial.assignTaskMessageStep${stepNumber}.title`);
      const message = t(
        `chat.tutorial.assignTaskMessageStep${stepNumber}.message`
      );
      return (
        <MessagePopup
          opacity={opacity}
          showStep={true}
          showImage={true}
          imageName={imageName}
          step={step}
          title={title}
          message={message}
          highlight={['']}
          nextBtn={stepModalOpen1 || stepModalOpen2}
          finishBtn={!stepModalOpen1 && !stepModalOpen2}
          width={268}
          closePopFn={closeFn}
          finishFn={closeFn}
          nextFn={() => {
            setOpacity(0);

            if (stepModalOpen1) {
              setStepModalOpen1(false);
              setStepModalOpen2(true);
            } else if (stepModalOpen2) {
              setStepModalOpen2(false);
            }

            setTimeout(() => {
              setOpacity(1);
            }, 1000);
          }}
        />
      );
    } else {
      let message = t('chat.tutorial.tagMessageStep2.message');
      if (stepModalOpen1) {
        if (messages.length > 0) {
          message = t('chat.tutorial.tagMessageStep1.message');
        } else {
          message = t('chat.tutorial.hashtagMessageStep1.message');
        }
      }
      return (
        <MessagePopup
          opacity={opacity}
          showStep={true}
          showImage={!stepModalOpen1 || messages.length === 0}
          imageName={
            !stepModalOpen1 && messages.length > 0 ? 'Message21' : 'Message1'
          }
          step={
            stepModalOpen1
              ? t('chat.tutorial.tagMessageStep1.step')
              : t('chat.tutorial.tagMessageStep2.step')
          }
          title={
            stepModalOpen1
              ? t('chat.tutorial.tagMessageStep1.title')
              : t('chat.tutorial.tagMessageStep2.title')
          }
          message={message}
          highlight={messages.length > 0 ? [''] : ['#hashtag', '#icon']}
          nextBtn={stepModalOpen1}
          finishBtn={!stepModalOpen1}
          width={268}
          closePopFn={closeFn}
          finishFn={closeFn}
          nextFn={() => {
            setOpacity(0);

            setStepModalOpen1(false);

            setTimeout(() => {
              setOpacity(1);
            }, 500);
          }}
        />
      );
    }
  };

  const getTutorialBackground = () => {
    const height1 = Dimensions.get('window').height - (modalHeight - 48);
    const height2 = shouldShowTutorialAssignTask
      ? modalHeight - 48 - (10 + insets.bottom)
      : 48 - 10;
    const height3 = shouldShowTutorialAssignTask
      ? 10 + insets.bottom
      : modalHeight - 48 * 2 + 10;
    const top2 = Dimensions.get('window').height - modalHeight;
    const top3 =
      Dimensions.get('window').height -
      (shouldShowTutorialAssignTask
        ? 10 + insets.bottom
        : modalHeight - 48 * 2 + 10);

    const baseValue = 48;
    const mediumPhone = 915;

    const phoneSize = Dimensions.get('window').height;
    const normalizedValue =
      phoneSize > mediumPhone ? baseValue + 29 : baseValue;

    return (
      <TouchableWithoutFeedback onPress={closeFn}>
        <Box>
          <Box
            style={[
              {
                zIndex: 100,
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                height: height1,
                backgroundColor: 'black',
                opacity: 0,
              },
            ]}></Box>
          <Box
            style={[
              {
                zIndex: 100,
                position: 'absolute',
                left: 0,
                right: 0,
                top: top2 - (Platform.OS === 'android' ? normalizedValue : 0),
                height: height2,
                borderTopLeftRadius: theme.spacing.l,
                borderTopRightRadius: theme.spacing.l,
                backgroundColor: 'black',
                opacity: 0.5,
              },
            ]}></Box>
          <Box
            style={[
              {
                zIndex: 100,
                position: 'absolute',
                left: 0,
                right: 0,
                top: top3 - (Platform.OS === 'android' ? normalizedValue : 0),
                height: height3,
                backgroundColor: 'black',
                opacity: 0.5,
              },
            ]}></Box>
        </Box>
      </TouchableWithoutFeedback>
    );
  };
  // Tutorial End

  return (
    <Modal
      animationType='fade'
      transparent={true}
      visible={modalOpen || stepModalOpen}
      onRequestClose={closeFn}>
      {modalOpen && showTutorialModal()}
      {stepModalOpen && showTutorialStepModal()}
    </Modal>
  );
};

const styles = StyleSheet.create({
  modal: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    zIndex: 99,
    height: Dimensions.get('window').height,
    backgroundColor: 'black',
    opacity: 0.5,
  },
  popUp: {
    zIndex: 100,
    position: 'absolute',
    top: (Dimensions.get('window').height * 245) / 813, // Figma
    left: (Dimensions.get('window').width - 268) / 2,
    right: (Dimensions.get('window').width - 268) / 2,
  },
  popUp2: {
    zIndex: 100,
    position: 'absolute',
    top: (Dimensions.get('window').height * 127) / 813, // Figma
    left: (Dimensions.get('window').width - 268) / 2,
    right: (Dimensions.get('window').width - 268) / 2,
  },
});
