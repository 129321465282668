import { useNavigation, useRoute } from '@react-navigation/native';
import { Formik } from 'formik';
import get from 'lodash.get';
import sortBy from 'lodash.sortby';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Alert, FlatList, StyleSheet, TouchableOpacity } from 'react-native';

import { HeaderGoBack } from '@components/HeaderGoBack/HeaderGoBack';
import SecondaryHeader from '@components/Headers/SecondaryHeader';
import {
  AddMemberType,
  SelectMembersList,
} from '@components/Invite/SelectMembersList';
import { WebModal } from '@components/Modals/WebModal';
import { Box, Text } from '@components/Restyle';
import ActivityIndicatorLoading from '@components/shared/ActivityIndicatorLoading';
import Icon from '@components/shared/Icon/Icon';
import { MemberRole } from '@components/User/MemberRole';
import { useWebDrawer } from '@components/Web/Drawer/WebDrawerContext';
import {
  TeamRole,
  User,
  useAssignTeamOwnerMutation,
  useRemoveTeamMemberMutation,
  useUpdateTeamMembersMutation,
} from '@graphql/generated';
import { useContacts } from '@hooks/useContacts';
import useMe from '@hooks/useMe';
import useSearch from '@hooks/useSearch';
import { useTeamFromQuery } from '@hooks/useTeamFromQuery';
import { TeamsStackScreenProps } from '@navigation/teams/teams-stack';
import TeamActionList from '@screens/Teams/TeamActionList.web';
import theme from '@themes/theme';
import {
  defaultTeamRole,
  getTeamRoleEnumValue,
  selectableTeamRoles,
} from '@utils/teams';

export type MemberAttributesWithOwner = {
  role: TeamRole | 'OWNER';
  user: User;
};

const TeamMembers: React.FC = () => {
  const TASKTAG_SUPPORT_EMAIL = 'emmanueln@lovettcommercial.com';
  const route = useRoute<TeamsStackScreenProps<'team-members'>['route']>();

  const { me } = useMe();
  const navigation = useNavigation();
  const { search } = useSearch();
  const { t } = useTranslation('models');

  const {
    setIsTaskWebPanelOpen,
    setIdTaskDetailOpen,
    setIdProjectOfTaskDetailOpen,
    setIsTaskWebPanelMaximize,
    setIsCreateProjectOpen,
    setEditProjectId,
    setUpgradeTeamPlanOpen,
    setIdTeamOpen,
    setIsTeamPersonal,
    setTeamValues,
    teamValues,
  } = useWebDrawer();

  const goToUpgradeSubscription = () => {
    setIsTaskWebPanelOpen(false);
    setIsTaskWebPanelMaximize(false);
    setIdTaskDetailOpen('');
    setIdProjectOfTaskDetailOpen('');
    setIsCreateProjectOpen(false);
    setEditProjectId('');
    setUpgradeTeamPlanOpen(true);
    setIdTeamOpen(team?.id ?? '');
    setIsTeamPersonal(team?.personal ?? false);
  };

  const {
    params: { teamId },
  } = route || {};

  const { team, loading } = useTeamFromQuery({
    teamId,
  });

  const [updateTeamMembers] = useUpdateTeamMembersMutation();

  const [removeTeamMember] = useRemoveTeamMemberMutation();

  const [assignTeamOwner] = useAssignTeamOwnerMutation();

  const [showAddMembersModal, setShowAddMembersModal] = useState(false);

  useEffect(() => {
    if (team) {
      navigation.setOptions({
        header: () => (
          <SecondaryHeader
            title={`Members (${team?.users.length})`}
            searchable={false}
          />
        ),
      });
    }
  }, [team]);

  if (loading) {
    return <ActivityIndicatorLoading />;
  }

  if (!team) {
    return (
      <Box flex={1} paddingTop='m' alignItems='center'>
        <Text>Team not found</Text>
      </Box>
    );
  }

  const { users, owner } = team;

  const initialValues = {
    users: [],
    selectedUserIds: (team?.users || []).map((u) => u.id),
  };

  const convertMembersToMembersWithRoles = (
    users: User[]
  ): MemberAttributesWithOwner[] => {
    const alphabetizedMembers = sortBy(users, [
      (item) => get(item, 'firstName')?.toLowerCase(),
      (item) => get(item, 'lastName')?.toLowerCase(),
    ]);

    const sortedOwnerFirst = alphabetizedMembers.slice();
    const ownerIndex = sortedOwnerFirst.findIndex((u) => u.id === owner?.id);
    const removeOwner = sortedOwnerFirst.splice(ownerIndex, 1)[0];
    sortedOwnerFirst.unshift(removeOwner);

    const ownerMembers: MemberAttributesWithOwner[] = [];
    const adminMembers: MemberAttributesWithOwner[] = [];
    const members: MemberAttributesWithOwner[] = [];

    sortedOwnerFirst.map((user) => {
      let role: MemberAttributesWithOwner['role'];

      if (user.id === owner?.id) {
        role = TeamRole.Owner;
        ownerMembers.push({
          user,
          role,
        });
      } else if (user.teamRoles?.includes(TeamRole.Admin)) {
        role = TeamRole.Admin;
        adminMembers.push({
          user,
          role,
        });
      } else {
        role = TeamRole.Member;
        members.push({
          user,
          role,
        });
      }

      return {
        user,
        role,
      };
    });

    return ownerMembers.concat(adminMembers).concat(members);
  };

  const memberRoleData = convertMembersToMembersWithRoles(users);

  const isOwner = users
    ?.find((u) => u.id === me?.id)
    ?.teamRoles?.includes(TeamRole.Owner);

  const isAdmin = users
    ?.find((u) => u.id === me?.id)
    ?.teamRoles?.some((role) =>
      [TeamRole.Admin, TeamRole.Owner].includes(role)
    );

  const isOwnerOrAdmin = (isOwner || isAdmin) && !team.personal;

  const removeMemberFromTeam = (userId: string) => {
    removeTeamMember({
      variables: {
        id: teamId,
        userId,
      },
    });
  };

  const assignNewOwnerAlert = (user: User) => {
    Alert.alert(
      t('models:teams.assignNewOwner.title'),
      t('models:teams.assignNewOwner.messageDowngradedToAdmin', {
        member: user.name,
      }),
      [
        { text: t('shared:cancel') },
        {
          text: t('shared:assign'),
          onPress: () => assignNewOwner(user),
        },
      ]
    );
  };

  const assignNewOwner = (user: User) => {
    if (!teamId) return;

    assignTeamOwner({
      variables: {
        id: teamId,
        newOwnerId: user.id,
      },
      onCompleted: () => {
        me && updateTeamMember(me.id, TeamRole.Admin);
      },
      onError: (error) => {
        Alert.alert(t('shared:error'), error.message);
      },
    });
  };

  const updateTeamMember = (userId: string, role: TeamRole) => {
    updateTeamMembers({
      variables: {
        teamId,
        attributes: [
          {
            userId,
            role: getTeamRoleEnumValue(role),
          },
        ],
      },
    });
  };

  const renderItem = ({ item }: { item: MemberAttributesWithOwner }) => (
    <Box mx='m'>
      <MemberRole<TeamRole>
        roles={selectableTeamRoles}
        member={item}
        updateRole={
          isOwnerOrAdmin &&
          item.user.id !== owner?.id &&
          item.role !== TeamRole.Owner
            ? (role) => {
                if (role === TeamRole.Owner) {
                  assignNewOwnerAlert(item.user);
                } else {
                  updateTeamMember(item.user.id, role);
                }
              }
            : undefined
        }
        removeMember={
          isOwnerOrAdmin
            ? (member) => removeMemberFromTeam(member?.user?.id)
            : undefined
        }
        filterVal={search}
      />
    </Box>
  );

  const itemSeparatorComponent = () => <Box marginTop='m' />;
  const listFooterComponent = () => <Box marginBottom='s' />;
  const listHeaderComponent = () => <Box marginTop='xs' />;

  return (
    <Box flex={1} mx='xs'>
      <HeaderGoBack
        redirectTo='team-details'
        titleHeader='Members'
        params={{ teamId: teamId }}
      />
      {!team.personal && team.plan && (
        <TeamActionList
          teamName={team.name}
          isOwnerOrAdmin={isOwnerOrAdmin}
          setShowAddMembersModal={setShowAddMembersModal}
          teamId={teamId}
        />
      )}
      <FlatList
        data={memberRoleData}
        showsVerticalScrollIndicator={false}
        showsHorizontalScrollIndicator={false}
        renderItem={renderItem}
        keyExtractor={(item) => item.user.id.toString()}
        ItemSeparatorComponent={itemSeparatorComponent}
        ListFooterComponent={listFooterComponent}
        ListHeaderComponent={listHeaderComponent}
      />
      <WebModal
        accessibilityLabel='Add Team Members'
        onClose={() => setShowAddMembersModal(false)}
        visible={showAddMembersModal}
        width={504}
        height={512}>
        <Formik initialValues={initialValues} onSubmit={() => {}}>
          {({ setFieldValue, values, isSubmitting }) => {
            const [step, setStep] = useState<number>(1);
            const { selectedUserIds: selectedUserIdsRaw, users } = values;
            const selectedUserIds = selectedUserIdsRaw?.filter(
              (s) => !!s
            ) as string[];

            const { contacts } = useContacts();

            const ButtonAdd = (
              xWidth: number,
              accessibilityLabel: string,
              backgroundColor: keyof typeof theme.colors,
              txtColor: keyof typeof theme.colors,
              isLoading: boolean,
              onPress: () => void
            ) => {
              return (
                <TouchableOpacity
                  onPress={onPress}
                  accessibilityLabel={accessibilityLabel}>
                  <Box
                    style={{ width: xWidth, height: 48 }}
                    alignItems='center'
                    borderWidth={1}
                    borderColor={txtColor}
                    borderRadius='xs'
                    justifyContent='center'
                    backgroundColor={backgroundColor}
                    mr='xs'>
                    {!isLoading && (
                      <Text variant='labelSmall' color={txtColor}>
                        {accessibilityLabel}
                      </Text>
                    )}
                    {isLoading && <ActivityIndicatorLoading />}
                  </Box>
                </TouchableOpacity>
              );
            };
            if (step === 1)
              return (
                <>
                  <Box
                    flexDirection='row'
                    justifyContent='space-between'
                    marginHorizontal='m'
                    mt='l'
                    mb='xs'>
                    <Text variant='labelEmphasized'>
                      {t('shared:addMembers')}
                    </Text>
                    <Icon
                      name='X'
                      variant='m'
                      color='black'
                      onPress={() => {
                        setShowAddMembersModal(false);
                      }}
                    />
                  </Box>
                  <Box
                    flex={1}
                    accessibilityLabel='Add Members'
                    backgroundColor='white'
                    mb='m'>
                    <SelectMembersList
                      isCreateTask={false}
                      isCreateProject={false}
                      teamId={teamId}
                      projectId=''
                      contacts={contacts.filter(
                        (c) => c.email !== TASKTAG_SUPPORT_EMAIL
                      )}
                      showTaskTagUsers
                      selectedUserIds={selectedUserIds}
                      disabledUsersId={selectedUserIds}
                      users={users}
                      updateUsers={(newUsers: AddMemberType[]) => {
                        setFieldValue(
                          'users',
                          newUsers.map((u) => {
                            return {
                              ...u,
                              role: u.role || defaultTeamRole,
                            };
                          })
                        );
                      }}
                    />
                    <Box
                      style={{
                        position: 'absolute',
                        bottom: 0,
                        right: theme.spacing.m,
                      }}
                      flexDirection='row'>
                      {ButtonAdd(
                        107,
                        t('shared:next'),
                        users.length > 0 ? 'black' : 'grey02',
                        'white',
                        isSubmitting,
                        async () => {
                          if (users.length > 0) {
                            setStep(2);
                          }
                        }
                      )}
                    </Box>
                  </Box>
                </>
              );
            else if (step === 2) {
              return (
                <>
                  <Box
                    flexDirection='row'
                    justifyContent='space-between'
                    marginHorizontal='m'
                    mt='l'
                    mb='xs'>
                    <Text variant='labelEmphasized'>
                      {t('models:teams.subscriptions.editRole')}
                    </Text>
                    <Icon
                      name='X'
                      variant='m'
                      color='black'
                      onPress={() => {
                        setShowAddMembersModal(false);
                      }}
                    />
                  </Box>
                  <Box
                    flex={1}
                    accessibilityLabel='Edit Role'
                    backgroundColor='white'>
                    <FlatList
                      data={users
                        .map((u) => {
                          return {
                            user: u,
                            role: u.role,
                          };
                        })
                        ?.filter((item) => item.role !== 'OWNER')}
                      renderItem={({ item }) => {
                        return (
                          <Box marginLeft='m' marginRight='l'>
                            <MemberRole
                              roles={selectableTeamRoles}
                              member={item}
                              updateRole={(role) => {
                                setFieldValue(
                                  'users',
                                  users.map((u) => {
                                    return {
                                      ...u,
                                      role:
                                        u.id === item.user.id ? role : u.role,
                                    };
                                  })
                                );
                              }}
                            />
                          </Box>
                        );
                      }}
                      showsHorizontalScrollIndicator={false}
                      showsVerticalScrollIndicator={false}
                      keyExtractor={(item) => item.user.id}
                      ItemSeparatorComponent={() => <Box marginTop='m' />}
                      ListFooterComponent={() => (
                        <Box marginBottom='listFooter' />
                      )}
                      ListHeaderComponent={() => <Box marginTop='m' />}
                    />
                    <Box style={styles.bottomButtonWeb} flexDirection='row'>
                      {ButtonAdd(
                        77,
                        t('shared:back'),
                        'white',
                        'black',
                        false,
                        () => {
                          setStep(1);
                        }
                      )}
                      {ButtonAdd(
                        110,
                        t('shared:confirm'),
                        users.length > 0 ? 'black' : 'grey02',
                        'white',
                        isSubmitting,
                        async () => {
                          if (users.length > 0) {
                            // here need to go to edit subscription to payment for the added members
                            goToUpgradeSubscription();
                            setShowAddMembersModal(false);
                            setTeamValues({ teamValues, users: users });
                          }
                        }
                      )}
                    </Box>
                  </Box>
                </>
              );
            }
          }}
        </Formik>
      </WebModal>
    </Box>
  );
};

const styles = StyleSheet.create({
  bottomButtonWeb: {
    position: 'absolute',
    bottom: theme.spacing.m,
    right: theme.spacing.m,
  },
});

export default TeamMembers;
