import React from 'react';
import { useTranslation } from 'react-i18next';
import { TouchableOpacity, Image } from 'react-native';

import { WebModal } from '@components/Modals/WebModal.web';
import { Box, Text } from '@components/Restyle';
import { Team } from '@graphql/generated';

interface CancelSubscriptionModal2Props {
  visible: boolean;
  onClose: () => void;
  selectedTeam?: Team; // Adjust the type as necessary
  setShowCancelSubscriptionConfirmBox2: (value: boolean) => void;
}

const CancelSubscriptionModal2: React.FC<CancelSubscriptionModal2Props> = ({
  visible,
  onClose,
  selectedTeam,
  setShowCancelSubscriptionConfirmBox2,
}) => {
  const { t } = useTranslation();

  const getItem = (text: string) => (
    <Box alignItems='center' mb='m'>
      <Text variant='webBodySecondary' color='textPrimary'>
        {text}
      </Text>
    </Box>
  );

  return (
    <WebModal
      accessibilityLabel='cancel-subscription-modal-2'
      marginTop='m'
      visible={visible}
      width={400}
      height={480}
      title={t('models:profile.subscriptionPlans.areYouSureToCancelThePlan')}
      titleVariant='labelLarge'
      titleColor='textPrimary'
      marginHorizontal='l'
      onClose={onClose}>
      <Box flex={1} mx='l'>
        <Box alignItems='center' m='l'>
          <Image
            key={selectedTeam?.id ?? 'key1'}
            source={require('../../../assets/images/cancelPlan.png')}
            style={{
              width: 194,
              height: 169,
            }}
          />
        </Box>

        <Box alignItems='center' mb='m'>
          <Text variant='webBodySecondary' color='textPrimary'>
            {t('models:profile.subscriptionPlans.ifCancelThePlan')}
          </Text>
        </Box>

        {getItem(
          t('models:profile.subscriptionPlans.teamMembersWillBeDowngraded')
        )}
        {getItem(t('models:profile.subscriptionPlans.canNotCreateNewProjects'))}
        {getItem(t('models:profile.subscriptionPlans.canNotUploadNewFiles'))}

        <Box mt='l' flexDirection='row'>
          <TouchableOpacity onPress={onClose}>
            <Box
              borderRadius='xs'
              borderWidth={1}
              borderColor='black'
              width={172}
              height={48}
              mr='xs'
              justifyContent='center'
              alignContent='center'
              alignItems='center'
              accessibilityLabel={t(
                'models:profile.subscriptionPlans.keepTheTeamsPlan'
              )}>
              <Text variant='labelSmall' color='textPrimary'>
                {t('models:profile.subscriptionPlans.keepTheTeamsPlan')}
              </Text>
            </Box>
          </TouchableOpacity>

          <TouchableOpacity
            onPress={() => {
              onClose();
              setShowCancelSubscriptionConfirmBox2(true);
            }}>
            <Box
              backgroundColor='black'
              borderRadius='xs'
              width={172}
              height={48}
              justifyContent='center'
              alignContent='center'
              alignItems='center'
              accessibilityLabel={t('shared:continue')}>
              <Text variant='labelSmall' color='white'>
                {t('shared:continue')}
              </Text>
            </Box>
          </TouchableOpacity>
        </Box>
      </Box>
    </WebModal>
  );
};

export default CancelSubscriptionModal2;
