import React from 'react';
import { useTranslation } from 'react-i18next';
import { TouchableOpacity } from 'react-native';

import Avatar from '@components/Avatar/Avatar';
import { AddMemberType } from '@components/Invite/SelectMembersList';
import Icon from '@components/shared/Icon/Icon';
import { MemberRole } from '@components/User/MemberRole.web';
import { ProjectMemberRole } from '@graphql/generated';
import theme from '@themes/theme';
import { selectableProjectRoles } from '@utils/projects';
import { selectableTaskRoles } from '@utils/tasks';

import Box from '../Box/Box';
import Text from '../Text/Text';

const ADD_SIZE = 32;
interface AddMembersSectionProps {
  members: AddMemberType[];
  currentUserTeamRole?: string;
  removeMember: (member: AddMemberType) => void;
  addMembers: () => void;
  updateMember?: (member: AddMemberType, role: string) => void;
  isCreateContactGroup?: boolean;
  isCreateTask?: boolean;
  isCreateProject?: boolean;
  isEditProject?: boolean;
}

export const AddMembersSection: React.FC<AddMembersSectionProps> = ({
  members,
  currentUserTeamRole,
  removeMember,
  addMembers,
  updateMember,
  isCreateContactGroup,
  isCreateTask,
  isCreateProject,
  isEditProject,
}) => {
  const { t } = useTranslation();

  const showAddMemberLabel = members?.length > 0;
  const showAddMemberIcon = members?.length === 0;

  return (
    <Box
      flexDirection='column'
      justifyContent='center'
      marginBottom='s'
      marginTop='s'>
      <Box flex={1} flexDirection='row' justifyContent='space-between'>
        <Text variant='labelSmall' marginBottom='xs'>
          {t('models:projects.create.members.title')}
        </Text>
        {showAddMemberLabel && (
          <TouchableOpacity
            onPress={addMembers}
            accessibilityLabel={t('shared:addMembers')}>
            <Box marginLeft='s' flexDirection='row' alignItems='center'>
              <Icon
                variant='s'
                name='Plus'
                color='greenSecondary'
                marginRight='xxs'
              />
              <Text
                variant='labelSmall'
                textAlign='center'
                numberOfLines={1}
                color='greenSecondary'>
                {t('shared:addMembers')}
              </Text>
            </Box>
          </TouchableOpacity>
        )}
      </Box>

      {showAddMemberIcon && (
        <Box
          flex={1}
          flexDirection='row'
          alignItems='flex-start'
          justifyContent='flex-start'
          flexWrap='wrap'>
          <Box justifyContent='center' alignItems='center'>
            <TouchableOpacity
              onPress={addMembers}
              accessibilityLabel={t('shared:addMembers')}>
              <Box
                flexDirection='row'
                alignItems='center'
                justifyContent='center'
                marginRight='m'>
                <Box
                  alignItems='center'
                  justifyContent='center'
                  borderRadius='xxl'
                  bg='black'
                  width={ADD_SIZE}
                  height={ADD_SIZE}>
                  <Icon variant='s' name='Plus' color='white' />
                </Box>
                <Text variant='labelSmall' marginLeft='xs' color='grey04'>
                  {t('shared:addMembers')}
                </Text>
              </Box>
            </TouchableOpacity>
          </Box>
        </Box>
      )}

      {!(isCreateTask || isCreateProject || isEditProject) &&
        members.map((item) => {
          if (!item) return;
          const { name, avatar } = item;

          return (
            <Box flexDirection='column' key={item.id}>
              <Box flexDirection='row' alignItems='center' mb='xxs'>
                <Avatar size='medium' label={name} avatar={avatar} />
                <Box
                  flex={1}
                  flexDirection='row'
                  justifyContent='space-between'>
                  <Text
                    variant='labelSmall'
                    marginLeft='s'
                    textAlign='center'
                    numberOfLines={1}>
                    {name}
                  </Text>
                  <TouchableOpacity
                    accessibilityLabel={t('shared:remove')}
                    onPress={() => {
                      removeMember(item);
                    }}>
                    {isCreateContactGroup ? (
                      currentUserTeamRole && <Icon name='DeleteBin' />
                    ) : (
                      <Text
                        variant='labelSmall'
                        marginLeft='s'
                        textAlign='center'
                        numberOfLines={1}
                        color='alertRed'>
                        {t('shared:remove')}
                      </Text>
                    )}
                  </TouchableOpacity>
                </Box>
              </Box>
              <Box height={1} marginBottom='xxs' backgroundColor='grey02' />
            </Box>
          );
        })}
      {(isCreateTask || isCreateProject || isEditProject) &&
        members.map((item, index) => {
          return (
            <Box
              key={item.id}
              style={{
                marginBottom:
                  index === members.length - 1 ? theme.spacing.listFooter : 0,
              }}
              py={isCreateProject ? 'xxxs' : 's'}
              borderBottomWidth={1}
              borderBottomColor='grey02'>
              <MemberRole
                isCreateTask={isCreateTask}
                isCreateProject={isCreateProject}
                isEditProject={isEditProject}
                roles={
                  isCreateTask ? selectableTaskRoles : selectableProjectRoles
                }
                member={item}
                updateRole={
                  isCreateTask ||
                  isCreateProject ||
                  (isEditProject && item.role !== ProjectMemberRole.Owner)
                    ? (i: string) => updateMember && updateMember(item, i)
                    : undefined
                }
                removeMember={() => removeMember(item)}
                avaterSize='small'
              />
            </Box>
          );
        })}
    </Box>
  );
};
